import React, { useState } from "react";
import IconInput from "../inputs/iconInput";
import { IconButton } from "../buttons";
import Select from "../select";
import "./styles.scss";

const Prompt = ({
  show,
  closeModal,
  title,
  onSubmit,
  content,
  saveText,
  submitting,
  withGreen,
  withRed,
  onClick,
  isLight,
  disabled,
  type ="submit"
}) => {
  const [locations, setLocations] = useState([]);

  return (
    (show && (
      <div className="flex_column_center prompt p_20 ">
        <button className="cursor_pointer close_button" onClick={closeModal}>
          x
        </button>
        <div className="back_drop back_drop_alt" onClick={closeModal} />
        <form
          className="flex_column_start half_width_desktop overflow-scroll"
          onSubmit={onSubmit}
        >
          <button
            className="cursor_pointer close_button"
            onClick={closeModal}
            type="button"
          >
            x
          </button>
          <h6 className=" mb_35 text-center w-100">
            {title.split("\n").map((str, i) => {
              return (
                <p
                  style={{ marginBottom: str == "" ? "10px" : "" }}
                  key={i + "str"}
                  className={` text-center ${
                    i === 0 || !isLight ? "bold text-dark-alt" : "text-gray-alt"
                  }`}
                >
                  {str}
                </p>
              );
            })}
          </h6>
          {content}
          <div className="flex_row_between width_full">
            {withGreen && (
              <IconButton
                text="Return"
                className="mt_35"
                loading={submitting}
                type="button"
                onClick={closeModal}
              />
            )}
            {withRed && (
              <IconButton
                text={saveText}
                className="red_button_thick mt_35"
                isLoading={submitting}
                type={type}
                onClick={onClick}
                isDisabled={disabled}
              />
            )}
          </div>
        </form>
      </div>
    )) ||
    null
  );
};
export default Prompt;
