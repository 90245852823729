import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import { numberWithCommas } from "../../helpers/commaSeparator";
import CommonStore from "../../stores/superAdminStores/commonStore";
import { withAlert } from "react-alert";
import "./styles.scss";
import IconInput from "../../components/inputs/iconInput";
import { BiSearchAlt2 } from "react-icons/bi";
import { AiTwotonePhone } from "react-icons/ai";
import { AiTwotoneMail } from "react-icons/ai";
import Select from "../../components/select";
import Loader from "react-loader-spinner";
import moment from "moment";
import homeStore from "../../stores/superAdminStores/homeStore";
import { Link } from "react-router-dom";
import { ChevronRight } from "../../assets/icons/icons";
import Button from "../../components/buttons";
import Prompt from "../../components/modals/prompt";
import Modal from "../../components/modals";
import ReactPaginate from "react-paginate";

const PartnerHistoryRecord = ({ alert }) => {
	const [todayTrips, setTodayTrips] = useState([]);
	const [blockedUsers, setBlockedUsers] = useState([]);
	const [yesterdayTrips, setYesterdayTrips] = useState([]);
	const [showTodayTrips, setShowTodayTrips] = useState(true);
	const [showYesterdayTrips, setShowYesterdayTrips] = useState(false);
	const [showAllTrips, setShowAllTrips] = useState(false);
	const [location, setLocation] = useState("");
	const [form, setForm] = useState({
		amount: "",
		type: "",
		reference: "",
		reason: "",
	});

	const [selectedlocations, setSelectedLocations] = useState([]);
	const [cokitchens, setCokitchens] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [activeTab, setActiveTab] = useState("all");
	const [sortVal, setSortVal] = useState({
		label: "Sort By: Name",
		value: "name",
	});
	const [currentData, setCurrentData] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [showPrompt, setShowPrompt] = useState(false);
	const [showPromptAlt, setShowPromptAlt] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [loading, setLoading] = useState(false);

	const [debtors, setDebtors] = useState([]);
	const [currentItems, setCurrentItems] = useState(null);
	const [pageCount, setPageCount] = useState(0);
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);

	const itemsPerPage = 30;
	const today = moment().format("DD-MM-YYYY");
	const yesterday = moment().add(-1, "days").format("DD-MM-YYYY");

	// Invoke when user click to request another page.
	const handlePageClick = (event) => {
		let usersCop = homeStore?.users
			.sort((a, b) => a?.first_name?.localeCompare(b?.first_name))
			?.sort((a, b) => {
				if (sortVal?.value === "name") {
					return a?.first_name?.localeCompare(b?.first_name);
				} else if (sortVal?.value === "balance") {
					return parseFloat(b.balance) - parseFloat(a.balance);
				} else if (sortVal?.value === "reg") {
					return new Date(b.created_at) - new Date(a.created_at);
				}
			})
			.filter(
				(item) =>
					(item?.first_name + " " + item?.last_name)
						.toUpperCase()
						.includes(searchValue.toUpperCase()) ||
					(item?.user_gender)
						.toUpperCase()
						.includes(searchValue.toUpperCase()) ||
					item?.username?.toUpperCase().includes(searchValue.toUpperCase()) ||
					(item?.phone_number)
						.toUpperCase()
						.includes(searchValue.toUpperCase()) ||
					item?.email?.toUpperCase().includes(searchValue.toUpperCase()) ||
					(item?.role).toUpperCase().includes(searchValue.toUpperCase())
			);
		const newOffset = (event.selected * itemsPerPage) % usersCop?.length;
		console.log(
			`User requested page number ${event.selected}, which is offset ${newOffset}`
		);
		setItemOffset(newOffset);
	};

	// Reducer function to compute total
	const getTotal = (items, field) => {
		let total = items.reduce(function (acc, curr) {
			return acc + Number(curr[field]);
		}, 0);
		return total;
	};

	// To calculate the total deficit
	const getTotalDeficit = () => {
		return getTotal(debtors, "owe");
	};

	const generatePromptTitle = (currentData) => {
		let message = "";
		if (form?.type === "add") {
			message = `Are you sure you want to add ₦${form?.amount} to ${
				currentData?.first_name + " " + currentData?.last_name
			}'s wallet`;
		} else if (form?.type === "debt") {
			message = `Are you sure you want to update ₦${form?.amount} to ${
				currentData?.first_name + " " + currentData?.last_name
			}'s debt`;
		} else if (form?.type === "paystack") {
			message = `Are you sure you want to create ${
				currentData?.first_name + " " + currentData?.last_name
			}'s last order from paystack`;
		} else {
			message = `Are you sure you want to deduct ₦${form?.amount} from ${
				currentData?.first_name + " " + currentData?.last_name
			}'s wallet`;
		}
		return message;
	};
	// load data from store
	useEffect(async () => {
		if (!homeStore.dataUsersCached) {
			await loadData();
		}
		if (!CommonStore.dataCached) {
			await CommonStore.getCokitchens(alert);
		}
		setCokitchens(CommonStore.cokitchens);
		await sortTrips();
		// Fetch items from another resources.
		let usersCop = homeStore?.users
			.sort((a, b) => a?.first_name?.localeCompare(b?.first_name))
			?.sort((a, b) => {
				if (sortVal?.value === "name") {
					return a?.first_name?.localeCompare(b?.first_name);
				} else if (sortVal?.value === "email") {
					return a?.email?.localeCompare(b?.email);
				} else if (sortVal?.value === "balance") {
					return parseFloat(b.balance) - parseFloat(a.balance);
				} else if (sortVal?.value === "reg") {
					return new Date(b.created_at) - new Date(a.created_at);
				}
			})
			?.filter(
				(item) =>
					item.first_name != null &&
					item.last_name != null &&
					((item.first_name + " " + item.last_name)
						.toUpperCase()
						.includes(searchValue.toUpperCase()) ||
						(item?.user_gender)
							.toUpperCase()
							.includes(searchValue.toUpperCase()) ||
						item?.username?.toUpperCase().includes(searchValue.toUpperCase()) ||
						(item?.phone_number)
							.toUpperCase()
							.includes(searchValue.toUpperCase()) ||
						item?.email?.toUpperCase().includes(searchValue.toUpperCase()) ||
						(item?.role).toUpperCase().includes(searchValue.toUpperCase()))
			);
		const endOffset = itemOffset + itemsPerPage;
		console.log(`Loading items from ${itemOffset} to ${endOffset}`);
		setCurrentItems(usersCop.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(usersCop?.length / itemsPerPage));
	}, [itemOffset, itemsPerPage]);

	useEffect(async () => {
		// Fetch items from another resources.
		let usersCop = homeStore?.users
			.sort((a, b) => a?.first_name?.localeCompare(b?.first_name))
			?.sort((a, b) => {
				if (sortVal?.value === "name") {
					return a?.first_name?.localeCompare(b?.first_name);
				} else if (sortVal?.value === "email") {
					return a?.email?.localeCompare(b?.email);
				} else if (sortVal?.value === "balance") {
					return parseFloat(b.balance) - parseFloat(a.balance);
				} else if (sortVal?.value === "reg") {
					return new Date(b.created_at) - new Date(a.created_at);
				}
			})
			?.filter(
				(item) =>
					item.first_name != null &&
					item.last_name != null &&
					((item.first_name + " " + item.last_name)
						.toUpperCase()
						.includes(searchValue.toUpperCase()) ||
						(item?.user_gender)
							.toUpperCase()
							.includes(searchValue.toUpperCase()) ||
						item?.username?.toUpperCase().includes(searchValue.toUpperCase()) ||
						(item?.phone_number)
							.toUpperCase()
							.includes(searchValue.toUpperCase()) ||
						item?.email?.toUpperCase().includes(searchValue.toUpperCase()) ||
						(item?.role).toUpperCase().includes(searchValue.toUpperCase()))
			);
		const endOffset = itemOffset + itemsPerPage;
		console.log(`Loading items from ${itemOffset} to ${endOffset}`);
		setCurrentItems(usersCop.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(usersCop?.length / itemsPerPage));
	}, [itemOffset, itemsPerPage, searchValue, sortVal]);
	// useEffect(() => {
	//   // Fetch items from another resources.
	//   const endOffset = itemOffset + itemsPerPage;
	//   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
	//   setCurrentItems(homeStore?.users.slice(itemOffset, endOffset));
	//   setPageCount(Math.ceil(homeStore?.users?.length / itemsPerPage));
	// }, [itemOffset, itemsPerPage]);
	const loadData = async (noload) => {
		!noload && setLoading(true);
		await Promise.all([homeStore.getBlockedUsers(alert)]);
		await Promise.all([homeStore.getUsers(alert, true)]);
		sortTrips();
		setLoading(false);
	};

	// Sort trips into today, yesterday and all time, cash, card
	const sortTrips = async () => {
		setTodayTrips(homeStore?.todayUsers);
		setYesterdayTrips(homeStore?.yesterdayUsers);
		setBlockedUsers(homeStore?.blockedUsersMain);
		let allDebtors = homeStore?.users?.filter((item) => Number(item.owe));
		setDebtors(allDebtors);
	};

	// submit form
	const updateWallet = async () => {
		let type = form?.type;
		if (!type) {
			alert.show("Please select action!", {
				type: "info",
			});
		} else {
			setSubmitting(true);

			if (type === "add") {
				await homeStore.addToWallet(alert, {
					amount: form?.amount?.toString(),
					phone_number: currentData?.phone_number,
					reason: form?.reason,
				});
			} else if (type === "debt") {
				await homeStore.updateUserDebt(alert, {
					owe: form?.amount?.toString(),
					user_id: currentData?.id,
				});
			} else if (type === "last_order") {
				await homeStore.createLastOrder(alert, {
					reference: form?.amount?.toString(),
					user_id: currentData?.id,
				});
			} else if (type === "paystack") {
				await homeStore.createUserOrderFromPaystack(alert, {
					reference: form?.reference?.toString(),
					user_id: currentData?.id,
				});
			} else {
				await homeStore.subtractFromWallet(alert, {
					amount: form?.amount?.toString(),
					phone_number: currentData?.phone_number,
					reason: form?.reason,
				});
			}

			setSubmitting(false);
			setShowModal(false);
			setShowPrompt(false);
			setForm({ amount: "", type: "" });
			await loadData(true);
		}
	};

	// submit form
	const updateUserStatus = async () => {
		setSubmitting(true);
		if (currentData?.blocked) {
			await homeStore.unBlockUser(alert, {
				phone_number: currentData?.phone_number,
			});
		} else {
			await homeStore.blockUser(alert, {
				phone_number: currentData?.phone_number,
			});
		}
		setSubmitting(false);
		setShowModal(false);
		setShowPromptAlt(false);
		await loadData(true);
	};
	return (
		<Layout locationChange={setLocation} users="active_nav_item" withHistoryBtn>
			<section className="main_section flex-column d-align-center content_section tab_column users_container">
				{/* Users section */}

				{/* Tabs */}
				<div className="tabs-container flex-row d-justify-center d-align-center w-fiit rounded-xl mb-3">
					<button
						className={`pointer ${
							activeTab === "all" ? "active-app-tab bg-light-red" : ""
						} app-tab p-2 rounded-xl`}
						onClick={() => {
							setActiveTab("all");
						}}
					>
						All users
					</button>

					<button
						className={`pointer ${
							activeTab === "debtors" ? "active-app-tab bg-light-red" : ""
						} app-tab p-2 rounded-xl`}
						onClick={() => {
							setActiveTab("debtors");
						}}
					>
						Debtors
					</button>
					<button
						className={`pointer ${
							activeTab === "blocked" ? "active-app-tab bg-light-red" : ""
						} app-tab p-2 rounded-xl`}
						onClick={() => {
							setActiveTab("blocked");
						}}
					>
						Blocked Users
					</button>
				</div>
				{/* tabs end */}
				<section className="bottom_left_section w-100 position-relative">
					<div className="border_bottom_thin flex_row_between pb-3 w-100">
						<h5 className="mb_15">All Users ({homeStore?.users?.length})</h5>
						<Select
							containerClass="w-30"
							placeholder="Locations: ALL"
							defaultValue={{ label: "Sort By: Name", value: "name" }}
							handleChange={(e) => setSortVal(e)}
							options={[
								{ label: "Sort By: Name", value: "name" },
								{ label: "Sort By: Email", value: "email" },
								{ label: "Sort By: Wallet Balance", value: "balance" },
								{ label: "Sort By: Registration Date", value: "reg" },
							]}
						/>
						<IconInput
							containerClass="w-30"
							placeholder="Search user by name, gender, role"
							withIcon
							icon={<BiSearchAlt2 width="20px" height="20px" fill="#8B8B8B" />}
							value={searchValue}
							onChange={(e) => setSearchValue(e)}
						/>
					</div>
					{activeTab === "all" ? (
						<div className="overflow-scroll w-100">
							<div className="border_bottom_thin flex_row_between py_15">
								<p className="bold_18_px">Today ({todayTrips.length})</p>
								<Link
									to="#/"
									onClick={() => setShowTodayTrips((prev) => !prev)}
								>
									<p className="gray_label mb_15 bold">
										{showTodayTrips ? "Collapse" : "Display"}
									</p>
								</Link>
							</div>

							<table className="width_full">
								<thead>
									<tr>
										<th className="pl-0">Name</th>
										<th className="pl-0">Date Registered</th>
										<th className="pl-0">Gender/Role</th>
										<th className="pl-0">Contact</th>
										<th className="px-0">Wallet Balance</th>
									</tr>
								</thead>
								<tbody>
									<p className="gray_label bold_21px uppercase mt_25 pb_15">
										{!loading && todayTrips.length === 0
											? "No user has registered today"
											: null}
									</p>
									{/* Loader */}
									{loading ? (
										<tr>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_center">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
										</tr>
									) : null}
									{/* Loader End */}
									{showTodayTrips
										? todayTrips

												.sort((a, b) => {
													if (sortVal?.value === "name") {
														return a?.first_name?.localeCompare(b?.first_name);
													} else if (sortVal?.value === "email") {
														return a?.email?.localeCompare(b?.email);
													} else if (sortVal?.value === "balance") {
														return (
															parseFloat(b.balance) - parseFloat(a.balance)
														);
													} else if (sortVal?.value === "reg") {
														return (
															new Date(b.created_at) - new Date(a.created_at)
														);
													}
												})
												.filter(
													(item) =>
														(item?.first_name + " " + item?.last_name)
															.toUpperCase()
															.includes(searchValue.toUpperCase()) ||
														(item?.user_gender)
															.toUpperCase()
															.includes(searchValue.toUpperCase()) ||
														item?.username
															?.toUpperCase()
															.includes(searchValue.toUpperCase()) ||
														(item?.phone_number)
															.toUpperCase()
															.includes(searchValue.toUpperCase()) ||
														item?.email
															?.toUpperCase()
															.includes(searchValue.toUpperCase()) ||
														(item?.role)
															.toUpperCase()
															.includes(searchValue.toUpperCase())
												)
												.map((item, i) => {
													let status =
														item && item.completed
															? "Completed"
															: item && item.cancelled
															? "Cancelled"
															: item && !item.completed && !item.cancelled
															? "Pending"
															: "";
													return (
														<tr key={i + "item"}>
															<td>
																<div className="flex_column_left">
																	<label className="text-dark-50 capitalize">
																		{item?.first_name + " " + item?.last_name}
																	</label>
																</div>
															</td>
															<td>
																<div className="flex_column_left">
																	<label className="text-dark-50 mb_4">
																		{item &&
																			item.created_at &&
																			moment(item.created_at).format("LT")}
																	</label>
																	<label className="text-dark-50">
																		{item &&
																			item.created_at &&
																			moment(item.created_at).format(
																				"DD MMM YYYY"
																			)}
																	</label>
																</div>
															</td>
															<td>
																<div className="flex_column_left">
																	<div className="flex_row_align_center mb_10">
																		<label className="text-dark-50 mb_4">
																			{item?.user_gender}
																		</label>
																	</div>

																	<div className="flex_row_align_center">
																		<label className="text-dark-50 mb_4">
																			{item?.role}
																		</label>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_left">
																	<div className="flex_row_center mb_17">
																		<AiTwotonePhone
																			fill="#939393"
																			width="8px"
																			height="8px"
																			className="mr_15"
																		/>

																		<label className="text-dark-50 mb_4">
																			{item?.phone_number}
																		</label>
																	</div>

																	<div className="flex_row_align_center">
																		<AiTwotoneMail
																			fill="#939393"
																			width="9px"
																			height="9px"
																			className="mr_15"
																		/>

																		<label className="text-dark-50">
																			{item?.email}
																		</label>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_right">
																	<span className="bold_21px green_text mb_17">
																		₦
																		{item?.balance &&
																			numberWithCommas(parseInt(item?.balance))}
																	</span>

																	<div
																		onClick={() => {
																			setCurrentData(item);
																			setShowModal(true);
																		}}
																		className="flex_row_right"
																	>
																		<Link to={`#/`}>
																			<p className="mr_10 normal_15px black">
																				View & Edit
																			</p>
																		</Link>
																		<Link to={`#/`}>
																			<ChevronRight
																				fill="#000000"
																				width="11px"
																				height="11px"
																			/>
																		</Link>
																	</div>
																</div>
															</td>
														</tr>
													);
												})
										: null}
								</tbody>
							</table>

							<div className="border_bottom_thin flex_row_between py_15">
								<p className="bold_18_px">
									Yesterday ({yesterdayTrips.length})
								</p>

								<Link
									to="#/"
									onClick={() => setShowYesterdayTrips((prev) => !prev)}
								>
									<p className="gray_label mb_15 bold">
										{showYesterdayTrips ? "Collapse" : "Display"}
									</p>
								</Link>
							</div>
							<table className="width_full">
								{showYesterdayTrips ? (
									<thead>
										<tr>
											<th className="pl-0">Name</th>
											<th className="pl-0">Date Registered</th>
											<th className="pl-0">Gender/Role</th>
											<th className="pl-0">Contact</th>
											<th className="px-0">Wallet Balance</th>
										</tr>
									</thead>
								) : null}
								<tbody>
									<p className="gray_label bold_21px uppercase mt_25 pb_15">
										{!loading && yesterdayTrips.length === 0
											? "No user registered yesterday"
											: null}
									</p>
									{/* Loader */}
									{loading ? (
										<tr>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_center">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
										</tr>
									) : null}
									{/* Loader End */}
									{showYesterdayTrips
										? yesterdayTrips

												.sort((a, b) => {
													if (sortVal?.value === "name") {
														return a?.first_name?.localeCompare(b?.first_name);
													} else if (sortVal?.value === "email") {
														return a?.email?.localeCompare(b?.email);
													} else if (sortVal?.value === "balance") {
														return (
															parseFloat(b.balance) - parseFloat(a.balance)
														);
													} else if (sortVal?.value === "reg") {
														return (
															new Date(b.created_at) - new Date(a.created_at)
														);
													}
												})
												.filter(
													(item) =>
														(item?.first_name + " " + item?.last_name)
															.toUpperCase()
															.includes(searchValue.toUpperCase()) ||
														(item?.user_gender)
															.toUpperCase()
															.includes(searchValue.toUpperCase()) ||
														item?.username
															?.toUpperCase()
															.includes(searchValue.toUpperCase()) ||
														(item?.phone_number)
															.toUpperCase()
															.includes(searchValue.toUpperCase()) ||
														item?.email
															?.toUpperCase()
															.includes(searchValue.toUpperCase()) ||
														(item?.role)
															.toUpperCase()
															.includes(searchValue.toUpperCase())
												)
												.map((item, i) => {
													let status =
														item && item.completed
															? "Completed"
															: item && item.cancelled
															? "Cancelled"
															: item && !item.completed && !item.cancelled
															? "Pending"
															: "";
													return (
														<tr key={i + "item"}>
															<td>
																<div className="flex_column_left">
																	<label className="text-dark-50 capitalize">
																		{item?.first_name + " " + item?.last_name}
																	</label>
																</div>
															</td>
															<td>
																<div className="flex_column_left">
																	<label className="text-dark-50 mb_4">
																		{item &&
																			item.created_at &&
																			moment(item.created_at).format("LT")}
																	</label>
																	<label className="text-dark-50">
																		{item &&
																			item.created_at &&
																			moment(item.created_at).format(
																				"DD MMM YYYY"
																			)}
																	</label>
																</div>
															</td>
															<td>
																<div className="flex_column_left">
																	<div className="flex_row_align_center mb_10">
																		<label className="text-dark-50 mb_4">
																			{item?.user_gender}
																		</label>
																	</div>

																	<div className="flex_row_align_center">
																		<label className="text-dark-50 mb_4">
																			{item?.role}
																		</label>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_left">
																	<div className="flex_row_center mb_17">
																		<AiTwotonePhone
																			fill="#939393"
																			width="8px"
																			height="8px"
																			className="mr_15"
																		/>

																		<label className="text-dark-50 mb_4">
																			{item?.phone_number}
																		</label>
																	</div>

																	<div className="flex_row_align_center">
																		<AiTwotoneMail
																			fill="#939393"
																			width="9px"
																			height="9px"
																			className="mr_15"
																		/>

																		<label className="text-dark-50">
																			{item?.email}
																		</label>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_right">
																	<span className="bold_21px green_text mb_17">
																		₦
																		{item?.balance &&
																			numberWithCommas(parseInt(item?.balance))}
																	</span>

																	<div
																		onClick={() => {
																			setCurrentData(item);
																			setShowModal(true);
																		}}
																		className="flex_row_right"
																	>
																		<Link to={`#/`}>
																			<p className="mr_10 normal_15px black">
																				View & Edit
																			</p>
																		</Link>
																		<Link to={`#/`}>
																			<ChevronRight
																				fill="#000000"
																				width="11px"
																				height="11px"
																			/>
																		</Link>
																	</div>
																</div>
															</td>
														</tr>
													);
												})
										: null}
								</tbody>
							</table>
							<div className="p_15_40 flex_row_center">
								<Button
									className="red_button_thick width_full"
									text={
										showAllTrips
											? `Hide all users  (${homeStore?.users?.length || 0})`
											: `Show all users  (${homeStore?.users?.length || 0})`
									}
									onClick={() => {
										setShowAllTrips(!showAllTrips);
									}}
								/>
							</div>
							{showAllTrips ? (
								<>
									<table className="width_full slideup">
										<thead>
											<tr>
												<th className="pl-0">Name</th>
												<th className="pl-0">Date Registered</th>
												<th className="pl-0">Gender/Role</th>
												<th className="pl-0">Contact</th>
												<th className="px-0">Wallet Balance</th>
											</tr>
										</thead>
										<tbody>
											<p className="gray_label bold_21px uppercase mt_25 pb_15">
												{!loading && !homeStore?.users[0] ? "No User" : null}
											</p>
											{/* Loader */}
											{loading ? (
												<tr>
													<td>
														<div className="flex_column_left">
															<Loader
																type="ThreeDots"
																color="#ff000060"
																height={10}
																width={50}
															/>
														</div>
													</td>
													<td>
														<div className="flex_column_center">
															<Loader
																type="ThreeDots"
																color="#ff000060"
																height={10}
																width={50}
															/>
														</div>
													</td>

													<td>
														<div className="flex_column_left">
															<Loader
																type="ThreeDots"
																color="#ff000060"
																height={10}
																width={50}
															/>
														</div>
													</td>

													<td>
														<div className="flex_column_right">
															<Loader
																type="ThreeDots"
																color="#ff000060"
																height={10}
																width={50}
															/>
														</div>
													</td>
												</tr>
											) : null}
											{/* Loader End */}
											{currentItems.map((item, i) => {
												return (
													<tr key={i + "item"}>
														<td>
															<div className="flex_column_left">
																<label className="text-dark-50 capitalize">
																	{item?.first_name + " " + item?.last_name}
																</label>
															</div>
														</td>
														<td>
															<div className="flex_column_left">
																<label className="text-dark-50 mb_4">
																	{item &&
																		item.created_at &&
																		moment(item.created_at).format("LT")}
																</label>
																<label className="text-dark-50">
																	{item &&
																		item.created_at &&
																		moment(item.created_at).format(
																			"DD MMM YYYY"
																		)}
																</label>
															</div>
														</td>
														<td>
															<div className="flex_column_left">
																<div className="flex_row_align_center mb_10">
																	<label className="text-dark-50 mb_4">
																		{item?.user_gender}
																	</label>
																</div>

																<div className="flex_row_align_center">
																	<label className="text-dark-50 mb_4">
																		{item?.role}
																	</label>
																</div>
															</div>
														</td>

														<td>
															<div className="flex_column_left">
																<div className="flex_row_center mb_17">
																	<AiTwotonePhone
																		fill="#939393"
																		width="8px"
																		height="8px"
																		className="mr_15"
																	/>

																	<label className="text-dark-50 mb_4">
																		{item?.phone_number}
																	</label>
																</div>

																<div className="flex_row_align_center">
																	<AiTwotoneMail
																		fill="#939393"
																		width="9px"
																		height="9px"
																		className="mr_15"
																	/>

																	<label className="text-dark-50">
																		{item?.email}
																	</label>
																</div>
															</div>
														</td>

														<td>
															<div className="flex_column_right">
																<span className="bold_21px green_text mb_17">
																	₦
																	{item?.balance &&
																		numberWithCommas(parseInt(item?.balance))}
																</span>

																<div
																	onClick={() => {
																		setCurrentData(item);
																		setShowModal(true);
																	}}
																	className="flex_row_right"
																>
																	<Link to={`#/`}>
																		<p className="mr_10 normal_15px black">
																			View & Edit
																		</p>
																	</Link>
																	<Link to={`#/`}>
																		<ChevronRight
																			fill="#000000"
																			width="11px"
																			height="11px"
																		/>
																	</Link>
																</div>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
									<ReactPaginate
										breakLabel="..."
										nextLabel="next >"
										onPageChange={handlePageClick}
										pageRangeDisplayed={5}
										pageCount={pageCount}
										previousLabel="< previous"
										renderOnZeroPageCount={null}
										breakClassName="break-me"
										containerClassName="d-flex flex-row flex-wrap w-100 d-justify-start d-align-center pagination my-5"
										activeClassName="active-pagination bg-danger"
										pageClassName="page-item px-2 py-1 border-danger pointer"
										pageLinkClassName="page-link"
										previousClassName="page-item px-2 py-1 border-danger pointer"
										previousLinkClassName="page-link"
										nextClassName="page-item px-2 py-1 border-danger pointer"
										nextLinkClassName="page-link"
										activeLinkClassName="white"
									/>
								</>
							) : null}
						</div>
					) : activeTab === "blocked" ? (
						<div className="overflow-scroll w-100">
							<div className="border_bottom_thin flex_row_between py_15">
								<p className="bold_18_px">
									Blocked Users ({blockedUsers?.length})
								</p>
								<Link to="#/">
									<p className="gray_label mb_15 bold">
										{showTodayTrips ? "Collapse" : "Display"}
									</p>
								</Link>
							</div>
							<table className="width_full">
								<thead>
									<tr>
										<th className="pl-0">Name</th>
										<th className="pl-0">Date Registered</th>
										<th className="pl-0">Gender/Role</th>
										<th className="pl-0">Contact</th>
										<th className="px-0">Wallet Balance</th>
									</tr>
								</thead>
								<tbody>
									<p className="gray_label bold_21px uppercase mt_25 pb_15">
										{!loading && blockedUsers.length === 0
											? "No blocked users"
											: null}
									</p>
									{/* Loader */}
									{loading ? (
										<tr>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_center">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
										</tr>
									) : null}
									{/* Loader End */}
									{blockedUsers

										.sort((a, b) => {
											if (sortVal?.value === "name") {
												return a?.first_name?.localeCompare(b?.first_name);
											} else if (sortVal?.value === "email") {
												return a?.email?.localeCompare(b?.email);
											} else if (sortVal?.value === "balance") {
												return parseFloat(b.balance) - parseFloat(a.balance);
											} else if (sortVal?.value === "reg") {
												return new Date(b.created_at) - new Date(a.created_at);
											}
										})
										.filter(
											(item) =>
												(item?.first_name + " " + item?.last_name)
													.toUpperCase()
													.includes(searchValue.toUpperCase()) ||
												(item?.user_gender)
													.toUpperCase()
													.includes(searchValue.toUpperCase()) ||
												item?.username
													?.toUpperCase()
													.includes(searchValue.toUpperCase()) ||
												(item?.phone_number)
													.toUpperCase()
													.includes(searchValue.toUpperCase()) ||
												item?.email
													?.toUpperCase()
													.includes(searchValue.toUpperCase()) ||
												(item?.role)
													.toUpperCase()
													.includes(searchValue.toUpperCase())
										)
										.map((item, i) => {
											let status =
												item && item.completed
													? "Completed"
													: item && item.cancelled
													? "Cancelled"
													: item && !item.completed && !item.cancelled
													? "Pending"
													: "";
											return (
												<tr key={i + "item"}>
													<td>
														<div className="flex_column_left">
															<label className="text-dark-50 capitalize">
																{item?.first_name + " " + item?.last_name}
															</label>
														</div>
													</td>
													<td>
														<div className="flex_column_left">
															<label className="text-dark-50 mb_4">
																{item &&
																	item.created_at &&
																	moment(item.created_at).format("LT")}
															</label>
															<label className="text-dark-50">
																{item &&
																	item.created_at &&
																	moment(item.created_at).format("DD MMM YYYY")}
															</label>
														</div>
													</td>
													<td>
														<div className="flex_column_left">
															<div className="flex_row_align_center mb_10">
																<label className="text-dark-50 mb_4">
																	{item?.user_gender}
																</label>
															</div>

															<div className="flex_row_align_center">
																<label className="text-dark-50 mb_4">
																	{item?.role}
																</label>
															</div>
														</div>
													</td>

													<td>
														<div className="flex_column_left">
															<div className="flex_row_center mb_17">
																<AiTwotonePhone
																	fill="#939393"
																	width="8px"
																	height="8px"
																	className="mr_15"
																/>

																<label className="text-dark-50 mb_4">
																	{item?.phone_number}
																</label>
															</div>

															<div className="flex_row_align_center">
																<AiTwotoneMail
																	fill="#939393"
																	width="9px"
																	height="9px"
																	className="mr_15"
																/>

																<label className="text-dark-50">
																	{item?.email}
																</label>
															</div>
														</div>
													</td>

													<td>
														<div className="flex_column_right">
															<span className="bold_21px green_text mb_17">
																₦
																{item?.balance &&
																	numberWithCommas(parseInt(item?.balance))}
															</span>

															<div
																onClick={() => {
																	setCurrentData(item);
																	setShowModal(true);
																}}
																className="flex_row_right"
															>
																<Link to={`#/`}>
																	<p className="mr_10 normal_15px black">
																		View & Edit
																	</p>
																</Link>
																<Link to={`#/`}>
																	<ChevronRight
																		fill="#000000"
																		width="11px"
																		height="11px"
																	/>
																</Link>
															</div>
														</div>
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					) : activeTab === "debtors" ? (
						<div className="overflow-scroll w-100">
							<div className="border_bottom_thin flex_row_between py_15">
								<p className="bold_18_px">Debtors ({debtors?.length})</p>

								<p className="bold_18_px">
									Total deficit{" "}
									<span className="text-danger">
										(₦-
										{getTotalDeficit() && numberWithCommas(getTotalDeficit())})
									</span>
								</p>
							</div>
							<table className="width_full">
								<thead>
									<tr>
										<th className="pl-0">Name</th>
										<th className="pl-0">Date Registered</th>
										<th className="pl-0">Gender/Role</th>
										<th className="pl-0">Contact</th>
										<th className="px-0">Debt amount</th>
									</tr>
								</thead>
								<tbody>
									<p className="gray_label bold_21px uppercase mt_25 pb_15">
										{!loading && debtors.length === 0 ? "No debtors" : null}
									</p>
									{/* Loader */}
									{loading ? (
										<tr>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_center">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
										</tr>
									) : null}
									{/* Loader End */}
									{debtors

										.sort((a, b) => {
											return parseFloat(b.owe) - parseFloat(a.owe);
										})
										.filter(
											(item) =>
												(item?.first_name + " " + item?.last_name)
													.toUpperCase()
													.includes(searchValue.toUpperCase()) ||
												(item?.user_gender)
													.toUpperCase()
													.includes(searchValue.toUpperCase()) ||
												item?.username
													?.toUpperCase()
													.includes(searchValue.toUpperCase()) ||
												(item?.phone_number)
													.toUpperCase()
													.includes(searchValue.toUpperCase()) ||
												item?.email
													?.toUpperCase()
													.includes(searchValue.toUpperCase()) ||
												(item?.role)
													.toUpperCase()
													.includes(searchValue.toUpperCase())
										)
										.map((item, i) => {
											let status =
												item && item.completed
													? "Completed"
													: item && item.cancelled
													? "Cancelled"
													: item && !item.completed && !item.cancelled
													? "Pending"
													: "";
											return (
												<tr key={i + "item"}>
													<td>
														<div className="flex_column_left">
															<label className="text-dark-50 capitalize">
																{item?.first_name + " " + item?.last_name}
															</label>
														</div>
													</td>
													<td>
														<div className="flex_column_left">
															<label className="text-dark-50 mb_4">
																{item &&
																	item.created_at &&
																	moment(item.created_at).format("LT")}
															</label>
															<label className="text-dark-50">
																{item &&
																	item.created_at &&
																	moment(item.created_at).format("DD MMM YYYY")}
															</label>
														</div>
													</td>
													<td>
														<div className="flex_column_left">
															<div className="flex_row_align_center mb_10">
																<label className="text-dark-50 mb_4">
																	{item?.user_gender}
																</label>
															</div>

															<div className="flex_row_align_center">
																<label className="text-dark-50 mb_4">
																	{item?.role}
																</label>
															</div>
														</div>
													</td>

													<td>
														<div className="flex_column_left">
															<div className="flex_row_center mb_17">
																<AiTwotonePhone
																	fill="#939393"
																	width="8px"
																	height="8px"
																	className="mr_15"
																/>

																<label className="text-dark-50 mb_4">
																	{item?.phone_number}
																</label>
															</div>

															<div className="flex_row_align_center">
																<AiTwotoneMail
																	fill="#939393"
																	width="9px"
																	height="9px"
																	className="mr_15"
																/>

																<label className="text-dark-50">
																	{item?.email}
																</label>
															</div>
														</div>
													</td>

													<td>
														<div className="flex_column_right">
															<span className="bold_21px green_text mb_17">
																₦
																{item?.owe &&
																	numberWithCommas(parseInt(item?.owe))}
															</span>

															<div
																onClick={() => {
																	setCurrentData(item);
																	setShowModal(true);
																}}
																className="flex_row_right"
															>
																<Link to={`#/`}>
																	<p className="mr_10 normal_15px black">
																		View & Edit
																	</p>
																</Link>
																<Link to={`#/`}>
																	<ChevronRight
																		fill="#000000"
																		width="11px"
																		height="11px"
																	/>
																</Link>
															</div>
														</div>
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					) : null}
				</section>
			</section>
			<Modal
				showModal={showModal}
				closeModal={() => {
					setShowModal((prev) => !prev);
					setForm({ amount: "", type: "" });
				}}
				title={`${currentData?.first_name + " " + currentData?.last_name}`}
				onSubmit={(e) => {
					e.preventDefault();
					if (!form.type) {
						alert.show("Please select wallet action!", {
							type: "info",
						});
					} else {
						setShowPrompt(true);
					}
				}}
				content={
					<div className="w-100">
						<Select
							placeholder="Select action"
							containerClass="width_full mb-1"
							options={[
								{ label: "Add to wallet", value: "add" },
								{ label: "Deduct from wallet", value: "deduct" },
								{ label: "Update user's debt", value: "debt" },
								{
									label: "Create user's last order from Paystack",
									value: "last_order",
								},
								// { label: "Create User's order from Paystack", value: 'paystack' },
							]}
							handleChange={(e) => {
								setForm({ ...form, type: e.value });
							}}
						/>

						{form?.type === "paystack" ? null : (
							<IconInput
								containerClass="w-100 mb-1"
								placeholder={`${
									form?.type === "last_order"
										? "Enter paystack reference"
										: "Enter amount to be"
								} ${
									form?.type === "add"
										? "added"
										: form?.type === "deduct"
										? "deducted"
										: form?.type === "debt"
										? "debited"
										: form?.type === "last_order"
										? ""
										: "added or deducted"
								}  `}
								value={form.amount}
								onChange={(e) => setForm({ ...form, amount: e })}
								type={form?.type === "last_order" ? "text" : "number"}
								required
							/>
						)}
						{form?.type === "debt" ? (
							<IconInput
								containerClass="w-100"
								placeholder={`Outstanding debt`}
								value={Number(currentData.owe)}
								onChange={(e) => setForm({ ...form, amount: e })}
								type="number"
								disabled
								withLabel
								label="Outstanding debt"
							/>
						) : null}
						{form?.type === "paystack" ? (
							<IconInput
								containerClass="w-100"
								placeholder={`Paystack referece`}
								value={form.reference}
								onChange={(e) => setForm({ ...form, reference: e })}
								type="text"
								required
								withLabel
								label="Paystack referece"
							/>
						) : null}

						{form?.type === "add" || form?.type === "deduct" ? (
							<div className="w-100">
								<label className={`mb_15 normal_17px`}>{`Reason for wallet ${
									form?.type === "add" ? "credit" : "debit"
								} `}</label>
								<textarea
									className="text_area text_area_short mb_15 w-100"
									placeholder={`Type reason for wallet ${
										form?.type === "add" ? "credit" : "debit"
									} here `}
									value={form.reason}
									onChange={(e) => setForm({ ...form, reason: e.target.value })}
									required
								/>
							</div>
						) : null}
					</div>
				}
				saveText={"Confirm action"}
				btmBtns
				saveTextTwo={currentData?.blocked ? "Unblock User" : "block user"}
				onClickTwo={() => {
					setShowPromptAlt(true);
				}}
			/>
			<Prompt
				show={showPrompt}
				closeModal={() => {
					setShowPrompt(!showPrompt);
				}}
				title={`Are you sure you want to ${
					form?.type === "add"
						? "add " + "₦" + form?.amount + " to"
						: form?.type === "last_order"
						? "create " +
						  currentData?.first_name +
						  " " +
						  currentData?.last_name +
						  "'s" +
						  " last order?"
						: form?.type === "debt"
						? "update"
						: "deduct " + "₦" + form?.amount + " from"
				} ${
					form?.type !== "last_order"
						? currentData?.first_name + " " + currentData?.last_name
						: ""
				}${form?.type !== "last_order" ? "'s" : ""} ${
					form?.type === "debt"
						? "debt to " +
						  "₦" +
						  form?.amount +
						  " ?" +
						  `
       \n
              (Note: this will override the outstanding debt.)`
						: form?.type === "last_order"
						? ""
						: "wallet?"
				}`}
				title={generatePromptTitle(currentData)}
				onSubmit={(e) => {
					e.preventDefault();
					updateWallet();
				}}
				saveText={submitting ? "Updating..." : "Yes I'm sure"}
				loading={submitting}
				submitting={submitting}
				withGreen
				withRed
			/>

			<Prompt
				show={showPromptAlt}
				closeModal={() => {
					setShowPromptAlt(!showPromptAlt);
				}}
				title={`Are you sure you want to ${
					currentData?.blocked ? "unblock " : "block "
				} ${currentData?.first_name + " " + currentData?.last_name}`}
				onSubmit={(e) => {
					e.preventDefault();
					updateUserStatus();
				}}
				saveText={submitting ? "Updating user status..." : "Yes I'm sure"}
				loading={submitting}
				submitting={submitting}
				withGreen
				withRed
			/>
		</Layout>
	);
};

export default withAlert()(PartnerHistoryRecord);
