import moment from "moment";
import React, { Fragment } from "react";
import CardAccordion from "./CardAccordion";
import { ShopLogo, FoodCourtLogo } from "../../../assets/icons";
import { numberWithCommas } from "../../../helpers/commaSeparator";

const OrderReceiptCard = ({
	status,
	calculatedOrder,
	isGroupOrder,
	orders,
	getItemsTotal,
	time,
	order,
	mealTotal,
	subOrders,
	isCutlery,
	discountValue,
	calculatedGroupTotal,
	subTotal,
	deliveryFee,
	serviceTotal,
	extraCharges,
	calculatedTotal,
	paid,
	amountDueGroup,
	amountDue,
	orderType,
	id,
}) => {
	let calcOrder = order?.calculated_order;

	let shopPriceArray = [];
	let totalFoodItems = orders?.map((meal) =>
		meal.meals.map((item) => {
			if (item?.item_type === "SHOP") {
				let eachPrice = mealTotal(item);
				shopPriceArray.push(eachPrice);
			} else {
				return null;
			}
		})
	);

	const getTotalShopPrice = shopPriceArray?.reduce((acc, curr) => {
		return acc + curr;
	}, 0);

	return (
		<Fragment>
			{/* Receipt View Start */}
			<div className="px-3 text-center w-full">
				<div className="flext justify-center flex flex-col items-center">
					<FoodCourtLogo />
					<p className="font-bold">#{id}</p>
					{!isGroupOrder && calculatedOrder?.pickup ? (
						<p className="font-bold">PICKUP</p>
					) : null}
					{isGroupOrder ? (
						<p className="mb-1 bold helvetica ">
							Group Order {calculatedOrder?.pickup ? " - PICKUP" : ""}
						</p>
					) : null}
					{isGroupOrder ? (
						<label className={`normal_17 mb-1  text-black helvetica`}>
							{calculatedOrder?.sub_calculated_order_ids?.length + 1}{" "}
							{calculatedOrder?.sub_calculated_order_ids?.length + 1 === 1
								? "person"
								: "people"}
						</label>
					) : (
						<label className={`normal_17 mb-1  text-black helvetica`}>
							{`${orders && orders.length} ${
								orders && orders.length === 1 ? "brand" : "brands"
							}`}
							,
							{` ${orders && getItemsTotal()} ${
								orders && getItemsTotal() === 1 ? "item" : "items"
							}`}
						</label>
					)}
					<p className="helvetica">
						{time && moment(time).format("DD/MM/YYYY ")} at
						{time && moment(time).format(" LT")}
					</p>
				</div>
				{isGroupOrder ? (
					<div className="flex justify-between items-start mb-1">
						<p className="">
							<span className="bold">
								1.{" "}
								{order?.calculated_order?.user?.first_name +
									" " +
									order?.calculated_order?.user?.last_name}{" "}
							</span>
							(host)
						</p>

						<p className="">
							{`   ${orders && orders.length} ${
								orders && orders.length === 1 ? "brand" : "brands"
							}`}
							,
							{` ${orders && getItemsTotal()} ${
								orders && getItemsTotal() === 1 ? "item" : "items"
							}`}
						</p>
					</div>
				) : null}

				{calcOrder?.item_type === "ALL" && (
					<div className="flex justify-start items-start order_card_item py-1 w-100">
						<div className="flex-col flex  justify-start items-start w-100">
							<div className="flex justify-between items-center ">
								<p className="normal_17px mb-1 bold">Shop</p>₦
								{numberWithCommas(getTotalShopPrice)}
								.00
							</div>
						</div>
					</div>
				)}

				<div className="mb-4">
					{orders &&
						orders.map((itms, ind) => {
							return (
								itms?.brand?.item_type === "FOOD" && (
									<div
										className="order_card flex-col flex _left mb-2 w-100 helvetica"
										key={ind}
									>
										<div className="order_card_title mb-1 flex font-bold  mt-2 justify-center items-center p-0 w-100">
											<label className="normal_17px bold text-black text-dark">
												{itms && itms.brand && itms.brand.name}
											</label>
										</div>

										<div className="order_card_body  px-1 w-100">
											{itms &&
												itms.meals.map((item, i) => {
													mealTotal(item);

													return (
														<div
															key={i + "items"}
															className="flex justify-between items-start order_card_item py-1 w-100"
														>
															<div className="flex-col flex  items-start justify-start">
																{item?.order_note && (
																	<span className="bold mr-2 ">Note: </span>
																)}
																<h6 className="text-dark mr-2 font-semibold text-xl">
																	{item?.quantity + "×"}
																</h6>
															</div>
															<div className="flex-col flex  items-start justify-start w-100">
																<label className="normal_14px text-dark mb-1">
																	{item?.order_note}
																</label>
																<div className="flex justify-between items-center gap-6">
																	<p className="font-bold">
																		{item && item.name}
																	</p>
																	₦{numberWithCommas(mealTotal(item))}
																	.00
																</div>

																{item &&
																	item.addons &&
																	item.addons.map((itm, ind) => {
																		return (
																			<label
																				className="normal_14px text-black mt-1"
																				key={ind + "itm"}
																			>
																				{itm &&
																					itm.meal_data &&
																					itm.meal_data.name}
																				<span className="text-dark">
																					{" "}
																					x {itm && itm.quantity}
																				</span>
																			</label>
																		);
																	})}
															</div>
														</div>
													);
												})}
										</div>
									</div>
								)
							);
						})}
				</div>
				{/* receipt Sub orders */}

				{subOrders?.map((item, i) => {
					return (
						<CardAccordion
							item={item}
							i={i}
							isReceipt
							getItemsTotal={() => getItemsTotal(item?.meals)}
							body={
								item?.meals &&
								item?.meals?.map((itms, ind) => {
									return (
										<div
											className="order_card order_card_accordion flex-col flex mb-2 w-100"
											key={ind}
										>
											<div className="order_card_title mb-1 flex font-bold  mt-2 justify-center items-center p-0 w-100">
												<label className="normal_17px bold text-black text-dark">
													{itms && itms.brand && itms.brand.name}
												</label>
											</div>

											<div className="order_card_body px-1 w-100">
												{itms &&
													itms.meals.map((item, i) => {
														return (
															<div
																key={i + "items"}
																className="flex justify-between items-start order_card_item py-1 w-100"
															>
																<div className="flex-col flex  items-start justify-start">
																	{item?.order_note && (
																		<span className="bold mr-2 ">Note: </span>
																	)}
																	<h6 className="text-dark mr-2 font-semibold text-xl">
																		{item?.quantity + "×"}
																	</h6>
																</div>
																<div className="flex-col flex  items-start justify-start w-100">
																	<label className="normal_14px text-dark mb-1">
																		{item?.order_note}
																	</label>
																	<div className="flex justify-between items-center gap-6">
																		<p className="font-bold">
																			{item && item.name}
																		</p>
																		₦{numberWithCommas(mealTotal(item))}
																		.00
																	</div>

																	{item &&
																		item.addons &&
																		item.addons.map((itm, ind) => {
																			return (
																				<label
																					className="normal_14px text-black mt-1"
																					key={ind + "itm"}
																				>
																					{itm &&
																						itm.meal_data &&
																						itm.meal_data.name}
																					<span className="text-dark">
																						{" "}
																						x {itm && itm.quantity}
																					</span>
																				</label>
																			);
																		})}
																</div>
															</div>
														);
													})}
											</div>
										</div>
									);
								})
							}
						/>
					);
				})}
				{/*receipt sub orders end */}

				{/* Receipt footer start */}
				<div className="flex justify-between items-center  mb-0 px-1 font-bold">
					<p className="mb-1 normal_17px bold">Cutlery</p>
					<p className="mb-1 normal_17px font-bold">
						{isCutlery ? "YES" : "NO"}
					</p>
				</div>
				<div className=" px-1 w-100 pt-2">
					<div className="flex justify-between items-center  mb-0">
						<p className="normal_17px mb-1">Discount</p>
						<p className="normal_17px mb-1">
							₦{numberWithCommas(discountValue)}
							.00
						</p>
					</div>

					<div className="flex justify-between items-center  mb-0">
						<p className="normal_17px mb-1">Subtotal</p>

						{isGroupOrder ? (
							<p className="normal_17px mb-1">
								₦{numberWithCommas(calculatedGroupTotal())}
								.00
							</p>
						) : (
							<p className="normal_17px mb-1">
								₦{numberWithCommas(subTotal)}
								.00
							</p>
						)}
					</div>
					<div className="flex justify-between items-center  mb-0">
						<p className="normal_17px mb-1">Delivery fee </p>

						<p className="normal_17px mb-1">
							₦{numberWithCommas(deliveryFee)}
							.00
						</p>
					</div>
					<div className="flex justify-between items-center  mb-1">
						<p className="normal_17px mb-1">Service fee </p>

						<p className="normal_17px mb-1">
							₦{numberWithCommas(serviceTotal)}
							.00
						</p>
					</div>
					<div className="flex justify-between items-center mb-3">
						<p className="normal_17px mb-1">Order total</p>

						{isGroupOrder ? (
							<p className="normal_17px mb-1">
								₦{numberWithCommas(calculatedGroupTotal() + extraCharges)}
								.00
							</p>
						) : (
							<p className="normal_17px mb-1">
								₦{numberWithCommas(calculatedTotal)}
								.00
							</p>
						)}
					</div>
					{!paid ? (
						<div className="flex justify-between items-center mb-2">
							<p className="normal_17px mb-1">Amount due</p>
							{isGroupOrder ? (
								<p className="normal_17px mb-1">
									₦
									{numberWithCommas(
										(amountDueGroup && amountDueGroup + extraCharges) || 0
									)}
									.00
								</p>
							) : (
								<p className="normal_17px mb-1">
									₦{numberWithCommas((amountDue && amountDue) || 0)}
									.00
								</p>
							)}
						</div>
					) : null}
					<div className="flex justify-between items-center mb-3">
						<p className="normal_17px mb-1">Payment</p>

						<p className="normal_17px mb-1">{orderType?.name}</p>
					</div>

					<p className="normal_17px mb-2 text-center">
						7.5% tax included in price
					</p>

					<p className="normal_17px mb-2 text-center">
						complaints? email us <br />
						hello@getfoodcourt.com
					</p>

					<p className="normal_17px mb-2 text-center">www.getfoodcourt.com</p>

					<p className="normal_17px mb-1 text-center">
						Thanks, order again soon!
					</p>
				</div>

				{/* Receipt footer end */}
			</div>

			{/* Receipt View End */}
		</Fragment>
	);
};

export default OrderReceiptCard;
