import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header";
import { ArrowBack, Clock, User, Stars } from "../../assets/icons/icons";
import OrderCard from "../../components/orderCard";
import { IoMdFlash } from "react-icons/io";
import { AiTwotoneDislike } from "react-icons/ai";
import { IoBagHandle } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import {
	HOME_S_ADMIN,
	LIVE_TRIPS,
	NOTIFICATIONS_S_ADMIN,
} from "../../router/router";
import homeStore from "../../stores/superAdminStores/homeStore";
import Map from "../../components/map";
import { withAlert } from "react-alert";
import moment from "moment";
import "./styles.scss";
import { numberWithCommas } from "../../helpers/commaSeparator";
import Button from "../../components/buttons";
import Select from "../../components/select";
import IconInput from "../../components/inputs/iconInput";
import Prompt from "../../components/modals/prompt";
import dataService from "../../services/data.service";
import { SEARCH_ORDER_URL } from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
import Loader from "../../components/loader";
import CardAccordion from "../../components/cardAccordion";
import NewIcon from "../../assets/images/NEW-icon.png";
import { GiStabbedNote } from "react-icons/gi";
import Modal from "../../components/modals";
const MapPage = ({ alert, ...props }) => {
	const history = useHistory();
	const path = props.match.params.id;
	const pathType = props.match.params.type;
	const [logistics, setLogistics] = useState(null);
	const [details, setDetails] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [showPrompt, setShowPrompt] = useState(false);
	const [submitting, setSubmitting] = useState(null);
	const [newAmount, setNewAmount] = useState("");
	const [searchLoading, setSearchLoading] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const [currentOrder, setCurrentOrder] = useState(null);
	const [riders, setRiders] = useState([]);
	const [selectedRider, setSelectedRider] = useState("");
	const [ridersLoading, setRidersLoading] = useState(false);
	// load data from store
	useEffect(async () => {
		setSearchLoading(true);
		if (!homeStore.dataCached) {
			await homeStore.getLogistics();
		}

		setLogistics(homeStore.logistics);

		findCurrentTrip();
	}, []);

	useEffect(() => {
		(async () => {
			setRidersLoading(true);
			if (!homeStore.dataCached) {
				const data = await Promise.all([homeStore.getLogistics(alert, false)]);
			}
			setRidersLoading(false);
		})();
	}, []);
	useEffect(() => {
		sortRiders();
	}, [showModal, showPrompt]);

	const sortRiders = () => {
		let ridersArr = [];
		for (
			let i = 0,
				partners = homeStore?.logistics?.fleets,
				len = homeStore?.logistics?.fleets?.length;
			i < len;
			i++
		) {
			if (partners[i].role === "RIDER") {
				ridersArr.push({
					label: partners[i]?.first_name + " " + partners[i]?.last_name,
					value: partners[i]?.id,
				});
			}
		}
		setRiders(ridersArr);

		return ridersArr;
	};

	// search order api
	const sendQuery = async () => {
		try {
			setSearchLoading(true);
			let payload = { search_query: path };
			const response = await dataService.postAuthData(
				SEARCH_ORDER_URL + path,
				payload
			);
			let res = response?.data?.data;
			setDetails(res && res[0]);
		} catch (error) {
			errorHandler(error, alert);
		} finally {
			setSearchLoading(false);
		}
	};
	// FInd currently selected trip
	const findCurrentTrip = async (allTrips = homeStore?.allTrips) => {
		let active = await allTrips.find((item) => item.order_code === path);
		if (active) {
			setDetails(active);
			setSearchLoading(false);
			return active;
		} else {
			sendQuery();
		}
	};

	// Reducer function to compute total
	const getTotal = (items) => {
		let total = items.reduce(function (acc, curr) {
			return acc + curr.quantity;
		}, 0);
		return total;
	};

	// To calculate the total number of items bought
	const getItemsTotal = (orders) => {
		let i;
		let tot = [];
		for (i = 0; i < orders.length; i++) {
			tot.push(getTotal(orders[i].meals));
		}
		let allTotals = tot.reduce((acc, curr) => {
			return acc + curr;
		}, 0);

		return allTotals;
	};

	const orderDetails = details?.order_details;

	let totalAmt = details?.order_details?.use_wallet
		? parseInt(details?.calculated_order?.total_amount) +
		  parseInt(details?.change) -
		  parseInt(details?.order_details?.wallet_amount)
		: parseInt(details?.calculated_order?.total_amount) +
		  parseInt(details?.change);

	let walletAmt = details?.order_details?.use_wallet
		? parseInt(details?.order_details?.wallet_amount)
		: 0;

	const updateOrderAmt = async () => {
		const { updateOrderAmount } = homeStore;
		if (!newAmount) {
			alert.show("Please enter new amount!", {
				type: "info",
			});
		} else {
			setSubmitting(true);

			let body = { order_code: path, new_amount: newAmount };

			await updateOrderAmount(alert, body);
			setSubmitting(false);
			setShowPrompt(false);
		}
	};

	const reassignRider = async () => {
		setSubmitting(true);
		await homeStore.reassignRider(alert, {
			order_id: details?.id,
			rider_id: selectedRider,
		});
		setSubmitting(false);
		setShowModal(false);
	};
	// calculated total for single order
	const calculatedTotal = parseInt(details?.calculated_order?.total_amount);

	const serviceTotal = parseInt(details?.calculated_order?.service_charge);

	const deliveryFee = parseInt(
		(details?.calculated_order?.free_delivery && "0") ||
			details?.calculated_order?.delivery_fee
	);

	//  serviceTotal + deliveryFee
	const extraCharges = serviceTotal + deliveryFee;

	const subTotal = calculatedTotal - extraCharges;
	//if order is a group order
	const isGroupOrder = details?.calculated_order?.is_group_order ? true : false;
	const subOrders = details?.calculated_order?.sub_calculated_order_ids || null;
	// calculated total for group order
	const calculatedGroupTotal = (due) => {
		let alltotal = subOrders?.reduce((acc, curr) => {
			return acc + (parseFloat(curr.total_amount) - extraCharges);
		}, 0);

		if (due) {
			return alltotal + totalAmt;
		} else {
			return alltotal + subTotal;
		}
	};

	const groupCount =
		details?.calculated_order?.sub_calculated_order_ids?.length + 1;
	return (
		<main className="main_container">
			<div className="main_container_content">
				<Header />
				<section className="content_section">
					<div className="main_section_sec p_40">
						<div className="flex_row_between title_bar">
							<div className="flex_row_center">
								<button
									className="mr_45 cursor_pointer"
									onClick={() => {
										if (pathType === "home") {
											history.push(HOME_S_ADMIN);
										} else if (pathType === "notification") {
											history.push(NOTIFICATIONS_S_ADMIN);
										} else {
											history.push(LIVE_TRIPS);
										}
									}}
								>
									<ArrowBack width="17px" height="17px" fill="#FF0000" />
								</button>

								<h6 className="bold_24px mr_10">#{path} </h6>

								<label className="gray normal_14px">
									{moment(details?.order_type?.created_at).format("LT")} |{" "}
									{moment(details?.order_type?.created_at).format(
										"DD MMM YYYY"
									)}
								</label>
							</div>
						</div>
						<section className="top_section_sec flex_row_between ml_75 mobile_column_wide">
							<div className="flex_column_left">
								<div className="flex_row_align_center">
									<span className="normal_53px green_text mr_10">•</span>
									<p className="normal_15px">
										From: <span className="bold">CoKitchen Lekki </span>
									</p>
								</div>

								<div className="flex_row_align_center">
									<span className="normal_53px red_text mr_10">•</span>
									<p className="normal_15px">
										To:{" "}
										<span className="bold">
											{details?.calculated_order?.address_details?.address_line}
										</span>
									</p>
								</div>
							</div>
							{/* top middle */}

							<div className="flex_column_left">
								<div className="flex_row_align_center mb_15">
									<span className="mr_10">
										<Clock
											fill=" rgba(0, 0, 0, 0.214)"
											width="7px"
											height="7px"
										/>
									</span>
									<p className="normal_15px bold green_text">Completed</p>
								</div>

								<div className="flex_row_align_center">
									<span className="mr_10">
										<User
											fill="rgba(0, 0, 0, 0.214)"
											width="7px"
											height="7px"
										/>
									</span>
									<p className="normal_15px">
										{(details &&
											details.rider &&
											details.rider.first_name +
												" " +
												details.rider.last_name +
												" (Rider)") ||
											"No rider yet"}
									</p>
								</div>
								<div className="flex_row_align_center">
									<span className="mr_10">
										<GiStabbedNote color="rgba(0, 0, 0, 0.214)" size="7px" />
									</span>
									<p className="normal_15px">
										{orderDetails?.rider_note || "___"}
									</p>
								</div>
							</div>

							{/* top last */}

							<div className="flex_column_right mobile_column_wide">
								<h6 className="bold_24px green_text mb_15">
									₦{" "}
									{details?.calculated_order?.delivery_fee &&
										numberWithCommas(
											parseInt(details.calculated_order.delivery_fee)
										)}
								</h6>
								<label className="normal_15px text_right">
									Total trip time: 12mins <br />
									Total trip distance: 2km
								</label>
							</div>
						</section>

						<section className="flex_row_top ml_35_0_0_75 tab_column">
							<section className="left_section_sec flex_column_left">
								<p className="bold_21px mb_25">User rating & feedback</p>
								<div className="mb_35">
									<Stars /> <span className="normal_px_20 ml_10">4/5</span>
								</div>

								<p className="bold_21px mb_25">Improvements requested</p>

								<div className="flex_column_left mb_15">
									<div className="flex_row_start mb_17">
										<IoMdFlash
											fill="#C4C4C4"
											width="7px"
											height="7px"
											className="mr_15"
										/>
										<p className="normal_15px">Faster delivery</p>
									</div>
									<div className="flex_row_start mb_17">
										<AiTwotoneDislike
											fill="#C4C4C4"
											width="7px"
											height="7px"
											className="mr_15"
										/>
										<p className="normal_15px">Rider behaviour</p>
									</div>
									<div className="flex_row_start mb_17">
										<IoBagHandle
											fill="#C4C4C4"
											width="7px"
											height="7px"
											className="mr_15"
										/>
										<p className="normal_15px">Food handling</p>
									</div>
								</div>

								<p className="bold_21px mb_25">Comment</p>
								<p className="normal_15px mb_45">N/A</p>
								{/* Order detail */}
								<div className="order_detail_table flex_column_left">
									<div className="d-justify-between d-align-start mb_45">
										<div className="flex-column d-align-start d-justify-start">
											<p className="bold_21px mr-1 mb-1">
												{(isGroupOrder && "Group order details") ||
													"Order details"}
												:
											</p>
											<label className="bold_21px mb_5">
												for {groupCount}{" "}
												{groupCount === 1 ? "Person" : "People"}
											</label>
										</div>
										<div className="flex_column_right">
											<label className="bold_21px mb_12">
												{(details?.calculated_order?.meals?.length > 1 &&
													details.calculated_order.meals.length + " brands") ||
													details?.calculated_order?.meals?.length + " brand"}
												,
												{`${
													details?.calculated_order?.meals &&
													getItemsTotal(details?.calculated_order?.meals)
												} ${
													details?.calculated_order?.meals &&
													getItemsTotal(details?.calculated_order?.meals) === 1
														? "item"
														: "items"
												}`}
											</label>
											<label className="bold_21px mb_5">
												Amount paid by wallet: ₦{numberWithCommas(walletAmt)}
											</label>
											<label className="bold_21px mb_12">
												Order total: ₦
												{isGroupOrder
													? numberWithCommas(
															calculatedGroupTotal() + extraCharges
													  )
													: details?.calculated_order?.total_amount &&
													  numberWithCommas(
															details.calculated_order.total_amount
													  )}
											</label>
											<label className="bold_21px mb_12 green_text">
												₦
												{isGroupOrder
													? numberWithCommas(calculatedGroupTotal(true))
													: numberWithCommas(totalAmt)}{" "}
												Cash{" "}
												{details?.bank_transfer ? "Transferred" : "Received"}
											</label>
											{/* <label className="bold_21px mb_12">
                        Change to customer wallet: ₦400
                      </label> */}
										</div>
									</div>
									{/* host order */}
									<div
										className={`py-2 d-flex d-justify-between d-align-center active-accordion-head`}
									>
										<p
											className="bold_21px pointer"
											style={{ width: "40%" }}
											onClick={() => {
												setCurrentOrder(details);
												setShowDetails(true);
											}}
										>
											1.{" "}
											{details?.calculated_order?.user?.first_name +
												" " +
												details?.calculated_order?.user?.last_name}{" "}
											{details?.first_order ? (
												<img
													src={NewIcon}
													className="ml-1"
													alt="new"
													style={{ width: 60, height: 30 }}
												/>
											) : null}
										</p>
										<p className={`bold_21px text-left`}>
											{` ${details?.calculated_order?.meals?.length} ${
												details?.calculated_order?.meals?.length === 1
													? "brand"
													: "brands"
											}`}
											,
											{` ${
												details?.calculated_order?.meals &&
												getItemsTotal(details?.calculated_order?.meals)
											} ${
												details?.calculated_order?.meals &&
												getItemsTotal(details?.calculated_order?.meals) === 1
													? "item"
													: "items"
											}`}
										</p>
									</div>
									{details?.calculated_order?.meals.map((item, i) => {
										return (
											<OrderCard
												title={item && item.brand && item.brand.name}
												items={item && item.meals}
												key={"item" + i}
											/>
										);
									})}

									{/* Sub orders start */}
									{subOrders?.map((item, i) => {
										return (
											<CardAccordion
												item={item}
												i={i}
												getItemsTotal={() => getItemsTotal(item?.meals)}
												setCurrentOrder={(e) => setCurrentOrder(e)}
												setShowDetails={(e) => setShowDetails(e)}
												body={
													item?.meals &&
													item?.meals?.map((itms, ind) => {
														return (
															<div
																className="order_card order_card_accordion flex-column_left mb-2 w-100"
																key={ind}
															>
																<div className="order_card_title mb_15 d-justify-left d-align-center p_20 w-100">
																	<label className="normal_17px bold">
																		{itms && itms.brand && itms.brand.name}
																	</label>
																</div>

																<div className="order_card_body px-1 w-100">
																	{itms &&
																		itms.meals.map((item, i) => {
																			return (
																				<div
																					key={i + "items"}
																					className="d-justify-start d-align-start order_card_item py-2"
																				>
																					<div className="flex-column d-align-start d-justify-start">
																						{item && item.order_note && (
																							<span className="normal_17px bold mr-2">
																								Note:{" "}
																							</span>
																						)}
																						<h6 className="normal_17px bold text-danger mr-2">
																							{item && item.quantity + "×"}
																						</h6>
																					</div>
																					<div className="flex-column d-align-start d-justify-start">
																						<label className="normal_14px text-dark mb-1">
																							{item && item.order_note}
																						</label>

																						<p className="normal_17px mb-1 ">
																							{item && item.name}
																						</p>
																						{item &&
																							item.addons &&
																							item.addons.map((itm, ind) => {
																								return (
																									<label
																										className="normal_14px text-black mt-1"
																										key={ind + "itm"}
																									>
																										{itm &&
																											itm.meal_data &&
																											itm.meal_data.name}
																										<span className="red_text">
																											{" "}
																											x {parseInt(itm.quantity)}
																										</span>
																									</label>
																								);
																							})}
																					</div>
																				</div>
																			);
																		})}
																</div>
															</div>
														);
													})
												}
											/>
										);
									})}
									{/* sub orders end */}
								</div>
							</section>
							<section className="right_section_sec map_section flex_column_left">
								<div className="flex_row_between map_label">
									<p className="mb_25 bold_21px">Trip route </p>
								</div>

								<Map
									className="app_map app_map_detail"
									lat={details && details?.calculated_order?.cokitchen?.lat}
									lng={details && details?.calculated_order?.cokitchen?.lng}
									userLat={
										details?.calculated_order?.lat &&
										parseFloat(details.calculated_order.lat)
									}
									userLng={
										details?.calculated_order?.lng &&
										parseFloat(details.calculated_order.lng)
									}
								/>

								<div className="p_20 mt_35 card flex_column_start width_full">
									<p className="mb_30 bold_21px">User information </p>
									<div className="flex_row_between tab_column width_full">
										<p className="normal_15px bold mb_15">
											Name:{" "}
											{details?.user?.first_name +
												" " +
												details?.user?.last_name}
										</p>
										<p className="normal_15px bold mb_15">
											Email: {details?.user?.email}
										</p>
									</div>

									<div className="flex_row_between tab_column width_full mb_35">
										<p className="normal_15px bold mb_15">
											Phone: {details?.user?.phone_number}
										</p>
										<p className="normal_15px bold mb_15">
											user since:{" "}
											{details?.user?.created_at &&
												moment(details.user.created_at).format("DD/MM/YYYY")}
										</p>
									</div>
								</div>
								<div className="width_full flex_column_start">
									<Button
										className="green_button width_full my-1"
										text="Update order amount"
										onClick={() => setShowPrompt(true)}
									/>
									<Button
										className="green_button width_full my-1"
										text="Reassign rider"
										onClick={() => setShowModal(true)}
									/>
								</div>
							</section>
						</section>
					</div>

					<Prompt
						show={showPrompt}
						closeModal={() => setShowPrompt((prev) => !prev)}
						title={"Fill in the new order amount"}
						content={
							<div className="flex_column_start width_full">
								<IconInput
									required
									withLabel
									label="New amount"
									placeholder="Enter new amount"
									bold
									type="number"
									containerClass="mb_25 width_full"
									value={newAmount}
									onChange={(e) => setNewAmount(e)}
								/>
							</div>
						}
						onSubmit={(e) => {
							e.preventDefault();
							updateOrderAmt();
						}}
						saveText={submitting ? "Saving..." : "Save"}
						loading={submitting}
						submitting={submitting}
						withGreen
						withRed
					/>

					<Modal
						showModal={showModal}
						closeModal={() => setShowModal((prev) => !prev)}
						title={`Reassign Rider for order ${path}`}
						onSubmit={(e) => {
							e.preventDefault();

							reassignRider();
						}}
						content={
							<div className="width_full">
								<Select
									placeholder="Select Rider"
									defaultValue={{
										label:
											details?.rider?.first_name +
											" " +
											details?.rider?.last_name,
										value: details?.rider?.id,
									}}
									containerClass="width_full mb_15"
									options={riders}
									handleChange={(e) => {
										setSelectedRider(e.value);
									}}
									isLoading={ridersLoading}
								/>
							</div>
						}
						saveText={submitting ? "Assigning rider..." : "Confirm"}
						submitting={submitting}
					/>
				</section>
			</div>

			<Loader
				loading={searchLoading}
				style={{ backgroundColor: "#2698fb2f", top: "0px" }}
				logoStyle={{
					marginTop: "40%",
					marginLeft: "auto",
					marginRight: "auto",
				}}
			/>
		</main>
	);
};
export default withAlert()(MapPage);
