import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
} from "react";
import Layout from "../../components/layout";
import AltCard from "../../components/card/altCard";
//import DateTime from "react-datetime";
import { Link } from "react-router-dom";
//import moment from "moment";
import "./styles.scss";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { BiSearchAlt2 } from "react-icons/bi";
import Input from "../../components/inputs";
import IconInput from "../../components/inputs/iconInput";
import Button, { IconButton } from "../../components/buttons";
import Select from "../../components/select";
import {
	AppMap,
	Stars,
	ChevronRight,
	Clock,
	User,
} from "../../assets/icons/icons";
import { FULL_TRIP_DETAILS } from "../../router/router";
import { withAlert } from "react-alert";
import homeStore from "../../stores/superAdminStores/homeStore";
import CommonStore from "../../stores/superAdminStores/commonStore";
import Loader from "react-loader-spinner";
import db from "../../services/firebase.config";
import moment from "moment";
import Map from "../../components/map";
import { numberWithCommas } from "../../helpers/commaSeparator";
import { RiEBikeFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLoadItems } from "../../helpers/useLoadItems";
import useInfiniteLoad from "../../helpers/useInfiniteLoad";
import { SEARCH_ORDER_URL } from "../../helpers/urls";
import debounce from "lodash.debounce";
import errorHandler from "../../helpers/errorHandler";
import dataService from "../../services/data.service";
import { FiChevronsDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import { TiGroup } from "react-icons/ti";
import { FaUserAlt } from "react-icons/fa";
import { CSVLink } from "react-csv";
import axios from "axios";
import NewAltCard from "../../components/card/NewAltCard";

const LiveHistoryFeedBack = ({ alert }) => {
	const partners = [];
	const [cokitchens, setCokitchens] = useState([]);
	const [activeOngoingTrip, setActiveOngoingTrip] = useState("");
	const [activeRiderPos, setActiveRiderPos] = useState("");
	const [location, setLocation] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [searchedValue, setSearchedValue] = useState("");
	const [cancelTok, setCancelTok] = useState(null);
	const [searchValueAlt, setSearchValueAlt] = useState("");
	const [refreshCount, setRefreshCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [showAllCompletedTrips, setShowAllCompletedTrips] = useState(false);
	const [logistics, setLogistics] = useState(null);
	const [ongoingTrips, setOngoingTrips] = useState([]);
	const ongoingTripsPlaceholder = ["", "", "", ""];
	const [todayCompletedTrips, setTodayCompletedTrips] = useState([]);
	const [yesterdayCompletedTrips, setYesterdayCompletedTrips] = useState([]);
	const [allCompletedTrips, setAllCompletedTrips] = useState([]);
	const [showTodayTrips, setShowTodayTrips] = useState(true);
	const [showYesterdayTrips, setShowYesterdayTrips] = useState(true);
	const [searchChanged, setSearchChanged] = useState(false);
	const [searchLoading, setSearchLoading] = useState(false);
	const [searchResult, setSearchResult] = useState(null);

	const [currentItems, setCurrentItems] = useState(null);
	const [pageCount, setPageCount] = useState(0);
	const [page, setPage] = useState(homeStore?.tripPage);

	// Infinite scrolling
	const {
		isLoading,
		error,
		list,
		todayStart,
		yesterdayStart,
		errorEncountered,
	} = useInfiniteLoad(page, alert, moment);
	const loader = useRef(null);
	const handleObserver = useCallback((entries) => {
		const target = entries[0];
		if (target.isIntersecting && !errorEncountered) {
			setPage((prev) => prev + 1);
		}
	}, []);

	useEffect(() => {
		const option = {
			root: null,
			rootMargin: "20px",
			threshold: 0,
		};
		const observer = new IntersectionObserver(handleObserver, option);
		if (loader.current) observer.observe(loader.current);
	}, [handleObserver]);

	let cancelToken;
	const sendQuery = async () => {
		//Check if there are any previous pending requests
		if (cancelTok) {
			cancelTok.cancel("Cancelling in previous search");
		}
		//Save the cancel token for the current request
		cancelToken = axios.CancelToken.source();
		setCancelTok(axios.CancelToken.source());
		try {
			setSearchLoading(true);
			setSearchResult(null);
			let payload = { search_query: searchValue };
			const response = await dataService.postAuthData(
				SEARCH_ORDER_URL + searchValue,
				payload,
				cancelToken.token
			);
			let res = response?.data?.data;
			setSearchResult(res);
		} catch (error) {
			errorHandler(error, alert);
		} finally {
			setSearchLoading(false);
			setSearchChanged(false);
			setSearchedValue();
		}
	};
	const delayedQuery = useCallback(debounce(sendQuery, 1000), [searchValue]);
	// const delayedQuery = () => {}

	useEffect(() => {
		// if (searchChanged && !searchLoading) {
		if (searchChanged && searchValue?.length > 4) {
			delayedQuery();
			return delayedQuery.cancel;
		}
	}, [searchValue, delayedQuery]);

	// // Infinite scroll
	// const { isLoading, items, hasNextPage, error, loadMore } = useLoadItems(
	//   alert,
	//   1
	// )
	// const [infiniteRef, { rootRef }] = useInfiniteScroll({
	//   isLoading,
	//   hasNextPage: false,
	//   onLoadMore: loadMore,
	//   // disabled: !!error,
	//   disabled: !!error,
	//   rootMargin: '0px 0px 400px 0px'
	// })
	// Scrollto top of orders
	const orderRef = useRef(null);
	const scrollToElement = () =>
		orderRef.current.scrollIntoView({ behavior: "smooth" });
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);

	const itemsPerPage = 30;
	// const valid = (current) => {
	//   return current.isAfter(moment(startDate));
	// };

	// Invoke when user click to request another page.
	const handlePageClick = (event) => {
		let tripsCopy = homeStore?.logistics?.trips
			?.sort((a, b) => new Date(b.completed_time) - new Date(a.completed_time))
			?.filter(
				(item) =>
					(item?.rider?.first_name + " " + item?.rider?.last_name)
						?.toUpperCase()
						.includes(searchValue.toUpperCase()) ||
					item?.order_code?.toUpperCase().includes(searchValue.toUpperCase()) ||
					(item?.user?.first_name + " " + item?.user?.last_name)
						?.toUpperCase()
						.includes(searchValue.toUpperCase()) ||
					item?.calculated_order?.address_details?.address_line
						?.toUpperCase()
						.includes(searchValue.toUpperCase())
			);
		const newOffset = (event.selected * itemsPerPage) % tripsCopy?.length;

		setItemOffset(newOffset);
	};

	//csv headers
	const titles = [
		{ label: "Customer", key: "customer" },
		{ label: "Rider", key: "rider" },
		{ label: "Delivery Fee", key: "delivery_fee" },
	];
	// csv body
	const csvBody =
		ongoingTrips &&
		ongoingTrips
			.sort(
				(a, b) =>
					new Date(a?.calculated_order?.created_at) -
					new Date(b?.calculated_order?.created_at)
			)
			.sort(
				(a, b) =>
					new Date(b.kitchen_dispatched_time) -
					new Date(a.kitchen_dispatched_time)
			)
			.map((item) => {
				return {
					customer:
						item?.calculated_order?.user?.first_name +
						" " +
						item?.calculated_order?.user?.last_name,
					rider: item?.rider?.first_name + " " + item?.rider?.last_name,
					delivery_fee: `₦
          ${
						item?.calculated_order?.delivery_fee &&
						numberWithCommas(parseInt(item?.calculated_order?.delivery_fee))
					}`,
				};
			});

	// load data from store
	useEffect(async () => {
		// if (!homeStore.dataCached) {
		await loadData();
		//  }
		setLoading(false);
		setLogistics(homeStore.logistics);
		setOngoingTrips(homeStore.ongoingTrips);
		sortTrips();
		setCokitchens(CommonStore.cokitchens);
		setLocation(
			CommonStore?.cokitchens?.find(
				(item) => item?.id === "85c7d173-56ed-44c8-8335-ef0eecabf454"
			)
		);
		// Fetch items from another resources.

		let tripsCopy = homeStore?.logistics?.trips
			?.filter((item, i) => item.completed)
			?.sort((a, b) => new Date(b.completed_time) - new Date(a.completed_time))
			?.filter(
				(item) =>
					(item?.rider?.first_name + " " + item?.rider?.last_name)
						?.toUpperCase()
						.includes(searchValue.toUpperCase()) ||
					item?.order_code?.toUpperCase().includes(searchValue.toUpperCase()) ||
					(item?.user?.first_name + " " + item?.user?.last_name)
						?.toUpperCase()
						.includes(searchValue.toUpperCase()) ||
					item?.calculated_order?.address_details?.address_line
						?.toUpperCase()
						.includes(searchValue.toUpperCase())
			);
		const endOffset = itemOffset + itemsPerPage;

		setCurrentItems(tripsCopy?.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(tripsCopy?.length / itemsPerPage));
	}, [itemOffset, itemsPerPage]);

	useEffect(async () => {
		if (refreshCount) {
			await loadData();
		}
	}, [refreshCount]);

	useEffect(() => {
		setActiveOngoingTrip(homeStore.ongoingTrips && homeStore.ongoingTrips[0]);
	}, []);

	useEffect(async () => {
		if (
			activeOngoingTrip &&
			activeOngoingTrip.rider &&
			activeOngoingTrip.rider.id
		) {
			const response = await db
				.collection("rider_locations")
				.doc(activeOngoingTrip?.rider?.id)
				.onSnapshot(
					(items) => {
						// items.forEach((doc) => {
						//   //   ongoing.push(doc.data());
						// });
						setActiveRiderPos(items.data());
					},
					(err) => {
						errorHandler(err, alert);
					}
				);
		} else {
			setActiveRiderPos(null);
		}
	}, [activeOngoingTrip]);

	// load data from store
	useEffect(async () => {
		// Fetch items from another resources.
		let tripsCopy = homeStore?.logistics?.trips
			?.sort((a, b) => new Date(b.completed_time) - new Date(a.completed_time))
			?.filter(
				(item) =>
					(item?.rider?.first_name + " " + item?.rider?.last_name)
						?.toUpperCase()
						.includes(searchValue.toUpperCase()) ||
					item?.order_code?.toUpperCase().includes(searchValue.toUpperCase()) ||
					(item?.user?.first_name + " " + item?.user?.last_name)
						?.toUpperCase()
						.includes(searchValue.toUpperCase()) ||
					item?.calculated_order?.address_details?.address_line
						?.toUpperCase()
						.includes(searchValue.toUpperCase())
			);
		const endOffset = itemOffset + itemsPerPage;

		setCurrentItems(tripsCopy?.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(tripsCopy?.length / itemsPerPage));
	}, [itemOffset, itemsPerPage, searchValue]);
	// load data from store
	const loadData = async () => {
		setLoading(true);
		let ongoing = [];
		homeStore.updateOngoingTrips([]);
		const response = db.collection("tracking_orders").onSnapshot(
			(items) => {
				ongoing = [];
				items.forEach((doc, i, arr) => {
					ongoing.push(doc.data());
				});
				setOngoingTrips(ongoing);
				homeStore.updateOngoingTrips(ongoing);
				// if (ongoingTrips.length === 0) {

				// }
			},
			(err) => {
				errorHandler(err, alert);
			}
		);

		if (!homeStore.dataCached) {
			const data = await Promise.all([
				homeStore.getLogistics(alert, true),
				CommonStore.getCokitchens(alert),
			]);
		}
		setLoading(false);

		setLogistics(homeStore.logistics);
		setCokitchens(CommonStore.cokitchens);
	};

	// Sort trips into today, yesterday and all time
	const sortTrips = (allTrips) => {
		let todTrips = [];
		let yesTrips = [];
		let today = moment().format("DD-MM-YYYY");
		let yesterday = moment().add(-1, "days").format("DD-MM-YYYY");
		allTrips =
			homeStore?.logistics?.trips &&
			homeStore.logistics.trips.filter((item, i) => item.completed);
		for (let i = 0, len = allTrips?.length || []; i < len; i++) {
			if (moment(allTrips[i].created_at).format("DD-MM-YYYY") === today) {
				todTrips.push(allTrips[i]);
			} else if (
				moment(allTrips[i].created_at).format("DD-MM-YYYY") === yesterday
			) {
				yesTrips.push(allTrips[i]);
			}
		}
		setAllCompletedTrips(allTrips);
		setTodayCompletedTrips(todTrips);
		setYesterdayCompletedTrips(yesTrips);
	};
	let active = activeOngoingTrip && activeOngoingTrip;

	return (
		<Layout
			alert={alert}
			locationChange={() => setLocation()}
			locationValue={location}
			locationLoading={loading}
			details="active_nav_item"
			withHistoryBtn
			noPaymentBtn
			title={
				<h6 className="bold_24px mr_30 green_text">
					LIVE On-going Trips ({ongoingTrips ? ongoingTrips.length : "---"})
				</h6>
			}
			serachBar={
				<IconInput
					containerClass="width_full"
					placeholder="Search by code, rider name, customer name or address"
					withIcon
					icon={<BiSearchAlt2 width="20px" height="20px" fill="#8B8B8B" />}
					value={searchValueAlt}
					onChange={(e) => setSearchValueAlt(e)}
				/>
			}
			options={cokitchens}
			onRefresh={() => setRefreshCount(refreshCount + 1)}
			refreshLoading={refreshCount && loading}
		>
			<section className="main_section  content_section flex_column_start mb_45">
				<section className="flex_row_top tab_column mb_45 width_full ongoing-trip">
					<section className="left_section pr-1 width_full overflow-y-scroll scroll-container pt-1 ongoing-trip-left ">
						{/* Left */}

						{/* Loader */}

						{/* {loading &&
              ongoingTripsPlaceholder.map((item, i) => {
                return <AltCard key={i + "card"} loading={true} />;
              })} */}

						<div className="card_flex">
							{ongoingTrips &&
								ongoingTrips
									.sort(
										(a, b) =>
											new Date(a?.calculated_order?.created_at) -
											new Date(b?.calculated_order?.created_at)
									)
									.sort(
										(a, b) =>
											new Date(b.kitchen_dispatched_time) -
											new Date(a.kitchen_dispatched_time)
									)

									.filter(
										(item) =>
											(item?.rider?.first_name + " " + item?.rider?.last_name)
												?.toUpperCase()
												.includes(searchValueAlt.toUpperCase()) ||
											item?.order_code
												?.toUpperCase()
												.includes(searchValueAlt.toUpperCase()) ||
											(
												item?.calculated_order?.user?.first_name +
												" " +
												item?.calculated_order?.user?.last_name
											)
												?.toUpperCase()
												.includes(searchValueAlt.toUpperCase()) ||
											item?.calculated_order?.address_details?.address_line
												?.toUpperCase()
												.includes(searchValueAlt.toUpperCase())
									)

									.map((item, i) => {
										return (
											<NewAltCard
												alert={alert}
												loading={false}
												ridersLoading={loading}
												key={i + "card"}
												pathType="live"
												order={item}
												status={
													item?.calculated_order?.pickup ? (
														<strong className="bold">PICKUP</strong>
													) : item?.kitchen_dispatched ? (
														"ongoing"
													) : item.kitchen_accepted &&
													  (["FOOD"].includes(
															item?.calculated_order?.item_type
													  )
															? item.kitchen_prepared
															: ["SHOP"].includes(
																	item?.calculated_order?.item_type
															  )
															? item.shop_prepared
															: item.kitchen_prepared && item.shop_prepared) &&
													  !item?.kitchen_dispatched &&
													  !item?.calculated_order?.pickup ? (
														"ready"
													) : item.kitchen_accepted &&
													  (["FOOD"].includes(
															item?.calculated_order?.item_type
													  )
															? !item.kitchen_prepared
															: ["SHOP"].includes(
																	item?.calculated_order?.item_type
															  )
															? !item.shop_prepared
															: !item.kitchen_prepared ||
															  !item.shop_prepared) &&
													  !item?.kitchen_dispatched ? (
														"processing"
													) : (
														"pending"
													)
												}
												onClick={() => setActiveOngoingTrip(item)}
												name={item.name}
												active={
													activeOngoingTrip && activeOngoingTrip.id === item.id
												}
												time={
													item?.kitchen_dispatched_time &&
													moment(item.kitchen_dispatched_time).format("LT")
												}
												date={
													item?.kitchen_dispatched_time &&
													moment(item.kitchen_dispatched_time).format(
														"DD MMM YYYY"
													)
												}
												id={item && item.order_code}
												from={"CoKitchen"}
												address={
													item &&
													item.calculated_order &&
													item.calculated_order.address_details &&
													item.calculated_order.address_details.address_line
												}
												price={
													item?.calculated_order?.delivery_fee &&
													numberWithCommas(
														parseInt(item.calculated_order.delivery_fee)
													)
												}
												toDestination={item.g}
												logistics={item.g}
												rider={
													item?.calculated_order?.pickup ? (
														<strong className="bold">PICKUP</strong>
													) : item?.rider?.first_name ? (
														item?.rider?.first_name + " " + item.rider.last_name
													) : (
														"No rider yet"
													)
												}
											/>
										);
									})}
						</div>

						{!loading && ongoingTrips.length === 0 ? (
							<div className="gray_label bold_24px mb_35">No LIVE Trips</div>
						) : null}
					</section>

					<section className="right_section ongoing-trip-right">
						{/* Right */}
						<Map
							className="app_map"
							lat={location?.lat && parseFloat(location.lat)}
							lng={location?.lng && parseFloat(location.lng)}
							userLat={
								activeOngoingTrip &&
								activeOngoingTrip.calculated_order &&
								activeOngoingTrip.calculated_order.lat &&
								parseFloat(activeOngoingTrip.calculated_order.lat)
							}
							userLng={
								activeOngoingTrip &&
								activeOngoingTrip.calculated_order &&
								activeOngoingTrip.calculated_order.lng &&
								parseFloat(activeOngoingTrip.calculated_order.lng)
							}
							riderLat={
								activeRiderPos &&
								activeRiderPos.lat &&
								parseFloat(activeRiderPos.lat)
							}
							riderLng={
								activeRiderPos &&
								activeRiderPos.lat &&
								parseFloat(activeRiderPos.lng)
							}
							dispatched={
								activeOngoingTrip && activeOngoingTrip.kitchen_dispatched
							}
						/>
					</section>
				</section>

				<section className="mt_35 width_full flex_column_start">
					<div className="flex_row_between mb_35 width_full">
						<div className="flex_row_start">
							<h6 className="bold_24px mr_10 mr_25">Trip history & feedback</h6>

							<Select
								placeholder="Locations: ALL"
								defaultValue={{ label: "Locations: ALL", value: "all" }}
								options={[{ label: "Locations: ALL", value: "all" }]}
							/>
						</div>

						<IconInput
							containerClass="half_width_desktop"
							placeholder="Search by code, user name, rider name or address"
							withIcon
							icon={<BiSearchAlt2 width="20px" height="20px" fill="#8B8B8B" />}
							value={searchValue}
							onChange={(e) => {
								setSearchValue(e);
								setSearchChanged(true);
							}}
							disabled={false}
						/>
					</div>

					{/* Table start */}
					<section className="bottom_left_section_alt width_full">
						<div className="border_bottom_thin flex_row_between">
							<p className="bold_21px mb_15">Complete trip history</p>
						</div>

						{!searchValue ? null : searchResult && !searchLoading ? (
							<div className="border_bottom_thin flex_row_between py_15">
								<p className="bold_18_px">
									Search result for "{searchValue}" ({searchResult?.length})
								</p>
							</div>
						) : null}

						{/* Search result  start*/}
						{!searchValue ? null : searchResult || searchLoading ? (
							<div
								style={{
									maxHeight: "600px",
									overflow: "scroll",
								}}
								className="width_full bottom_left_section"
							>
								<table className="width_full scroll-container overflow-scroll">
									<tbody>
										{searchResult &&
											!searchLoading &&
											searchResult?.length === 0 && (
												<p className="gray_label bold_21px uppercase mt_25 pb_15">
													{"No result for " + searchValue}
												</p>
											)}
										{searchLoading && (
											<tr>
												<td>
													<div className="flex_column_left">
														<Loader
															type="ThreeDots"
															color="#00aa34"
															height={10}
															width={50}
														/>
													</div>
												</td>
												<td>
													<div className="flex_column_center">
														<Loader
															type="ThreeDots"
															color="#00aa34"
															height={10}
															width={50}
														/>
													</div>
												</td>

												<td>
													<div className="flex_column_left">
														<Loader
															type="ThreeDots"
															color="#00aa34"
															height={10}
															width={50}
														/>
													</div>
												</td>

												<td>
													<div className="flex_column_right">
														<Loader
															type="ThreeDots"
															color="#00aa34"
															height={10}
															width={50}
														/>
													</div>
												</td>
											</tr>
										)}
										{searchResult
											?.sort(
												(a, b) =>
													new Date(b.completed_time) -
													new Date(a.completed_time)
											)

											.map((item, i) => {
												return (
													<>
														{moment(item.completed_time).format("LLL") ===
															todayStart && (
															<div className="border_bottom_thin flex_row_between py_15">
																<p className="bold_18_px">Today</p>
															</div>
														)}
														{moment(item.completed_time).format("LLL") ===
															yesterdayStart && (
															<div className="border_bottom_thin flex_row_between py_15">
																<p className="bold_18_px">Yesterday</p>
															</div>
														)}
														<tr key={i + "item"}>
															<td>
																<div className="flex_column_left">
																	{i + 1}
																	<label className="gray_label mb_4">
																		{item &&
																			item.completed_time &&
																			moment(item.completed_time).format("LT")}
																	</label>
																	<label className="gray_label mb_4">
																		{item &&
																			item.completed_time &&
																			moment(item.completed_time).format(
																				"DD MMM YYYY"
																			)}
																	</label>
																	<label className="gray_label">
																		{" "}
																		#{item && item.order_code}
																	</label>
																</div>
															</td>
															<td>
																<div className="flex_column_left">
																	<div className="flex_row_align_center mb_10">
																		<span className="normal_53px green_text mr_10">
																			•
																		</span>
																		<p className="normal_15px">
																			From:{" "}
																			<span className="bold">CoKitchen</span>
																		</p>
																	</div>

																	<div className="flex_row_align_center">
																		<span className="normal_53px red_text mr_10">
																			•
																		</span>
																		<p className="normal_15px">
																			To:{" "}
																			<span className="bold">
																				{item &&
																					item.calculated_order &&
																					item.calculated_order
																						.address_details &&
																					item.calculated_order.address_details
																						.address_line}
																			</span>
																		</p>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_left">
																	<div className="flex_row_center mb_17">
																		<Clock
																			fill="#939393"
																			width="8px"
																			height="8px"
																			className="mr_15"
																		/>

																		<p className="normal_15px bold green_text">
																			Completed
																		</p>
																	</div>

																	<div className="flex_row_align_center">
																		<HiOutlineOfficeBuilding
																			style={{
																				stroke: "#939393",
																			}}
																			width="9px"
																			height="9px"
																			className="mr_15"
																		/>

																		<p className="normal_15px">
																			{
																				homeStore?.logistics?.logistics_companies?.find(
																					(itm) =>
																						itm?.id ===
																						item?.rider?.logistics_company_id
																				)?.name
																			}
																		</p>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_left">
																	<div className="flex_row_center mb_17">
																		<RiEBikeFill
																			color="#939393"
																			size="14px"
																			className="mr_15"
																		/>

																		<p className="normal_15px">
																			{" "}
																			{item &&
																				item.rider &&
																				item.rider.first_name +
																					" " +
																					item.rider.last_name +
																					" (Rider)"}
																		</p>
																	</div>

																	<div className="flex_row_center mb_17">
																		<FaUser
																			color="#939393"
																			size="12px"
																			className="mr_15"
																		/>

																		<p className="normal_15px">
																			{" "}
																			{item?.user?.first_name +
																				" " +
																				item?.user?.last_name}
																		</p>
																	</div>

																	<div className="flex_row_align_center">
																		<Stars fill="#939393" width="9px" />

																		<p className="normal_15px">No comment</p>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_right">
																	<span className="bold_21px green_text mb_17">
																		₦
																		{item?.calculated_order?.delivery_fee &&
																			numberWithCommas(
																				parseInt(
																					item.calculated_order.delivery_fee
																				)
																			)}
																	</span>

																	<div className="flex_row_right">
																		<Link
																			to={`full-trip-details/live/${
																				item && item.order_code
																			}`}
																			onClick={() => {
																				homeStore.updateAllTrips(list);
																				homeStore.updateTripPage(page);
																			}}
																		>
																			<p className="mr_10 normal_15px black">
																				See full trip details
																			</p>
																		</Link>
																		<Link
																			to={`full-trip-details/live/${
																				item && item.order_code
																			}`}
																		>
																			<ChevronRight
																				fill="#000000"
																				width="11px"
																				height="11px"
																			/>
																		</Link>
																	</div>
																</div>
															</td>
														</tr>
													</>
												);
											})}
									</tbody>
								</table>
							</div>
						) : null}

						{/* Search result end */}
						<div className="border_bottom_thin flex_row_between py_15">
							<p className="bold_18_px">Trips shown ({list?.length})</p>
						</div>
						<div
							style={{
								maxHeight: "700px",
								minHeight: "700px",
								overflow: "scroll",
								position: "relative",
							}}
							className="width_full bottom_left_section"
						>
							<table
								className="width_full scroll-container overflow-scroll "
								style={{
									maxHeight: "700px",
									minHeight: "700px",
									overflow: "scroll",
								}}
							>
								<tbody>
									<div ref={orderRef} />
									{list
										.sort(
											(a, b) =>
												new Date(b.completed_time) - new Date(a.completed_time)
										)

										.map((item, i) => {
											const isGroupOrder = item?.calculated_order
												?.is_group_order
												? true
												: false;

											return (
												<>
													{moment(item.completed_time).format("LLL") ===
														todayStart && (
														<div className="border_bottom_thin flex_row_between py_15">
															<p className="bold_18_px">Today</p>
														</div>
													)}
													{moment(item.completed_time).format("LLL") ===
														yesterdayStart && (
														<div className="border_bottom_thin flex_row_between py_15">
															<p className="bold_18_px">Yesterday</p>
														</div>
													)}
													<tr key={i + "item"} className="position-relative">
														<td>
															<div className="flex_column_left">
																{i + 1}
																<label className="gray_label mb_4">
																	{item &&
																		item.completed_time &&
																		moment(item.completed_time).format("LT")}
																</label>
																<label className="gray_label mb_4">
																	{item &&
																		item.completed_time &&
																		moment(item.completed_time).format(
																			"DD MMM YYYY"
																		)}
																</label>
																<Link
																	to={`search/order/${item?.id}`}
																	className="bold_21px gray_label uppercase"
																>
																	{" "}
																	#{item && item.order_code}
																</Link>
															</div>
														</td>
														<td>
															<div className="flex_column_left">
																<div className="flex_row_align_center mb_10">
																	<span className="normal_53px green_text mr_10">
																		•
																	</span>
																	<p className="normal_15px">
																		From:{" "}
																		<span className="bold">CoKitchen</span>
																	</p>
																</div>

																<div className="flex_row_align_center">
																	<span className="normal_53px red_text mr_10">
																		•
																	</span>
																	<p className="normal_15px">
																		To:{" "}
																		<span className="bold">
																			{item &&
																				item.calculated_order &&
																				item.calculated_order.address_details &&
																				item.calculated_order.address_details
																					.address_line}
																		</span>
																	</p>
																</div>
															</div>
														</td>

														<td>
															<div className="flex_column_left">
																<div className="flex_row_center mb_17">
																	<Clock
																		fill="#939393"
																		width="8px"
																		height="8px"
																		className="mr_15"
																	/>

																	<p className="normal_15px bold green_text">
																		Completed
																	</p>
																</div>

																<div className="flex_row_align_center mb_17">
																	<HiOutlineOfficeBuilding
																		style={{
																			stroke: "#939393",
																		}}
																		width="9px"
																		height="9px"
																		className="mr_15"
																	/>

																	<p className="normal_15px">
																		{homeStore?.logistics?.logistics_companies?.find(
																			(itm) =>
																				itm?.id ===
																				item?.rider?.logistics_company_id
																		)?.name || "PICKUP"}
																	</p>
																</div>

																<div className="flex_row_align_center mb_17">
																	{isGroupOrder ? (
																		<TiGroup
																			color="#d50000"
																			size="14px"
																			className="mr-1"
																		/>
																	) : (
																		<FaUserAlt
																			color="#d50000"
																			size="11px"
																			className="mr-1"
																		/>
																	)}

																	<p className="normal_15px">
																		{isGroupOrder ? "Group" : "Single"} Order
																	</p>
																</div>
															</div>
														</td>

														<td>
															<div className="flex_column_left">
																<div className="flex_row_center mb_17">
																	<RiEBikeFill
																		color="#939393"
																		size="14px"
																		className="mr_15"
																	/>

																	<Link
																		to={`search/profile/${item?.rider?.id}`}
																		className="normal_15px"
																	>
																		{" "}
																		{item &&
																			item.rider &&
																			item.rider.first_name +
																				" " +
																				item.rider.last_name +
																				" (Rider)"}
																	</Link>
																</div>

																<div className="flex_row_center mb_17">
																	<FaUser
																		color="#939393"
																		size="12px"
																		className="mr_15"
																	/>

																	<Link
																		to={`search/profile/${item?.user_id}`}
																		className="normal_15px"
																	>
																		{" "}
																		{item?.user?.first_name +
																			" " +
																			item?.user?.last_name}
																	</Link>
																</div>

																<div className="flex_row_align_center">
																	<Stars fill="#939393" width="9px" />

																	<p className="normal_15px">No comment</p>
																</div>
															</div>
														</td>

														<td>
															<div className="flex_column_right">
																<span className="bold_21px green_text mb_17">
																	₦
																	{item?.calculated_order?.delivery_fee &&
																		numberWithCommas(
																			parseInt(
																				item.calculated_order.delivery_fee
																			)
																		)}
																</span>

																<div className="flex_row_right">
																	<Link
																		to={`full-trip-details/live/${
																			item && item.order_code
																		}`}
																		onClick={() => {
																			homeStore.updateAllTrips(list);
																			homeStore.updateTripPage(page + 1);
																		}}
																	>
																		<p className="mr_10 normal_15px black">
																			See full trip details
																		</p>
																	</Link>
																	<Link
																		to={`full-trip-details/live/${
																			item && item.order_code
																		}`}
																	>
																		<ChevronRight
																			fill="#000000"
																			width="11px"
																			height="11px"
																		/>
																	</Link>
																</div>
															</div>
														</td>
													</tr>
												</>
											);
										})}
									{isLoading && (
										<tr>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_center">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
							<button
								className="bg-light-gray rounded-circle d-justify-center d-align-center"
								style={{
									width: 50,
									height: 50,
									position: "fixed",
									right: 50,
									bottom: 50,
								}}
								onClick={() => scrollToElement()}
							>
								<FiChevronUp
									fill="#000"
									width="22px"
									height="22px"
									className=""
									size={20}
								/>
							</button>
							<div
								ref={loader}
								style={{ width: "100%", height: 50 }}
								className="d-justify-center d-align-center"
							>
								<FiChevronsDown
									fill="#C4C4C4"
									width="12px"
									height="12px"
									className=""
								/>
							</div>
						</div>

						<div style={{ height: "140px" }} />
					</section>
					{/* Table end */}
				</section>
			</section>
		</Layout>
	);
};

export default withAlert()(LiveHistoryFeedBack);

// Trip history search function
// .filter(
//   item =>
//     (item?.rider?.first_name + ' ' + item?.rider?.last_name)
//       ?.toUpperCase()
//       .includes(searchValue.toUpperCase()) ||
//     item?.order_code
//       ?.toUpperCase()
//       .includes(searchValue.toUpperCase()) ||
//     (item?.user?.first_name + ' ' + item?.user?.last_name)
//       ?.toUpperCase()
//       .includes(searchValue.toUpperCase()) ||
//     item?.calculated_order?.address_details?.address_line
//       ?.toUpperCase()
//       .includes(searchValue.toUpperCase())
// )
