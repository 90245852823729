import React, { useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi";
import "./styles.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
	//   Dashboard
	HOME_S_ADMIN,
	LIVE_TRIPS,
	NOTIFICATIONS_S_ADMIN,
	PARTNER_DB_S_ADMIN,
	SEARCH,
	USERS,
} from "../../router/router";
import Cookies from "js-cookie";
const Tabs = ({
	home,
	details,
	notifications,
	location_set,
	users,
	search,
	searchLink,
}) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const [url, setUrl] = useState(SEARCH);
	let path = localStorage.getItem("path");

	useEffect(() => {
		if (path !== null) {
			setUrl(localStorage.getItem("path"));
		} else {
			setUrl(SEARCH);
		}
	}, [path]);

	useEffect(() => {
		if (pathname.includes("search")) {
			localStorage.setItem("path", pathname);
		}
	}, [pathname]);

	let searchtab = !url ? SEARCH : url === "/search" ? SEARCH : url;

	return (
		<div className="tabs_container  content_section">
			<ul className="flex_row_align_center">
				<li className={`nav_item ${details}`}>
					<Link to={LIVE_TRIPS}>
						<div className="normal_18px ">
							Live on-going trips, history & feedback
						</div>
					</Link>
				</li>
				<li className={`nav_item ${home}`}>
					<Link to={HOME_S_ADMIN}>
						<div className="normal_18px ">Dashboard & Pending Trips</div>
					</Link>
				</li>

				{/*	{(Cookies.get("token") && Cookies.get("role") === "CUSTOMER_SERVICE") ||
				(Cookies.get("token") && Cookies.get("role") === "SUPER_ADMIN") ? (
					<li className={`nav_item ${users}`}>
						<Link to={USERS}>
							<div className="normal_18px ">Users</div>
						</Link>
					</li>
				) : null} <li className={`nav_item ${notifications}`}>
          <Link to={NOTIFICATIONS_S_ADMIN}>
            <div className="normal_18px ">Notifications & alerts!</div>
          </Link>
        </li> */}
				<li className={`nav_item ${search}`}>
					<Link to={searchtab}>
						<div className="normal_18px">Search</div>
					</Link>
				</li>
				{Cookies.get("token") && Cookies.get("role") === "SUPER_ADMIN" ? (
					<li className={`nav_item ${notifications}`}>
						<Link to={NOTIFICATIONS_S_ADMIN}>
							<div className="normal_18px ">Settings</div>
						</Link>
					</li>
				) : null}

				<li className={`nav_add ${location_set}`}>
					<Link to={PARTNER_DB_S_ADMIN}>
						<div className="flex_row_right cursor_pointer">
							<label className="flex_row_center btn_span">
								<HiPlus fill="#B9B9B9" width="14px" height="14px" />
							</label>
							<label className="normal_18px ">Partner database</label>
						</div>
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default Tabs;
