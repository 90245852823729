import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import Button from "../../../components/buttons";
import IconInput from "../../../components/inputs/iconInput";
import Modal from "../../../components/modals";
import SearchModal from "../../../components/modals/SearchModal";
import SearchButton from "../../../components/buttons/SearchButton";
import { AiOutlinePlus } from "react-icons/ai";

const AddFunds = ({ userEmail, onCredit, creditWallet, onClick, id }) => {
	const [form, setForm] = useState({
		email: "",
		amount: "",
		reason: "",
	});
	const [showModal, setShowModal] = useState(false);
	useEffect(() => {
		setForm({ ...form, email: userEmail });
	}, []);

	const handleChange = (prop, val) => {
		setForm({ ...form, [prop]: val?.value || val });
	};

	const onCreditWallet = async () => {
		await creditWallet(form, id);
		setForm({
			email: "",
			amount: "",
			reason: "",
		});
		setShowModal(!showModal);
	};

	// function onCreditWallet() {
	// 	onCredit(form);
	// 	setForm({
	// 		email: "",
	// 		amount: "",
	// 		reason: "",
	// 	});
	// 	setShowModal(!showModal);
	// }

	return (
		<div>
			<AiOutlinePlus size={20} color="#00BA0E" onClick={onClick} />{" "}
		</div>
	);
};
AddFunds.propTypes = {
	onCreditWallet: PropTypes.func,
};

export default AddFunds;
