import axios from "axios";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const LOGIN_URL = "internal/auth/login-logistics-admin";
const SIGNUP_URL = "internal/auth/register-logistics-admin";
const SIGNUP_SA_URL = "internal/auth/register-logistics-super-admin";
class AuthService {
	/***********************************Login********************* */
	login(data) {
		return axios
			.post(API_URL + LOGIN_URL, data, {
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"x-api-key": API_KEY,
				},
			})
			.then((response) => {
				if (response.data) {
					let res = response.data;
					Cookies.set("token", res.token);
					Cookies.set("id", res.id);
					Cookies.set("email", res.email);
					Cookies.set("first_name", res.first_name);
					Cookies.set("last_name", res.last_name);
					Cookies.set("role", res.role);
					Cookies.set("logistics_company_id", res.logistics_company_id);
				}

				return response;
			});
	}

	/***********************************Signup********************* */
	signup(data) {
		return axios
			.post(API_URL + SIGNUP_URL, data, {
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"x-api-key": API_KEY,
				},
			})
			.then((response) => {
				if (response.data.access_token) {
					localStorage.setItem("user", JSON.stringify(response.data));
				} else {
					// console.log(response);
				}

				return response;
			});
	}

	/***********************************Signup Super admin********************* */
	signupSuperadmin(data) {
		return axios
			.post(API_URL + SIGNUP_SA_URL, data, {
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"x-api-key": API_KEY,
				},
			})
			.then((response) => {
				if (response.data.access_token) {
					// console.log(typeof response.data.access_token);
					localStorage.setItem("user", JSON.stringify(response.data));
				} else {
					// console.log(response);
				}

				return response;
			});
	}
}

export default withRouter(new AuthService());
