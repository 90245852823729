import React, { Fragment, useState } from "react";
import OrderDetails from "../../superAdminScreens/search/userprofile/orderDetails";

const OrderCard = ({
	amount,
	name,
	startTime,
	endTime,
	className,
	to,
	onClick,
	userId,
	riderName,
	deliveryFee,
	date,
	ongoing,
	orderCode,
	order,
	startingTime,
	setUserPage,
	setOrderPage,
	role,
	rider,
}) => {
	return (
		<div
			className={`bg-white-100 rounded-2xl border-[2px] border-grey-thin py-4 px-8 relative w-full ${className}`}
			onClick={onClick}
		>
			<div className={` pt-2 flex flex-col gap-6`}>
				<div className="flex gap-3 flex-wrap justify-between">
					<div className="space-y-3">
						<div>
							<span className=" flex gap-2 space-x-2 ">
								Order Code:{" "}
								<p
									className=" text-peach-100 font-semibold hover:underline hover:font-bold cursor-pointer uppercase"
									onClick={() => setOrderPage()}
								>{`#${orderCode}`}</p>{" "}
							</span>
						</div>

						<div>
							<span className=" flex gap-2 space-x-2 ">
								Amount:
								<p className=" text-peach-100 font-semibold ">{amount}</p>{" "}
							</span>
						</div>
					</div>

					<div className="space-y-3">
						<div>
							<span className=" flex gap-2 space-x-2 ">
								Delivery Fee:
								<p className=" text-peach-100 font-semibold ">
									{`${deliveryFee.toLocaleString(undefined, {
										maximumFractionDigits: 2,
									})}`}
								</p>{" "}
							</span>
						</div>

						<div>
							<span className=" flex gap-2 space-x-2 ">
								{role === rider ? "User:" : "Rider:"}
								<p
									className=" text-peach-100 font-semibold hover:underline hover:font-bold cursor-pointer "
									onClick={() => setUserPage()}
								>
									{riderName}
								</p>{" "}
							</span>
						</div>
					</div>

					<div className="space-y-3">
						<div>
							<span className=" flex gap-2 space-x-2 ">
								Start Time:
								<p className=" text-peach-100 font-semibold ">
									{startingTime}
								</p>{" "}
							</span>
						</div>

						<div>
							<span className=" flex gap-2 space-x-2 ">
								Completed Time:
								<p className=" text-peach-100 font-semibold ">{endTime}</p>{" "}
							</span>
						</div>
					</div>
				</div>

				<div className="flex justify-between items-end flex-wrap">
					<div>
						<span className=" space-x-2 ">
							Time Added:
							<p className=" ">{startTime}</p>{" "}
						</span>
					</div>

					<div className="flex gap-2 items-center">
						<p
							className={` ${
								ongoing ? "bg-green-400" : "bg-red-400"
							}  w-2 h-2 rounded-full`}
						></p>
						<span className="font-bold text-sm"> {to} </span>
					</div>

					<OrderDetails order={order} />
				</div>
			</div>
		</div>
	);
};

export default OrderCard;
