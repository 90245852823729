import React from "react";
import { useEffect, useState } from "react";

import moment from "moment";
import Modal from "../../../components/modals";
import SearchModal from "../../../components/modals/SearchModal";

const OrderDetails = ({ order, onCancel, className }) => {
	const [showModal, setShowModal] = useState(false);
	const [orderLogs, setOrderLogs] = useState(false);
	const [user, setUser] = useState(false);
	const [address, setAddress] = useState(false);
	const [string, setString] = useState(false);

	function onTimeClick() {
		setShowModal(!showModal);
	}

	useEffect(() => {
		let orderLogs = order.logs;
		let address = order?.calculated_order?.address_details?.address_line;
		orderLogsHandler(orderLogs, address);
	}, []);
	const orderLogsHandler = async (logs, address) => {
		const msToTime = (duration) => {
			var milliseconds = Math.floor((duration % 1000) / 100),
				seconds = Math.floor((duration / 1000) % 60),
				minutes = Math.floor((duration / (1000 * 60)) % 60),
				hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

			hours = hours < 10 ? "0" + hours : hours;
			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;
			return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
		};
		let string = "";
		let prev_time;
		for (let i = 0; i < logs?.length; i++) {
			let duration = "";
			if (logs[i + 1]) {
				prev_time = logs[i + 1].time;
			}

			if (prev_time) {
				let now = moment(logs[i].time);
				let end = moment(prev_time);
				let diff = moment.duration(end.diff(now));
				if (diff.asMinutes() > 0) {
					if (diff.asMinutes() < 1) {
						diff = diff.asSeconds();
						let whole_array = (diff + "").split(".");
						let seconds = whole_array[0];
						let milliseconds = whole_array[1];
						duration = `${seconds} s ${milliseconds} ms`;
					} else if (diff.asMinutes() < 60) {
						diff = diff.asMinutes();
						let whole_array = (diff + "").split(".");
						let seconds = whole_array[0];
						let milliseconds = whole_array[1];
						duration = `${seconds} mins`;
					} else {
						diff = msToTime(diff);
						duration = `${diff}`;
					}
				} else {
					let now = moment(logs[i].time);
					let end = moment(logs[0].time);
					let diff = moment.duration(now.diff(end));
					duration = "ORDER DURATION: " + msToTime(diff);
				}
			}
			string +=
				logs[i].description +
				" <br><b>Time:</b> " +
				moment(logs[i].time).add(1, "hours").format("hh:mm:ssa YYYY-MM-DD") +
				"<br><br>  " +
				`<b>${duration}</b>` +
				"<br><br>  ";
		}
		string += `<b>${"ADDRESS - "} ${address}</b><br>`;
		//   string += `<br><b>${user.first_name} ${user.last_name},  ${user.phone_number}</b><br>`
		setString(string);
		return string;
	};
	return (
		<div>
			<span
				className={`text-blue-900 text-sm cursor-pointer hover:underline font-bold ${className}`}
				onClick={() => onTimeClick()}
			>
				Timeline
			</span>
			<Modal
				showModal={showModal}
				closeModal={() => setShowModal(!showModal)}
				title="History"
				content={
					<div>
						<div dangerouslySetInnerHTML={{ __html: string }}></div>
					</div>
				}
			/>
		</div>
	);
};
OrderDetails.propTypes = {};

export default OrderDetails;
