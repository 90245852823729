import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import {
	COKITCHEN_DELIVERY_SURGE_URL,
	CREATE_NOTIFICATION,
	GET_COKITCHENS_URL,
	GET_ALL_ORDERS_URL,
	UPDATE_BLACKLIST_SETTING,
	GET_BLACKLIST_SETTING
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
import moment from "moment";
export class CommonStore {
	@observable cokitchens = [];
	@observable dataCached = false;
	@observable orders = [];
	@observable ordersCached = false;
	@observable ordersDateRange = "";
	@observable dateRange = "";
	@observable blacklistSetting="";
	getCokitchens = async (alert, shouldAlert) => {
		try {
			let response = await dataService.getInternalData(GET_COKITCHENS_URL);

			runInAction(() => {
				let res = response.data && response.data.cokitchens;

				let i;
				let options = [];

				for (i = 0; i < res.length; i++) {
					options.push({ ...res[i], label: res[i].name, value: res[i].id });
				}
				this.cokitchens = options;
				shouldAlert &&
					alert.show("Cokitchens loaded successfully!", {
						type: "success",
					});

				this.dataCached = true;
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};

	getAllOrders = async (alert, shouldAlert,date) => {
		try {
			let response = await dataService.postAuthData(GET_ALL_ORDERS_URL, date);

			runInAction(() => {
				let res = response
				let resCopy = [];
			
					shouldAlert &&
					alert.show("Csv currently sending!", {
						type: "success",
					});
				
				
			});
		} catch (error) {
			errorHandler(error, alert);
			
		}
	};
	getBlacklistSettings = async (alert, shouldAlert) => {
		try {
			let response = await dataService.getAuthData(GET_BLACKLIST_SETTING);

			runInAction(() => {
				let res = response && response.data ;
				this.blacklistSetting= res && res
	
			});
		} catch (error) {
			//errorHandler(error, alert);
		}
	};

	postNotification = async (alert, data) => {
		try {
			let response = await dataService.postAuthData(CREATE_NOTIFICATION, data);
			let res;
			runInAction(() => {
				res = response && response.data;

				if (res) {
					alert.show("Notification sent successfully!", {
						type: "success",
					});

					this.dataCached = true;
				}
			});
			return res;
		} catch (error) {
			errorHandler(error, alert);
		}
	};

	postDeliveryValues = async (alert, data, message) => {
		try {
			let response = await dataService.postAuthData(
				COKITCHEN_DELIVERY_SURGE_URL,
				data
			);

			runInAction(() => {
				if (response && response.data) {
					alert.show(message, {
						type: "success",
					});
					this.dataCached = true;
					return response.data;
				}
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};

	blacklistRiders = async (alert, data, message) => {
		try {
			let response = await dataService.postAuthData(
				`logistics/rider/blacklist/${data}`
			);

			runInAction(() => {
				if (response && response.data) {
					alert.show(message, {
						type: "success",
					});
					this.dataCached = true;
					return response.data;
				}
			});
		} catch (error) {
			//errorHandler(error, alert);
		}
	};
	unblacklistRiders = async (alert, data, message) => {
		try {
			let response = await dataService.postAuthData(
				`logistics/rider/unblacklist/${data}`
			);

			runInAction(() => {
				if (response && response.data) {
					alert.show(message, {
						type: "success",
					});
					this.dataCached = true;
					return response.data;
				}
			});
		} catch (error) {
			//errorHandler(error, alert);
		}
	};
	updateBlacklistSettings = async (alert, data, message) => {
		try {
			let response = await dataService.patchAuthData(
				UPDATE_BLACKLIST_SETTING,
				data
			);
	
			runInAction(() => {
				if (response && response.data) {
					alert.show(message, {
						type: "success",
					});
					this.dataCached = true;
					return response.data;
				}
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};
	
}

export default new CommonStore();
