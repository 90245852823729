import { observable, runInAction, action } from 'mobx'
import dataService from '../../services/data.service'
import {
  GET_LOGISTICS_URL,
  CANCEL_ORDER_URL,
  PAYMENT_HISTORY_URL,
  REASSIGN_RIDER_URL,
  UNASSIGN_RIDER_URL,
  COMPLETE_ORDER_URL,
  GET_USERS_URL,
  ADD_TO_WALLET_URL,
  SUBTRACT_FROM_WALLET_URL,
  GET_BLOCKED_USERS_URL,
  BLOCK_USER_URL,
  UNBLOCK_USER_URL,
  UPDATE_ORDER_AMOUNT_URL,
  USER_OWE_URL,
  ASSIST_RIDER_URL,
  SWITCH_ORDER_URL,
  CREATE_LAST_ORDER,
  CREATE_PAYSTACK_ORDER_URL
  
} from '../../helpers/urls'
import errorHandler from '../../helpers/errorHandler'
import { LIVE_TRIPS } from '../../router/router'
import moment from 'moment'

export class HomeStore {
  @observable logistics = {}

  @observable ongoingTrips = []
  @observable dataCached = false
  @observable dataFBCached = false
  @observable users = []
  @observable todayUsers = []
  @observable yesterdayUsers = []
  @observable blockedUsers = []
  @observable blockedUsersMain = []
  @observable allTrips = []
  @observable tripPage = 1
  @observable dataUsersCached = false
  @action updateOngoingTrips = item => {
    this.ongoingTrips = item

    this.dataFBCached = true
  }

  @action updateAllTrips = item => {
    this.allTrips = item
  }

  @action updateTripPage = item => {
    this.tripPage = item
  }

  getLogistics = async (alert, shouldAlert) => {
    try {
      let response = await dataService.getAuthData(GET_LOGISTICS_URL + 30)
      let res
      runInAction(() => {
        res = response.data && response.data
        let moments = res && res.trips.map(x => moment(x.created_at))
        let maxDate = moment.max(moments).format('D MMM YY')
        let minDate = moment.min(moments).format('D MMM YY')
        res.max_date = maxDate
        res.min_date = minDate
        this.dataCached = true
        this.logistics = res
        shouldAlert &&
          alert.show('Logistics data loaded successfully!', {
            type: 'success'
          })
      })
      return res
    } catch (error) {
      let err = errorHandler(error, alert)
      return err
    }
  }

  cancelOrders = async (alert, data, history) => {
    try {
      let response = await dataService.postAuthData(CANCEL_ORDER_URL, data)
      let res
      runInAction(() => {
        res = response && response.data

        if (res) {
          alert.show('Order cancelled successfully!', {
            type: 'success'
          })

          this.dataCached = true

          history.push(LIVE_TRIPS)
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }

  completeOrders = async (alert, data, history) => {
    try {
      let response = await dataService.postAuthData(COMPLETE_ORDER_URL, data)
      let res
      runInAction(() => {
        res = response && response.data

        if (res) {
          alert.show('Order completed successfully!', {
            type: 'success'
          })

          history.push(LIVE_TRIPS)
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
      const errMsg =
        error && (error.response || error.message || error.toString() || error)
      let errorMsg = (errMsg && errMsg.data && errMsg.data.message) || errMsg
      console.log('Errror Message', errorMsg)
    }
  }

  reassignRider = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(REASSIGN_RIDER_URL, data)
      let res
      runInAction(() => {
        res = response && response.data

        if (res) {
          alert.show('Rider reassigned successfully!', {
            type: 'success'
          })
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }

  assistRider = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(ASSIST_RIDER_URL, data)
      let res
      runInAction(() => {
        res = response && response.data

        if (res) {
          alert.show('Assistant riders assigned successfully!', {
            type: 'success'
          })
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }
  switchOrder = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(SWITCH_ORDER_URL, data)
      let res
      runInAction(() => {
        res = response && response.data

        if (res) {
          alert.show('Order type switched successfully!', {
            type: 'success'
          })
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }
  unassignRider = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(UNASSIGN_RIDER_URL, data)
      let res
      runInAction(() => {
        res = response && response.data

        if (res) {
          alert.show('Rider reassigned successfully!', {
            type: 'success'
          })
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }

  addToWallet = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(ADD_TO_WALLET_URL, data)
      let res
      runInAction(() => {
        res = response && response.data
        if (res) {
          alert.show('User wallet updated successfully!', {
            type: 'success'
          })
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }

  subtractFromWallet = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(
        SUBTRACT_FROM_WALLET_URL,
        data
      )
      let res
      runInAction(() => {
        res = response && response.data
        if (res) {
          alert.show('User wallet updated successfully!', {
            type: 'success'
          })
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }

  updateUserDebt = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(USER_OWE_URL, data)
      let res
      runInAction(() => {
        res = response && response.data
        if (res) {
          alert.show('User debt updated successfully!', {
            type: 'success'
          })
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }
  createLastOrder = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(CREATE_LAST_ORDER, data)
      let res
      runInAction(() => {
        res = response && response.data
        if (res) {
          alert.show('Last order created successfully!', {
            type: 'success'
          })
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }

  getUsers = async (alert, show) => {
    const today = moment().format('DD-MM-YYYY')
    const yesterday = moment()
      .add(-1, 'days')
      .format('DD-MM-YYYY')
    try {
      let response = await dataService.getAuthData(GET_USERS_URL)

      runInAction(() => {
        let res = response.data && response.data.data
        let moments = res?.map(x => moment(x.created_at))
        let maxDate = moment.max(moments).format('MM/DD/YYYY')
        let minDate = moment.min(moments).format('MM/DD/YYYY')

        let diff = moment(new Date(maxDate)).diff(
          moment(new Date(minDate)),
          'days'
        )
        this.dateRangeReg = diff
        this.usersDateRange = {
          startDate: new Date(minDate),
          endDate: new Date(maxDate),
          key: 'selection'
        }
        let usersCopy = []
        let blockedUsersCopy = []
        let todTrips = []
        let yesTrips = []
        for (let i = 0; i < res.length; i++) {
          if (this.blockedUsers[0]) {
            if (this.blockedUsers.includes(res[i].id)) {
              usersCopy.push({ ...res[i], blocked: true })
              blockedUsersCopy.push({ ...res[i], blocked: true })
            } else {
              usersCopy.push({ ...res[i], blocked: false })
            }
          }
          if (moment(res[i].created_at).format('DD-MM-YYYY') === today) {
            todTrips.push(res[i])
          } else if (
            moment(res[i].created_at).format('DD-MM-YYYY') === yesterday
          ) {
            yesTrips.push(res[i])
          }
        }
        this.users = usersCopy
        this.blockedUsersMain = blockedUsersCopy
        this.todayUsers = todTrips
        this.yesterdayUsers = yesTrips
        show &&
          alert.show('users loaded successfully!', {
            type: 'success'
          })

        this.dataUsersCached = true
      })
    } catch (error) {
      errorHandler(error, alert)
    }
  }
  getBlockedUsers = async (alert, show) => {
    try {
      let response = await dataService.getAuthData(GET_BLOCKED_USERS_URL)

      runInAction(() => {
        let res = response.data && response.data.data
        let moments = res?.map(x => moment(x.created_at))
        let maxDate = moment.max(moments).format('MM/DD/YYYY')
        let minDate = moment.min(moments).format('MM/DD/YYYY')

        let diff = moment(new Date(maxDate)).diff(
          moment(new Date(minDate)),
          'days'
        )
        this.dateRangeReg = diff
        this.usersDateRange = {
          startDate: new Date(minDate),
          endDate: new Date(maxDate),
          key: 'selection'
        }
        this.blockedUsers = res

        this.dataUsersCached = true
      })
    } catch (error) {
      errorHandler(error, alert)
    }
  }

  blockUser = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(BLOCK_USER_URL, data)
      let res
      runInAction(() => {
        res = response && response.data
        if (res) {
          alert.show('User blocked successfully!', {
            type: 'success'
          })
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }
  unBlockUser = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(UNBLOCK_USER_URL, data)
      let res
      runInAction(() => {
        res = response && response.data
        if (res) {
          alert.show('User unblocked successfully!', {
            type: 'success'
          })
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }

  updateOrderAmount = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(
        UPDATE_ORDER_AMOUNT_URL,
        data
      )
      let res
      runInAction(() => {
        res = response && response.data
        if (res) {
          alert.show('Order amount updated successfully!', {
            type: 'success'
          })
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }

  createUserOrderFromPaystack = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(
        CREATE_PAYSTACK_ORDER_URL,
        data
      )
      let res
      runInAction(() => {
        res = response && response.data
        if (res) {
          alert.show('User order created successfully!', {
            type: 'success'
          })
        }
      })
      return res
    } catch (error) {
      errorHandler(error, alert)
    }
  }
}
export default new HomeStore()
