import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import { ThreeDots } from "react-loader-spinner/dist/loader/ThreeDots";
import UserPendingTasks from "./UserPendingTasks";
import UserProfileAnalytics from "./UserProfileAnalytics";
import UserProfileHeader from "./UserProfileHeader";

import SearchStore from "../../../stores/superAdminStores/searchStore";
import { BiArrowBack } from "react-icons/bi";
import Layout from "../../../components/layout";
import { withAlert } from "react-alert";
import { SEARCH } from "../../../router/router";

const UserProfile = observer(({ alert, ...props }) => {
	const { id } = useParams();
	const history = useHistory();
	const {
		userData,
		getUserById,
		userOrders,
		userProfileLoading,
		getTopOrderedPolygon,
		getRidersTopOrderedPolygon,
		polygonLoading,
		topOrderedPolygon,
	} = SearchStore;

	useEffect(() => {
		handleGetUserById();
	}, []);
	let rider = "RIDER";
	let role = userData?.role;

	useEffect(() => {
		getTopOrderedPolygons();
	}, [role]);
	const handleGetUserById = async () => {
		await getUserById(alert, id, { user_id: id });
	};

	const getTopOrderedPolygons = async () => {
		if (role === rider) {
			await getRidersTopOrderedPolygon(alert, id);
		} else {
			await getTopOrderedPolygon(alert, id);
		}
	};

	function goBack() {
		const type = localStorage.getItem("type");
		const query = localStorage.getItem("query");
		const value = localStorage.getItem("value");
		const date = localStorage.getItem("date");

		if (type === "date") {
			history.push(`/search/users/query=${query}/${value}/${date} `);
		} else {
			history.push(`/search/users/query=${query}/${value}`);
		}
	}
	return (
		<Layout
			// locationChange={setLocation}
			search="active_nav_item"
			withHistoryBtn
			title={<h6 className="bold_24px mr_30">SearchLayout</h6>}
		>
			<div className="p-2 pb-10 w-[90%] xs:w-[80%] mdLg:w-[95%] mx-auto space-y-10">
				<div className="flex items-center space-x-2">
					<BiArrowBack
						size={20}
						color="#FF0000"
						className="cursor-pointer"
						onClick={goBack}
					/>
					<span className="text-lg font-bold">User Profile</span>
				</div>
				<div>
					{userProfileLoading ? (
						<div>
							<ThreeDots color="#ff000060" height={10} width={50} />
						</div>
					) : (
						<div>
							<UserProfileHeader user={userData} id={id} alert={alert} />
							<UserProfileAnalytics
								user={userData}
								polygonLoading={polygonLoading}
								topOrderedPolygon={topOrderedPolygon}
								alert={alert}
							/>

							<UserPendingTasks
								userOrders={userOrders}
								user={userData}
								id={id}
							/>
						</div>
					)}
				</div>
			</div>
		</Layout>
	);
});

export default withAlert()(UserProfile);
