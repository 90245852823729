import React from "react";
import { useParams } from "react-router";
import Button from "../../../../components/buttons";
import SearchButton from "../../../../components/buttons/SearchButton";
import IconInput from "../../../../components/inputs/iconInput";
import Modal from "../../../../components/modals";

const UpdateTrip = ({
	updateTripModal,
	setUpdateTripModal,
	handleChange,
	form,
	updateOrderAmountLoading,
	updateOrderAmount,
	orderData,
	setForm,
	emptyForm,
	alert,
}) => {
	const { id } = useParams();

	const handleUpdateOrderAmount = async () => {
		await updateOrderAmount(
			alert,
			{
				order_code: orderData?.order_code,
				new_amount: form?.update_amount,
			},
			id
		);
		setForm({
			...emptyForm,
			amount: "",
		});
		setUpdateTripModal(!updateTripModal);
	};
	return (
		<Modal
			showModal={updateTripModal}
			closeModal={() => setUpdateTripModal(!updateTripModal)}
			title="Fill in the new order amount			"
			content={
				<div className="flex- flex-col space-y-6">
					<IconInput
						type="number"
						onChange={(val) => handleChange("update_amount", val)}
						placeholder=""
						name="update_amount"
						value={form.update_amount}
						inputClassName="pt-1 "
					/>
					<div className=" flex justify-between gap-2 flex-wrap">
						<SearchButton
							className=""
							isGreen
							text="Go Back"
							onClick={() => setUpdateTripModal(!updateTripModal)}
						/>
						<SearchButton
							className=""
							isBlack
							text="Update Amount"
							onClick={handleUpdateOrderAmount}
							isDisabled={!form?.update_amount}
							isLoading={updateOrderAmountLoading}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default UpdateTrip;
