import React, { useState } from "react";
import "./styles.scss";
import Header from "../../components/header";
import IconInput from "../../components/inputs/iconInput";
import { User, Key, ArrowRight } from "../../assets/icons/icons";
import { IconButton } from "../../components/buttons";
import { useHistory } from "react-router-dom";
import { withAlert } from "react-alert";
import AuthService from "../../services/auth.service";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
	HOME,
	HOME_S_ADMIN,
	LIVE_TRIPS,
	SIGNUP_SUPER_ADMIN,
} from "../../router/router";
const Login = ({ alert }) => {
	const history = useHistory();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isPassword, setIsPassword] = useState(true);
	const [loading, setLoading] = useState(false);

	const login = async (e) => {
		e.preventDefault();
		setLoading(true);
		await AuthService.login({ email, password })
			.then((res) => {
				let role = res && res.data && res.data.role;
				if (
					role === "LOGISTICS_SUPER_ADMIN" ||
					role === "CUSTOMER_SERVICE" ||
					role === "SUPER_ADMIN"
				) {
					history.push(LIVE_TRIPS);
					alert.show(
						`successfully logged in as ${
							role === "LOGISTICS_SUPER_ADMIN"
								? "logistics super-admin"
								: role === "SUPER_ADMIN"
								? "super-admin"
								: role === "CUSTOMER_SERVICE"
								? "customer service"
								: ""
						}`,

						{
							type: "success",
						}
					);
				} else if (role === "LOGISTICS_ADMIN") {
					history.push(HOME);
					alert.show(
						"successfully logged in as admin",

						{
							type: "success",
						}
					);
				}
			})
			.catch((error) => {
				const errMsg =
					error &&
					(error.response || error.message || error.toString() || error);
				alert.show(
					(errMsg && errMsg.data && errMsg.data.name) ||
						"An error has occured, try again!",

					{
						type: "error",
					}
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<main className="main_container">
			<div className="main_container_content">
				<Header noUser />

				<section className="login_back_section main_section">
					<h6 className="bold_24px mt_35 capitalize mb_30">
						Welcome to logistics dashboard
					</h6>
					<h6 className="bold_24px capitalize">Login to your account</h6>
					<form className="login_form second_width_desktop" onSubmit={login}>
						<IconInput
							required
							withLabel
							label="Email"
							placeholder="Enter email"
							value={email}
							onChange={(e) => setEmail(e)}
							icon={<User width="13.5px" height="13.5px" />}
							type="email"
							containerClass="mb_25"
						/>
						<IconInput
							required
							withLabel
							label="Password"
							placeholder="Enter Password"
							value={password}
							onChange={(e) => setPassword(e)}
							icon={<Key width="13.5px" height="13.5px" />}
							type={isPassword ? "password" : "text"}
							subIcon={
								isPassword ? (
									<AiFillEye
										width="16px"
										height="16px"
										onClick={() => setIsPassword(!isPassword)}
									/>
								) : (
									<AiFillEyeInvisible
										width="16px"
										height="16px"
										onClick={() => setIsPassword(!isPassword)}
									/>
								)
							}
						/>

						<IconButton
							withSubIcon
							subIcon={<ArrowRight width="13px" height="13px" fill="#2699FB" />}
							text="Login"
							className="mt_35 login_btn"
							isBlue
							loading={loading}
							onClick={login}
						/>
					</form>
					{/* <p className="normal_16px cursor_pointer mb_12">
            Don't have an account?{" "}
            <Link className="underlined black bold" to="/signup">
              Signup as a delivery partner
            </Link>
          </p> */}

					{/* <p className="normal_16px cursor_pointer mb_5">
            <Link className="underlined black bold" to={SIGNUP_SUPER_ADMIN}>
              Signup for a super-admin account
            </Link>
          </p> */}

					<p className="normal_16px underlined cursor_pointer bold">
						Forgot password?
					</p>
				</section>
			</div>
		</main>
	);
};
export default withAlert()(Login);
