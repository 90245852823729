import React from "react";
const OrderDetailsCard = ({ title, items, itemType }) => {
	return (
		<div className="bg-white-100 rounded-sm border-[1px] border-orange-200  w-fit p-2 flex flex-col gap-4">
			<div className=" flex justify-between gap-4 items-center ">
				<label className="font-bold text-sm mdLg:text-base">{title}</label>
				<label className=" text-2xs text-orange-600 font-bold">
					{itemType}!
				</label>
			</div>

			<div className="">
				{items.map((item, i) => {
					return (
						<div key={i + "items"} className="flex gap-8 items-start">
							<h6 className="text-orange-600 font-bold text-sm">
								{item && item.quantity + "×"}
							</h6>
							<div className="flex flex-col gap-2 text-sm">
								<p className="">{item && item.name}</p>
								{item &&
									item.addons &&

									item.addons.map((itm, ind) => {
										return (
											<label
												className="text-sm text-grey-backdrop flex items-center"
												key={ind + "itm"}
											>
												{itm && itm.meal_data && itm.meal_data.name}{" "}
												<span className="text-orange-600 font-bold text-xs ml-2">
													x {itm && itm.quantity}
												</span>
											</label>
										);
									})}

								{item?.order_note && <div className="mb_35">
									<span className=" font-bold">Order Note: {
										item?.order_note
									} </span>
								</div>}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
export default OrderDetailsCard;
