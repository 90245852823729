import React, { useState, useRef, useContext } from "react";
import { FiChevronDown } from "react-icons/fi";
import { Hearts } from "react-loader-spinner";

function CardAccordion({
	body,
	accClass,
	item,
	getItemsTotal,
	i,
	isReceipt,
	setCurrentOrder,
	setShowDetails,
}) {
	const [setActive, setActiveState] = useState("activeAccordion");
	const [setHeight, setHeightState] = useState(
		`$content.current.scrollHeight}px`
	);
	const [setRotate, setRotateState] = useState("accordion__icon rotate");

	const content = useRef(null);

	function toggleAccordion() {
		setActiveState(setActive === "" ? "activeAccordion" : "");
		setHeightState(
			setActive === "activeAccordion"
				? "0px"
				: `${content.current.scrollHeight}px`
		);
		setRotateState(
			setActive === "activeAccordion"
				? "accordion__icon"
				: "accordion__icon rotate"
		);
	}

	return (
		<div className={`accordion__section ${isReceipt ? "mb-4" : ""}`}>
			<div
				className={`${
					isReceipt ? "" : "py-2"
				} flex justify-between active-accordion-head`}
			>
				<p
					className="bold pointer"
					// onClick={() => {
					// 	setCurrentOrder(item);
					// 	setShowDetails(true);
					// }}
				>
					{i + 2}. {item?.user?.first_name + " " + item?.user?.last_name}{" "}
					{item?.first_order ? (
						<div className="flex items-center gap-1 uppercase font-bold">
							{" "}
							<Hearts width={60} height={25} color="#FF9636" />
							NEW!
						</div>
					) : null}
				</p>
				<p className={`${isReceipt ? "" : "bold"} text-left`}>
					{`   ${item?.meals?.length} ${
						item?.meals?.length === 1 ? "brand" : "brands"
					}`}
					,
					{` ${item?.meals && getItemsTotal()} ${
						item?.meals && getItemsTotal() === 1 ? "item" : "items"
					}`}
				</p>
				{!isReceipt ? (
					<button
						style={{
							background: "rgba(0, 0, 0, 0.1)",
							borderRadius: "50%",
							width: 28,
							height: 28,
						}}
						className={`d-flex d-justify-center d-align-center accordion ${setActive} ${setRotate} ${accClass}`}
						onClick={toggleAccordion}
					>
						<FiChevronDown size={30} />
					</button>
				) : null}
			</div>

			<div
				ref={content}
				style={{ maxHeight: `${setHeight}` }}
				className="accordion__content"
			>
				{body}
			</div>
		</div>
	);
}

export default CardAccordion;
