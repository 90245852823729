import React from "react";
import Logo from "../../assets/images/logo.png";
import "./styles.scss";
const Loader = ({ loading, className, style, logoStyle }) => {
  return loading ? (
    <div className={`loader flex_column_start ${className}`} style={style}>
      <img src={Logo} alt="loader" style={logoStyle} />
    </div>
  ) : null;
};
export default Loader;
