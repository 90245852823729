import React from "react";
import { IconButton } from "../buttons";
import "./styles.scss";

const Modal = ({
	smallModal,
	showModal,
	closeModal,
	title,
	onSubmit,
	content,
	saveText,
	submitting,
	btmBtns,
	saveTextTwo,
	submittingTwo,
	saveTextThree,
	submittingThree,
	onClickTwo,
	onClickThree,
}) => {
	return (
		(showModal && (
			<div className="flex_column_center modal p_20 ">
				<button className="cursor_pointer close_button" onClick={closeModal}>
					x
				</button>
				<div className="back_drop" onClick={closeModal} />
				<form
					className="flex_column_start half_width_desktop overflow-scroll"
					onSubmit={onSubmit}
				>
					<button
						className="cursor_pointer close_button"
						onClick={closeModal}
						type="button"
					>
						x
					</button>
					<h6 className="bold_24px mb_35">{title}</h6>
					{content}
					{saveText && (
						<IconButton
							text={saveText}
							className="mt_35"
							loading={submitting}
							type="submit"
						/>
					)}
					{btmBtns ? (
						<div className="flex-row d-justify-between d-align-center w-100">
							<IconButton
								text={saveTextTwo}
								className="mt_35"
								loading={submittingTwo}
								type="button"
								onClick={onClickTwo}
							/>
							{saveTextThree ? (
								<IconButton
									text={saveTextThree}
									className="mt_35"
									loading={submittingThree}
									type="button"
									onClick={onClickThree}
								/>
							) : null}
						</div>
					) : null}
				</form>
			</div>
		)) ||
		(smallModal && (
			<div className="flex_column_center small_modal p_20 ">
				<button className="cursor_pointer close_button" onClick={closeModal}>
					x
				</button>
				<div className="back_drop" onClick={closeModal} />
				<form
					className="flex_column_start half_width_desktop overflow-scroll"
					onSubmit={onSubmit}
				>
					<button
						className="cursor_pointer close_button"
						onClick={closeModal}
						type="button"
					>
						x
					</button>
					<h6 className="bold_24px mb_35">{title}</h6>
					{content}
					<IconButton
						text={saveText}
						className="mt_35"
						isLoading={submitting}
						type="submit"
					/>
					{btmBtns ? (
						<div className="flex-row d-justify-between d-align-center w-100">
							<IconButton
								text={saveTextTwo}
								className="mt_35"
								loading={submittingTwo}
								type="button"
								onClick={onClickTwo}
							/>
							{saveTextThree ? (
								<IconButton
									text={saveTextThree}
									className="mt_35"
									loading={submittingThree}
									type="button"
									onClick={onClickThree}
								/>
							) : null}
						</div>
					) : null}
				</form>
			</div>
		)) ||
		null
	);
};
export default Modal;
