import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { HOME, LIVE_TRIPS } from "./router";
const UnauthRoute = ({ component: Component, authenticated, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				!Cookies.get("token") ? (
					<Route render={() => <Component {...props} />} />
				) : (
					<Redirect
						to={
							(Cookies.get("role") === "LOGISTICS_ADMIN" && HOME) || LIVE_TRIPS
						}
					/>
				)
			}
		/>
	);
};
export default UnauthRoute;
