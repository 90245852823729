import React from "react";
import PropTypes from "prop-types";
import { BiLoaderAlt } from "react-icons/bi";
import "./styles.scss";
const SearchButton = ({
	type,
	isOutline,
	text,
	isDisabled,
	onClick,
	icon,
	isLoading,
	whiteBg,
	redBg,
	fullWidth,
	borderColor,
	textColor,
	textClass = "helv-medium",
	height,
	className,
	isYellow,
	isBlack,
	isGreen,
	isRed,
	isGray,
}) => {
	const btnClass = `
  flex justify-center items-center 
  ${
		height ? height : " h-[34px] md:h-[44px]"
	} border-[2.5px] rounded-lg  outline-none bani-base
   p-1 border-[2.5px] rounded-lg hover:opacity-70 font-base transition-all duration-150 font-semibold ease-in-out cursor-pointer 
  ${fullWidth ? "w-full" : ""}
  whitespace-nowrap ${
		isOutline
			? `bg-transparent ${
					isDisabled ? "bg-white/[.2]" : ""
			  } text-blue border-[2.5px] border-blue`
			: whiteBg
			? `bg-white ${isDisabled ? "bg-white/[.2]" : ""} text-${
					textColor || "grey-text"
			  } border-1/2 border-${borderColor || "grey-border"}`
			: redBg
			? `bg-red ${isDisabled ? "bg-red/[.2]" : ""} text-white`
			: isYellow
			? `border-yellow-100 ${isDisabled ? " bg-opacity-60 cursor-auto" : ""}`
			: isBlack
			? `border-black-100 ${isDisabled ? " bg-opacity-60 cursor-auto" : ""}`
			: isGray
			? `border-grey-borderGrey ${
					isDisabled ? " bg-opacity-60 hello cursor-auto" : ""
			  }`
			: isGreen
			? `border-green-100 ${isDisabled ? " bg-opacity-60 cursor-auto" : ""}`
			: isRed
			? `border-red-100 ${isDisabled ? " bg-opacity-60 cursor-auto" : ""}`
			: ` ${
					isDisabled
						? "bg-blue-600 opacity-60 cursor-auto"
						: "bg-blue-600 hover:bg-blue-hover text-white-100"
			  } text-white-100`
	}`;

	return (
		<button
			type={type ?? "button"}
			onClick={onClick}
			disabled={isDisabled || isLoading}
			className={`z-10 ${fullWidth ? "w-full" : ""} ${className || ""}`}
		>
			{isLoading ? (
				<div className={btnClass}>
					<BiLoaderAlt className="animate-spin" />
				</div>
			) : (
				<div className={btnClass}>
					{icon && <div className="mr-2">{icon}</div>}
					<span className={`${textClass ? textClass : "text-base"} `}>
						{text}
					</span>
				</div>
			)}
		</button>
	);
};

SearchButton.propTypes = {
	type: PropTypes.any,
	isOutline: PropTypes.bool,
	text: PropTypes.string,
	isDisabled: PropTypes.bool,
	onClick: PropTypes.func,
	isLoading: PropTypes.bool,
	whiteBg: PropTypes.bool,
	redBg: PropTypes.bool,
	fullWidth: PropTypes.bool,
	icon: PropTypes.element,
	borderColor: PropTypes.string,
	textColor: PropTypes.string,
	textClass: PropTypes.string,
	height: PropTypes.string,
	className: PropTypes.string,
	isGreen: PropTypes.bool,
};

export default SearchButton;
