import React, { useState } from "react";
import "./styles.scss";
import Header from "../../components/header";
import IconInput from "../../components/inputs/iconInput";
import { User, Key, ArrowRight } from "../../assets/icons/icons";
import { IconButton } from "../../components/buttons";
import { useHistory } from "react-router-dom";
import { withAlert } from "react-alert";
import AuthService from "../../services/auth.service";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { AiFillPhone } from "react-icons/ai";
import { Link } from "react-router-dom";
import { SIGNUP_SUPER_ADMIN } from "../../router/router";
const Signup = ({ alert }) => {
	const history = useHistory();
	const [first_name, setFirst_name] = useState("");
	const [last_name, setLast_name] = useState("");
	const [phone_number, setPhone_number] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isPassword, setIsPassword] = useState(true);
	const [loading, setLoading] = useState(false);

	const signup = async (e) => {
		e.preventDefault();
		setLoading(true);
		await AuthService.signup({
			first_name,
			last_name,
			phone_number,
			email,
			password,
		})
			.then((res) => {
				alert.show(
					"Registration successful!!!",

					{
						type: "success",
					}
				);
				history.push("/login");
			})
			.catch((error) => {
				const errMsg =
					error &&
					(error.response || error.message || error.toString() || error);
				alert.show(
					(errMsg && errMsg.data && errMsg.data.message) ||
						"An error has occured, try again!",

					{
						type: "error",
					}
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<main className="main_container">
			<div className="main_container_content">
				<Header noUser title="Delivery Partner Registration" />

				<section className="login_back_section main_section">
					<h6 className="bold_24px mt_35 capitalize mb_15">Welcome!</h6>

					<h6 className="bold_24px capitalize">
						Fill in your details to create an account
					</h6>
					<form className="login_form second_width_desktop" onSubmit={signup}>
						<IconInput
							required
							withLabel
							label="First Name"
							placeholder="Enter first name"
							value={first_name}
							onChange={(e) => setFirst_name(e)}
							icon={<User width="13.5px" height="13.5px" />}
							type="name"
							containerClass="mb_25"
						/>

						<IconInput
							required
							withLabel
							label="Last Name"
							placeholder="Enter last name"
							value={last_name}
							onChange={(e) => setLast_name(e)}
							icon={<User width="13.5px" height="13.5px" />}
							type="name"
							containerClass="mb_25"
						/>

						<IconInput
							required
							withLabel
							label="Email"
							placeholder="Enter email"
							value={email}
							onChange={(e) => setEmail(e)}
							icon={<User width="13.5px" height="13.5px" />}
							type="email"
							containerClass="mb_25"
						/>

						<IconInput
							required
							withLabel
							label="Phone Number"
							placeholder="Enter phone number"
							value={phone_number}
							onChange={(e) => setPhone_number(e)}
							icon={<AiFillPhone width="13.5px" height="13.5px" />}
							type="tel"
							containerClass="mb_25"
						/>
						<IconInput
							required
							withLabel
							label="Password"
							placeholder="Enter Password"
							value={password}
							onChange={(e) => setPassword(e)}
							icon={<Key width="13.5px" height="13.5px" />}
							type={isPassword ? "password" : "text"}
							subIcon={
								isPassword ? (
									<AiFillEye
										width="16px"
										height="16px"
										onClick={() => setIsPassword(!isPassword)}
									/>
								) : (
									<AiFillEyeInvisible
										width="16px"
										height="16px"
										onClick={() => setIsPassword(!isPassword)}
									/>
								)
							}
						/>

						<IconButton
							withSubIcon
							subIcon={<ArrowRight width="13px" height="13px" fill="#2699FB" />}
							text="Signup"
							className="mt_35 login_btn"
							isBlue
							loading={loading}
							onClick={signup}
						/>
					</form>
					<p className="normal_16px cursor_pointer mb_12">
						Already have an account?{" "}
						<Link className="underlined black bold" to="/login">
							Login
						</Link>
					</p>

					<p className="normal_16px cursor_pointer mb_25">
						<Link className="underlined black bold" to={SIGNUP_SUPER_ADMIN}>
							Signup for a super-admin account
						</Link>
					</p>
				</section>
			</div>
		</main>
	);
};
export default withAlert()(Signup);
