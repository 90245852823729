import React, { useState } from "react";
import PropTypes from "prop-types";
import { GoSearch } from "react-icons/go";
import SearchDateRange from "./DateRange";
import moment from "moment";
import Button from "../../../components/buttons";
import RadioGroup from "../../../components/inputs/RadioGroup";
import SearchButton from "../../../components/buttons/SearchButton";

const SearchByDate = ({ onSearchClick, loading, disabled }) => {
	const [searchByDate, setSearchByDate] = useState(null);
	const [searchKeyword, setSearchKeyword] = useState(null);
	const [form, setForm] = useState({
		filter_type: null,
		start_date_range: "",
		end_date_range: "",
	});
	const [selectionRange, setSelectionRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: "selection",
	});
	function onChange(e) {
		setSearchByDate(e.target.value);
		setForm({
			...form,
			filter_type: e.target.value,
		});
	}
	function handleDateSelection(val) {
		setSelectionRange(val);

		let formatStartDate = moment(val.startDate).format("YYYY-MM-DD");
		let formatEndDate = moment(val.endDate).add(1, "days").format("YYYY-MM-DD");

		setForm({
			...form,
			start_date_range: formatStartDate,
			end_date_range: formatEndDate,
		});
	}
	function clearFilter() {
		setSelectionRange({
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		});
	}
	function onClick() {
		onSearchClick(form);
	}

	return (
		<div className="w-full font-bold space-y-3 ">
			<SearchDateRange
				onChange={handleDateSelection}
				ranges={selectionRange}
				clearDateFilter={() => clearFilter()}
			/>
			<p className="text-center md:text-left">
				Show these results from the selected date…
			</p>
			<div className=" flex justify-center md:justify-start mx-auto md:mx-0">
				<RadioGroup
					name="date"
					selectedOption={searchByDate}
					options={[
						{ title: "User Signups", value: "user_signup" },
						{ title: "Trip History", value: "trip_history" },
					]}
					onChange={onChange}
					className=" font-bold"
					// orientation={"vertical"}
				/>
			</div>
			<div className="w-fit mx-auto pt-5">
				<SearchButton
					icon={<GoSearch />}
					text="Search"
					textClass="text-base"
					blueBg
					onClick={onClick}
					isLoading={loading}
					isDisabled={form.filter_type && form.start_date_range ? false : true}
				/>
			</div>
		</div>
	);
};

SearchByDate.propTypes = {
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	onSearchClick: PropTypes.func,
};

export default SearchByDate;
