import moment from "moment";
import React, { Fragment } from "react";
import OrderDetailsCard from "./OrderDetailsCard";
import { Hearts } from "react-loader-spinner/dist/loader/Hearts";
import OrderDetails from "../userprofile/orderDetails";
import { numberWithCommas } from "../../../helpers/commaSeparator";
import { useHistory } from "react-router";

const OrderHeader = ({ orderData }) => {
	const history = useHistory();

	const calculatedTotal = parseInt(orderData?.calculated_order?.total_amount);
	const paid = orderData?.paid;
	const orderDetails = orderData?.order_details;
	const isGroupOrder = orderData?.calculated_order?.is_group_order
		? true
		: false;
	const walletAmount =
		typeof orderDetails === "string"
			? JSON.parse(orderDetails)?.wallet_amount
			: typeof orderDetails === "object"
				? orderDetails?.wallet_amount
				: 0;
	const amountDue =
		!paid && walletAmount && orderDetails?.use_wallet
			? calculatedTotal - parseInt(walletAmount)
			: !paid && !orderDetails?.use_wallet
				? calculatedTotal - 0
				: 0;
	const groupCount =
		orderData?.calculated_order?.sub_calculated_order_ids?.length + 1;

	const totalAmt = orderData?.order_details?.use_wallet
		? parseInt(orderData?.calculated_order?.total_amount) -
		parseInt(orderData?.order_details?.wallet_amount)
		: parseInt(orderData?.calculated_order?.total_amount);
	// Reducer function to compute total
	const getTotal = (items) => {
		let total = items.reduce(function (acc, curr) {
			return acc + curr.quantity;
		}, 0);
		return total;
	};

	// To calculate the total number of items bought

	// To calculate the total number of items bought
	const getItemsTotal = (orders) => {
		let i;
		let tot = [];
		for (i = 0; i < orders.length; i++) {
			tot.push(getTotal(orders[i].meals));
		}
		let allTotals = tot.reduce((acc, curr) => {
			return acc + curr;
		}, 0);

		return allTotals;
	};
	const subOrders =
		orderData?.calculated_order?.sub_calculated_order_ids || null;

	const serviceTotal = parseInt(orderData?.calculated_order?.service_charge);

	const deliveryFee = parseInt(
		(orderData?.calculated_order?.free_delivery && "0") ||
		orderData?.calculated_order?.delivery_fee
	);
	//  serviceTotal + deliveryFee
	const extraCharges = serviceTotal + deliveryFee;

	const subTotal = calculatedTotal - extraCharges;
	//if order is a group order

	// calculated total for group order
	const calculatedGroupTotal = () => {
		let alltotal = subOrders?.reduce((acc, curr) => {
			return acc + (parseFloat(curr.total_amount) - extraCharges);
		}, 0);
		return alltotal + subTotal;
	};
	const amountDueGroup =
		!paid && walletAmount && orderDetails?.use_wallet
			? calculatedGroupTotal() - parseInt(walletAmount)
			: !paid && !orderDetails?.use_wallet
				? calculatedGroupTotal() - 0
				: 0;

	function setUserPage(id) {
		history.push(`/search/profile/${id}`);
	}

	return (
		<div className="space-y-6 w-full">
			<div className=" gap-x-2 gap-y-4 flex flex-wrap justify-between items-start">
				<div className="space-y-2 w-full mdLg:w-[30%]">
					<div className="flex items-end gap-2 flex-wrap">
						{orderData?.cancelled ? (
							<span className="font-bold text-grey-backdrop text-base sm:text-lg mdLg:text-2xl ">
								Order
								<span className="uppercase"> #{orderData.order_code}</span>
							</span>
						) : (
							<span className="font-bold text-orange-600 text-base sm:text-lg mdLg:text-2xl ">
								Order
								<span className="uppercase"> #{orderData.order_code}</span>
							</span>
						)}

						{orderData?.completed && orderData?.kitchen_dispatched ? (
							<span className="text-sm text-grey-backdrop font-light">
								Order Is Completed
							</span>
						) : orderData?.cancelled ? (
							<span className="text-sm text-red-100 font-bold">
								CANCELLED ORDER
							</span>
						) : !orderData?.completed && !orderData?.kitchen_dispatched ? (
							<span className="text-sm text-grey-backdrop font-light">
								Order Has Not Been Dispatched By Kitchen
							</span>
						) : (
							!orderData?.completed &&
							orderData?.kitchen_dispatched && (
								<span className="text-sm text-grey-backdrop font-light">
									Order Has Been Dispatched By Kitchen
								</span>
							)
						)}
					</div>

					<div className="flex gap-6 justify-start">
						<OrderDetails
							order={orderData}
							className="font-bold text-lg mdLg:text-lg text-orange-600 underline ml-0 underline-offset-1"
						/>

						{orderData?.first_order && (
							<div className="flex items-center gap-1 uppercase font-bold">
								{" "}
								<Hearts width={60} height={25} color="#FF9636" />
								NEW!
							</div>
						)}
					</div>

					<div className="flex flex-col space-y-2 text-base">
						<div>
							<span>Order created at:</span>{" "}
							{moment(orderData?.created_at).format("LT")} |{" "}
							{moment(orderData?.created_at).format("DD MMM YYYY")}
						</div>

						{orderData?.kitchen_completed_time && (
							<div>
								<span> Kitchen completed at: </span>{" "}
								<span>
									{" "}
									{moment(orderData?.kitchen_completed_time).format(
										"LT"
									)} |{" "}
									{moment(orderData?.kitchen_completed_time).format(
										"DD MMM YYYY"
									)}
								</span>
							</div>
						)}

						{orderData?.kitchen_dispatched_time && (
							<div>
								<span> Order dispatched at: </span>{" "}
								<span>
									{" "}
									{moment(orderData?.kitchen_dispatched_time).format(
										"LT"
									)} |{" "}
									{moment(orderData?.kitchen_dispatched_time).format(
										"DD MMM YYYY"
									)}
								</span>
							</div>
						)}
						{orderData?.completed_time && (
							<div>
								<span>Order Completed at:</span>{" "}
								{moment(orderData?.completed_time).format("LT")} |{" "}
								{moment(orderData?.completed_time).format("DD MMM YYYY")}
							</div>
						)}
					</div>

					<div className="flex flex-col  w-full sm:w-[80%] mdLg:w-[60%]">
						<span className="underline font-bold text-base mdLg:text-lg">
							Delivery Details
						</span>
						<span>
							From: <span className="bold">CoKitchen Lekki</span>
						</span>

						<div className="">
							<a
								className=""
								href={`https://www.google.com/maps/search/?api=1&query=${orderData?.calculated_order?.lat},${orderData?.calculated_order?.lng}`}
								target="_blank"
								rel="noreferrer"
							>
								To:{" "}
								<span className="text-grey-backdrop underline w-full">
									{orderData?.calculated_order?.address_details?.address_line}
								</span>
							</a>{" "}
							<p>
								Phone: <span className="underline text-gray-500">{orderData?.calculated_order?.address_details?.phone_number}</span>
							</p>
						</div>
					</div>

					{orderData?.order_details?.rider_note &&
						(orderData?.calculated_order?.pickup ? (
							<p className="">
								Pickup Note:
								<span className=" w-full font-bold"> {orderData?.order_details?.rider_note}</span>
							</p>
						) : (
							<p className="">
								Rider Note:
								<span className=" w-full font-bold"> {orderData?.order_details?.rider_note}</span>
							</p>
						))}

					{orderData?.cancelled_note && (
						<div className="flex gap-2 flex-wrap">
							<span className="underline font-bold text-base mdLg:text-lg">
								Cancellation Note:
							</span>
							<span className=" font-bold text-red-100 text-base mdLg:text-lg">
								{orderData?.cancelled_note}
							</span>
						</div>
					)}
				</div>

				<div className="">
					<div className="">
						{" "}
						<span className="underline font-bold text-base mdLg:text-lg">
							{(isGroupOrder && "Group order details:") || "Order details:"}
						</span>{" "}
						for {groupCount} {groupCount === 1 ? "Person" : "People"}
					</div>
					<div className="flex flex-col">
						<label className="">
							{(orderData?.calculated_order?.meals?.length > 1 &&
								orderData.calculated_order.meals.length + " brands") ||
								orderData?.calculated_order?.meals?.length + " brand"}
							,{" "}
							{`${orderData?.calculated_order?.meals &&
								getItemsTotal(orderData?.calculated_order?.meals)
								} ${orderData?.calculated_order?.meals &&
									getItemsTotal(orderData?.calculated_order?.meals) === 1
									? "item"
									: "items"
								}`}
						</label>
						<label className="">
							Amount paid by wallet: ₦{" "}
							{walletAmount ? numberWithCommas(walletAmount) : 0}
						</label>
						<label className="">
							Order total: ₦{" "}
							{isGroupOrder
								? numberWithCommas(totalAmt)
								: numberWithCommas(totalAmt)}
						</label>
						{!paid ? (
							<div className="">
								<p className="">Amount due:</p>
								{isGroupOrder ? (
									<p className="">
										₦
										{numberWithCommas(
											(amountDueGroup && amountDueGroup + extraCharges) || 0
										)}
									</p>
								) : (
									<p className="">
										₦{numberWithCommas((amountDue && amountDue) || 0)}
										.00
									</p>
								)}
							</div>
						) : null}
						<div>
							<label className="">Cutlery: </label>

							{orderData?.order_details?.cutlery ? (
								<label className="font-semibold text-orange-600 uppercase">
									{""}
									Yes
								</label>
							) : (
								<label className="font-semibold text-orange-600 uppercase">
									{""}
									No
								</label>
							)}
						</div>
						<div className="flex space-x-1">
							<label className="">Payment Type: </label>
							<label className="font-semibold text-orange-600">
								{""}
								{` ${orderData?.order_type?.name}`}
							</label>
						</div>

						<label className="bold_21px mb_5">__</label>
					</div>
				</div>

				<div className="flex flex-col gap-4">
					<div className="flex flex-col bg-orange-200 bg-opacity-60 w-full sm:w-[80%] mdLg:w-fit p-4">
						<p className="underline font-bold text-base mdLg:text-lg">
							User information:{" "}
						</p>
						<div className="flex flex-col">
							<p
								className=" hover:text-orange-600 hover:underline hover:underline-offset-2 cursor-pointer hover:font-bold"
								onClick={() => setUserPage(orderData?.user_id)}
							>
								Name:{" "}
								{orderData?.calculated_order?.user?.first_name +
									" " +
									orderData?.calculated_order?.user?.last_name}
							</p>
							<p className="">
								Email: {orderData?.calculated_order?.user?.email}
							</p>
						</div>
						<div className="flex flex-col">
							<a
								href={`tel:${orderData?.calculated_order?.user?.phone_number}`}
								className="hover:text-orange-600 hover:font-bold hover:underline"
							>
								<p className="">
									Phone: {orderData?.calculated_order?.user?.phone_number}
								</p>
							</a>
							<p className="">
								User since:{" "}
								{orderData?.calculated_order?.user?.created_at &&
									moment(orderData.calculated_order.user.created_at).format(
										"DD MMM YYYY"
									)}
							</p>
							<p className="">
								Wema Account (Bani):
								{orderData?.user?.bani_account_number || "Not available"}
							</p>
							<p className="">
								Providus Account (Bani):{orderData?.user?.bani_account_number_providus || "Not available"}
							</p>
						</div>
					</div>
					{!orderData?.cancelled &&
						(orderData?.rider_assigned ? (
							<div className="width_full">
								<span className="underline font-bold text-base mdLg:text-lg">
									Rider Details:
								</span>
								<p className="">
									First Name:{" "}
									<span className=" "> {orderData?.rider?.first_name}</span>
								</p>
								<p className="">
									<span className=" ">Last Name:</span>{" "}
									{orderData?.rider?.last_name}
								</p>
								<p className="">
									<span className=" ">Email:</span> {orderData?.rider?.email}
								</p>
								<a
									href={`tel:${orderData?.rider?.phone_number}`}
									className="hover:text-orange-600 hover:font-bold hover:underline"
								>
									<span className=" ">Phone Number:</span>{" "}
									{orderData?.rider?.phone_number}
								</a>
							</div>
						) : (
							orderData?.pickup && (
								<p className="font-bold text-orange-500">
									THIS ORDER WAS PICKED UP
								</p>
							)
						))}
				</div>
			</div>

			<div className="flex flex-col gap-3">
				{isGroupOrder && (
					<span className="font-bold text-orange-500">
						Host -{" "}
						{orderData?.calculated_order?.user?.first_name +
							" " +
							orderData?.calculated_order?.user?.last_name}
					</span>
				)}
				<div>
					{isGroupOrder ? (
						<div className="flex flex-wrap gap-y-4 gap-x-6">
							{orderData?.calculated_order?.meals
								.filter((item) => item.brand.item_type === "FOOD")
								.map((item, i) => {
									return (
										<Fragment>
											<OrderDetailsCard
												itemType={"Food"}
												title={item && item.brand && item.brand.name}
												items={item && item.meals}
												key={"item" + i}
											/>
										</Fragment>
									);
								})}
						</div>
					) : (
						<div className="flex flex-wrap gap-y-4 gap-x-6">
							{orderData?.calculated_order?.meals
								.filter((item) => item.brand.item_type === "FOOD")
								.map((item, i) => {
									return (
										<Fragment>
											<OrderDetailsCard
												itemType={"Food"}
												title={item && item.brand && item.brand.name}
												items={item && item.meals}
												key={"item" + i}
											/>
										</Fragment>
									);
								})}
						</div>
					)}
				</div>
			</div>

			<div className="flex flex-wrap gap-y-4 gap-x-6">
				{orderData?.calculated_order?.meals
					.filter((item) => item.brand.item_type === "SHOP")
					.map((item, i) => {
						return (
							<div>
								<OrderDetailsCard
									itemType={"Shop"}
									title={item && item.brand && item.brand.name}
									items={item && item.meals}
									key={"item" + i}
								/>
							</div>
						);
					})}
			</div>

			<div className="flex flex-wrap gap-8 sm:gap-16 mdLg:gap-24">
				{isGroupOrder &&
					subOrders?.map((item, i) => {
						return (
							<div className="space-y-6">
								<span className="font-bold text-orange-500">
									{item?.user?.first_name + " " + item?.user?.last_name}
								</span>
								{item?.meals &&
									item?.meals
										.filter((item) => item.brand.item_type === "FOOD")
										.map((itm, i) => {
											return (
												<Fragment>
													<OrderDetailsCard
														itemType={"Food"}
														title={itm && itm.brand && itm.brand.name}
														items={itm && itm.meals}
														key={"item" + i}
													/>
												</Fragment>
											);
										})}
								{item?.meals &&
									item?.meals
										.filter((item) => item.brand.item_type === "SHOP")
										.map((itm, i) => {
											return (
												<Fragment>
													<OrderDetailsCard
														itemType={"Shop"}
														title={itm && itm.brand && itm.brand.name}
														items={itm && itm.meals}
														key={"item" + i}
													/>
												</Fragment>
											);
										})}
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default OrderHeader;
