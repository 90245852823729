import React, { useEffect, useState } from "react";
import SearchStore from "../store";
import AnalyticsCard from "../../../components/card/AnalyticsCard";
import { useParams } from "react-router";
import {
  GET_RIDER_STATS,
  GET_USER_BRANDS,
  GET_USER_MEALS,
  GET_USER_STATS,
} from "../../../helpers/urls";
import dataService from "../../../services/data.service";
import { numberWithCommas } from "../../../helpers/commaSeparator";
import errorHandler from "../../../helpers/errorHandler";

const UserProfileAnalytics = ({
  user,
  alert,
  topOrderedPolygon,
  polygonLoading,
}) => {
  const { mealsLoading, brandsLoading } = SearchStore;
  const [topUserBrands, setTopUserBrands] = useState([]);
  const [topUserMeals, setTopUserMeals] = useState([]);
  const [viewAllMeals, setViewAllMeals] = useState(false);
  const [viewAllBrands, setViewAllBrands] = useState(false);
  const [userStats, setUserStats] = useState([]);
  const [userMeals, setUserMeals] = useState([]);
  const [userBrands, setUserBrands] = useState([]);
  const [statsLoading, setStatsLoading] = useState([]);
  let rider = "RIDER";
  let role = user?.role;
  useEffect(() => {
    handleCalls();
  }, [user, role]);

  const { id } = useParams();

  const handleCalls = async () => {
    await Promise.all([getStats(id), getBrands(id), getMeals(id)]);
  };

  const getStats = async () => {
    setStatsLoading(true);

    try {
      if (role === rider) {
        const res = await dataService.getInternalData(GET_RIDER_STATS + id);
        setUserStats(res?.data);
      } else {
        const res = await dataService.getInternalData(GET_USER_STATS + id);
        setUserStats(res?.data);
      }
    } catch (error) {
      errorHandler(alert, error);
    } finally {
      setStatsLoading(false);
    }
  };

  const getBrands = async () => {
    try {
      const res = await dataService.getInternalData(GET_USER_BRANDS + id);
      setUserBrands(res?.data?.data || []);
    } catch (error) {
      errorHandler(alert, error);
    } finally {
    }
  };

  const getMeals = async () => {
    try {
      const res = await dataService.getInternalData(GET_USER_MEALS + id);

      setUserMeals(res?.data?.data || []);
    } catch (error) {
      errorHandler(alert, error);
    } finally {
    }
  };

  const topOrderedPolygonArray = topOrderedPolygon
    ?.sort((a, b) => b?.orders_length - a?.orders_length)
    ?.map((item) => {
      return {
        name: item?.cokitchen_polygon?.name,
        length: item?.orders_length,
      };
    });

  const topOrderedPolygonArrayFiltered = topOrderedPolygonArray?.filter(
    (item, idx) => {
      if (idx < 3) {
        return item;
      }
    }
  );

  const array = viewAllBrands
    ? topOrderedPolygonArray
    : topOrderedPolygonArrayFiltered;

  return (
    <div className="flex flex-wrap items-start justify-center md:justify-start  gap-6 ">
      <AnalyticsCard
        title="Total number of orders"
        totalOrders={user?.total_number_of_orders || 0}
        noOfOrders
      />

      {role !== rider && (
        <AnalyticsCard
          title="Total spent"
          totalAmountSpent={`₦${numberWithCommas(
            userStats?.total_all_amount_spent || 0
          )}`}
          unsettleOrderAmount={`₦${numberWithCommas(
            userStats?.total_cash_amount_spent || 0
          )}`}
          settledOrderAmount={`₦${numberWithCommas(
            userStats?.total_other_amount_spent || 0
          )}`}
          loading={statsLoading}
        />
      )}
      <AnalyticsCard
        title="Avg. total order duration"
        orderDuration={userStats?.average_delivery_time || 0}
        loading={statsLoading}
      />
      <AnalyticsCard
        title="Top Ordered Polygons"
        list={array || []}
        cardWithListItems
        onClick={() => setViewAllBrands(!viewAllBrands)}
        orders
        loading={polygonLoading}
        viewButton={viewAllBrands ? "Hide" : "View All"}
      />
      {user?.bani_account_number || user?.user?.bani_account_number || user?.user?.bani_account_number_providus ||
      user?.bani_account_number_providus ? (
        <AnalyticsCard
          title="Bani Account number "
          list={
            [
              {
                name: "Wema account (Bani) ",
                length: user?.user?.bani_account_number || user?.bani_account_number || "Not available",
              },
              {
                name: "Providus account (Bani) ",
                length: user?.user?.bani_account_number_providus || user?.bani_account_number_providus || "Not available",
              },
            ] || []
          }
          cardWithListItems
          onClick={() => setViewAllBrands(!viewAllBrands)}
        />
      ) : (
        ""
      )}
      {/* 

		
			
			<AnalyticsCard
						title={"Top picks from FC Shop"}
						list={list}
						cardWithListItems
					/>  
				<AnalyticsCard
				title="Basket abandonment rate"
				basketAbadonmentRate={(
					user.user.basket_abandonment_rate || 0
				).toLocaleString(undefined, { maximumFractionDigits: 2 })}
			/>
			<AnalyticsCard
				title={"Top ordered items"}
				list={topUserMeals || []}
				cardWithListItems
				onClick={() => setViewAllMeals(!viewAllMeals)}
				loading={statsLoading}
				viewButton={viewAllMeals ? "Hide" : "View All"}
			/>
			
			<AnalyticsCard
				title={" Top brands"}
				list={topUserBrands || []}
				cardWithListItems
				onClick={() => setViewAllBrands(!viewAllBrands)}
				loading={statsLoading}
				viewButton={viewAllBrands ? "Hide" : "View All"}
			/>
				*/}
    </div>
  );
};

export default UserProfileAnalytics;

// const topBrands = () => {
// 	if (userBrands?.length > 0) {
// 		if (viewAllBrands) {
// 			let length = userBrands?.map((item) => {
// 				return { name: item?.brand?.name, length: item?.orders?.length };
// 			});
// 			let highestToLowest = length.sort((a, b) => b.length - a.length);
// 			highestToLowest.length = 10;
// 			setTopUserBrands(highestToLowest);
// 		} else {
// 			let length = userBrands?.map((item) => {
// 				return { name: item?.brand?.name, length: item?.orders?.length };
// 			});
// 			let highestToLowest = length.sort((a, b) => b.length - a.length);
// 			highestToLowest.length = 4;
// 			setTopUserBrands(highestToLowest);
// 		}
// 	}
// };

// const topMeals = () => {
// 	if (userMeals?.length > 0) {
// 		if (viewAllMeals) {
// 			let length = userMeals?.map((item) => {
// 				return { name: item?.meal.name, length: item?.orders_length };
// 			});
// 			let highestToLowest = length.sort((a, b) => b.length - a.length);
// 			highestToLowest.length = 8;
// 			setTopUserMeals(highestToLowest);
// 		} else {
// 			let length = userMeals?.map((item) => {
// 				return { name: item?.meal.name, length: item?.orders_length };
// 			});
// 			let highestToLowest = length.sort((a, b) => b?.length - a?.length);
// 			highestToLowest.length = 4;
// 			setTopUserMeals(highestToLowest);
// 		}
// 	}
// };
