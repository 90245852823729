import React from "react";
import PropTypes from "prop-types";

const RadioGroup = ({
	className,
	name,
	options,
	onChange,
	orientation,
	selectedOption,
	required,
}) => {
	return (
		<div
			className={`flex flex-wrap justify-start  gap-7 ${className} ${
				orientation === "vertical" ? "flex-col space-y-2" : ""
			}`}
		>
			{options?.length > 0 &&
				options.map(({ value, title }, idx) => (
					<div
						key={idx}
						className={" flex  space-x-2 items-center text-sm w-fit ="}
					>
						<input
							data-testid={name}
							id={`${name}-${title}-${idx}`}
							name={name}
							className=""
							onChange={onChange}
							type="radio"
							value={value}
							checked={selectedOption === value}
							required={required}
						/>
						<label htmlFor={`${name}-${title}-${idx}`} className="">
							<span className="text-sm">{title}</span>
							<span className="bg-current checkmark"></span>
						</label>
					</div>
				))}
		</div>
	);
};

RadioGroup.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.object,
	onChange: PropTypes.any,
	orientation: PropTypes.any,
	selectedOption: PropTypes.string,
	required: PropTypes.bool,
};
export default RadioGroup;
