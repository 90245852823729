import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import { numberWithCommas } from "../../helpers/commaSeparator";
import CommonStore from "../../stores/superAdminStores/commonStore";
import { withAlert } from "react-alert";
import "./styles.scss";
import HistoryStore from "../../stores/superAdminStores/historyStore";
const PartnerHistoryRecord = ({ alert }) => {
  const [transactionHistory, setTransactionHistory] = useState([
    {
      name: "---",
      tripRevenue: "---",
      paymentAmount: "---",
      previousPayment: "---",
      notes: `---`,
    },

    {
      name: "---",
      tripRevenue: "---",
      paymentAmount: "---",
      previousPayment: "---",
      notes: `---`,
    },
  ]);

  const historyParams = [
    "Partner name",
    "Trip revenue",
    "Payment amount",
    "Days since previous payment",
    "Notes",
  ];
  const [location, setLocation] = useState("");

  const [selectedlocations, setSelectedLocations] = useState([]);
  const [cokitchens, setCokitchens] = useState([]);
  const [loading, setLoading] = useState(false);

  // load data from store
  useEffect(async () => {
    if (!HistoryStore.dataCached) {
      await loadData();
    }
    if (!CommonStore.dataCached) {
      await CommonStore.getCokitchens(alert);
    }

    setTransactionHistory(HistoryStore.histories);
    setCokitchens(CommonStore.cokitchens);
  }, []);

  const loadData = async () => {
    setLoading(true);
    await Promise.all([HistoryStore.getTransactionHistories(alert, true)]);
    setLoading(false);
  };

  return (
    <Layout
      locationChange={setLocation}
      paymentClass="red_button_fill"
      withHistoryBtn
    >
      <section className="main_section flex_row_top content_section tab_column">
        <section className="left_section width_full">
          {/* Left */}
          <table className="app_table">
            <thead>
              <tr className="patient_list_table_head payment_history_list_table_head">
                {historyParams.map((head, i) => {
                  return <th key={head + i}>{head}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {transactionHistory.map((item, i) => {
                let type = item?.transaction_type;
                return (
                  <tr className="normal_15px">
                    <td className="first_td">
                      {item?.logistics_company?.name}
                    </td>
                    <td>
                      {type === "CARD" || type === "WALLET" || type === "CASH"
                        ? "₦" + numberWithCommas(parseInt(item?.amount))
                        : "---"}
                    </td>
                    <td>
                      {type === "Withdraw" ||
                      type === "Deposit" ||
                      type === "Penalty-Fee"
                        ? "₦" + numberWithCommas(parseInt(item?.amount))
                        : "---"}
                    </td>
                    <td>{item.previousPayment}</td>
                    <td className="last_td">{item?.reason}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      </section>
    </Layout>
  );
};

export default withAlert()(PartnerHistoryRecord);
