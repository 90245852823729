import React from 'react'
import './styles.scss'
import Loader from 'react-loader-spinner'
import { Link } from 'react-router-dom'
const TextCard = ({
  cardLabel,
  withLink,
  link,
  cardValue,
  withFooter,
  cardFooter,
  className,
  loading,
  to = '#/#'
}) => {
  return (
    <div
      className={`text_card_container p_10_20 flex_column_center ${className}`}
    >
      <div className='flex_row_between width_full mb_35'>
        <label className='bold_21px'>{cardLabel}</label>
        {withLink && link}
      </div>
      <h6 className='normal_53px bold green_text mb_35'>
        {' '}
        {loading ? (
          <Loader type='ThreeDots' color='#1fc900 ' height={10} width={50} />
        ) : (
          cardValue
        )}
      </h6>

      {withFooter && (
        <Link
          className='gray normal_16px'
          to={to}
          style={{ textDecoration: to === '#/#' ? 'none' : 'underline' }}
        >
          {cardFooter}
        </Link>
      )}
    </div>
  )
}
export default TextCard
