import React, { useRef, useState } from "react";
import { observer } from "mobx-react";
import SearchStore from "../../../stores/superAdminStores/searchStore";
import { useHistory, useParams } from "react-router";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner/dist/loader/ThreeDots";
import OrderHeader from "./OrderHeader";
import OrderButtons from "./OrderButtons";
import CancelTrip from "./order-modals/CancelTrip";
import UpdateTrip from "./order-modals/UpdateTrip";
import CompleteTrip from "./order-modals/CompleteTrip";
import Layout from "../../../components/layout";
import { BiArrowBack } from "react-icons/bi";
import { withAlert } from "react-alert";
import { SEARCH } from "../../../router/router";
import ChangeAddress from "./order-modals/ChangeAddress";

const OrderPage = observer(({ alert }) => {
	const history = useHistory();

	const {
		getOrderById,
		orderDetailsLoading,
		orderData,
		cancelTripLoading,
		updateOrderAmount,
		updateOrderAmountLoading,
		cancelOrders,
		completeTripLoading,
		completeOrder,
		isCompleted,
		isCancelled,
		changeAddress,
		getCokitchenPolygonId,
		coordinatesDetails,
		cordinateLoading,
		addressLoading,
		creditWallet,
		debitWallet,
		creditLoading,
		debitLoading,
		getUserById,
		userData,
		completeOrderOnly,
	} = SearchStore;
	const { id } = useParams();
	const [updateTripModal, setUpdateTripModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [completeTripModal, setCompleteTripModal] = useState(false);
	const [changeAddressModal, setChangeAddressModal] = useState(false);
	const [showButtons, setShowbuttons] = useState(true);

	useEffect(() => {
		if (isCompleted) {
			setShowbuttons(false);
		} else if (isCancelled) {
			setShowbuttons(false);
		} else {
			setShowbuttons(true);
		}
	}, [isCancelled, isCompleted]);
	const emptyForm = {
		reason: "",
		update_amount: "",
		payment_type: "",
		amount_paid: "",
	};
	const [form, setForm] = useState({
		...emptyForm,
	});
	useEffect(() => {
		getOrderDetails();
	}, []);

	// fetch Order Details
	const getOrderDetails = async () => {
		await getOrderById(alert, id);
	};

	// Handle Change
	const handleChange = (prop, val) => {
		setForm({ ...form, [prop]: val?.value || val });
	};

	// Print Receipt
	const receiptRef = useRef(null);

	function goBack() {
		const type = localStorage.getItem("type");
		const query = localStorage.getItem("query");
		const value = localStorage.getItem("value");
		const date = localStorage.getItem("date");
		if (type === "date") {
			history.push(`/search/users/query=${query}/${value}/${date} `);
		} else {
			history.push(`/search/users/query=${query}/${value}`);
		}
	}

	return (
		<Layout
			// locationChange={setLocation}
			search="active_nav_item"
			withHistoryBtn
			title={<h6 className="bold_24px mr_30">SearchLayout</h6>}
		>
			<div>
				<CancelTrip
					handleChange={handleChange}
					alert={alert}
					form={form}
					setForm={setForm}
					emptyForm={emptyForm}
					cancelTripLoading={cancelTripLoading}
					showModal={showModal}
					setShowModal={setShowModal}
					orderData={orderData}
					cancelOrders={cancelOrders}
				/>
				<UpdateTrip
					handleChange={handleChange}
					alert={alert}
					form={form}
					setForm={setForm}
					emptyForm={emptyForm}
					updateOrderAmountLoading={updateOrderAmountLoading}
					updateTripModal={updateTripModal}
					setUpdateTripModal={setUpdateTripModal}
					orderData={orderData}
					updateOrderAmount={updateOrderAmount}
				/>
				<CompleteTrip
					handleChange={handleChange}
					alert={alert}
					form={form}
					setForm={setForm}
					emptyForm={emptyForm}
					completeTripModal={completeTripModal}
					setCompleteTripModal={setCompleteTripModal}
					orderData={orderData}
					completeTripLoading={completeTripLoading}
					completeOrder={completeOrder}
					completeOrderOnly={completeOrderOnly}
				/>{" "}
				<ChangeAddress
					handleChange={handleChange}
					form={form}
					setForm={setForm}
					emptyForm={emptyForm}
					changeAddressModal={changeAddressModal}
					setChangeAddressModal={setChangeAddressModal}
					orderData={orderData}
					completeTripLoading={completeTripLoading}
					completeOrder={completeOrder}
					changeAddress={changeAddress}
					getCokitchenPolygonId={getCokitchenPolygonId}
					coordinatesDetails={coordinatesDetails}
					cordinateLoading={cordinateLoading}
					addressLoading={addressLoading}
					creditWallet={creditWallet}
					debitWallet={debitWallet}
					creditLoading={creditLoading}
					debitLoading={debitLoading}
					getUserById={getUserById}
					userData={userData}
				/>
				<div className="flex items-center space-x-2 mx-2 my-2">
					<BiArrowBack
						size={20}
						color="#FF0000"
						className="cursor-pointer"
						onClick={goBack}
					/>
					<span className="text-lg font-bold">Order Details</span>
				</div>
				<div className="pb-10 w-[90%] xs:w-[80%] mdLg:w-[95%] mx-auto space-y-10">
					<div>
						{orderDetailsLoading ? (
							<div>
								<ThreeDots color="#ff000060" height={10} width={50} />
							</div>
						) : (
							<div>
								<OrderHeader orderData={orderData} />
								<OrderButtons
									orderData={orderData}
									isCompleted={isCompleted}
									isCancelled={isCancelled}
									onCancelClick={() => setShowModal(!showModal)}
									updateOrderAmount={() => setUpdateTripModal(!updateTripModal)}
									onCompleteClick={() =>
										setCompleteTripModal(!completeTripModal)
									}
									changeAddress={() =>
										setChangeAddressModal(!changeAddressModal)
									}
									showButtons={showButtons}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
});

export default withAlert()(OrderPage);
