import { action, makeAutoObservable, observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import {
	GET_SEARCH_RESULT_BY_KEYWORD,
	GET_SEARCH_RESULT_BY_DATE,
	GET_USER_ORDERS,
	GET_USER_BY_ID,
	GET_ORDER_BY_ID,
	CANCEL_ORDER_URL,
	UPDATE_ORDER_AMOUNT_URL,
	COMPLETE_ORDER_URL,
	UPDATE_DELIVERY,
	GET_TOP_ORDERED_POLYGON,
	GET_RIDER_ORDERS,
	GET_RIDER_TOP_ORDERED_POLYGON,
	GET_COKITCHEN_POLYGON_ID,
	CHANGE_ADDRESS,
	CREDIT_WALLET,
	DEBIT_WALLET,
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
import { LIVE_TRIPS } from "../../router/router";

export class SearchStore {
	@observable users = [];
	@observable userOrders = [];
	@observable riderOrders = [];
	@observable orders = [];
	@observable orderData = [];
	@observable userData = [];
	@observable topOrderedPolygon = [];
	@observable coordinatesDetails = [];

	@observable dataCached = false;
	@observable ordersCached = false;
	@observable loading = false;
	@observable ordersLoading = false;
	@observable userProfileLoading = false;
	@observable debitLoading = false;
	@observable creditLoading = false;
	@observable orderDetailsLoading = false;
	@observable cancelTripLoading = false;
	@observable updateOrderAmountLoading = false;
	@observable completeTripLoading = false;
	@observable updateDeliveryLoading = false;
	@observable polygonLoading = false;
	@observable cordinateLoading = false;
	@observable addressLoading = false;

	@observable ordersDateRange = "";
	@observable dateRange = "";

	// @action updateStoreItem = (item, value) => {
	// 	this[item] = value;
	// };

	constructor() {
		makeAutoObservable(this);
	}
	searchByKeyword = async (alert, data, count) => {
		this.loading = true;

		try {
			let response = await dataService.postInternalData(
				GET_SEARCH_RESULT_BY_KEYWORD + count,
				data
			);
			let res;
			runInAction(() => {
				res = response && response.data;

				if (res) {
					// alert.show("Users Fetched successfully!", {
					// 	type: "success",
					// });
					// this.dataCached = true;
					this.users = res || [];
				}
			});
			return res;
		} catch (error) {
			errorHandler(error, alert);
		} finally {
			this.loading = false;
		}
	};

	searchByDate = async (alert, data, count) => {
		this.loading = true;

		try {
			let response = await dataService.postInternalData(
				GET_SEARCH_RESULT_BY_DATE + count,
				data
			);
			let res;
			runInAction(() => {
				res = response && response.data;

				if (res) {
					// alert.show("Users Fetched successfully!", {
					// 	type: "success",
					// });

					// this.dataCached = true;
					this.users = res || [];
				}
			});
			return res;
		} catch (error) {
			errorHandler(error, alert);
		} finally {
			this.loading = false;
		}
	};

	getUserOrders = async (alert, data, count = 1) => {
		this.ordersLoading = true;
		try {
			let response = await dataService.postInternalData(
				GET_USER_ORDERS + count,
				data
			);

			runInAction(() => {
				let res = response.data;
				this.dataCached = true;
				this.userOrders = res || [];
			});
		} catch (error) {
			errorHandler(error, alert);
		} finally {
			this.ordersLoading = false;
		}
	};

	getRiderOrders = async (alert, data, count = 1) => {
		this.ordersLoading = true;
		try {
			let response = await dataService.postInternalData(
				GET_RIDER_ORDERS + count,
				data
			);

			runInAction(() => {
				let res = response.data;
				this.dataCached = true;
				this.riderOrders = res || [];
			});
		} catch (error) {
			errorHandler(error, alert);
		} finally {
			this.ordersLoading = false;
		}
	};

	getUserById = async (alert, id, data) => {
		this.userProfileLoading = true;

		try {
			let response = await dataService.getAuthData(GET_USER_BY_ID + id);

			runInAction(() => {
				let res = response?.data?.user;
				// this.dataCached = true;
				this.userData = res || [];
			});
		} catch (error) {
			errorHandler(error, alert);
		} finally {
			this.userProfileLoading = false;
			// await Promise.all([this.getUserOrders(alert, data, 1)]);
		}
	};

	getOrderById = async (alert, id) => {
		this.orderDetailsLoading = true;
		try {
			let response = await dataService.getAuthData(GET_ORDER_BY_ID + id);
			runInAction(() => {
				let res = response.data && response.data.order;
				this.dataCached = true;
				this.orderData = res || [];
				this.isCancelled = response.data.order?.cancelled;
				this.isCompleted = response.data.order?.completed;
			});
		} catch (error) {
			errorHandler(error, alert);
		} finally {
			this.orderDetailsLoading = false;
		}
	};

	cancelOrders = async (alert, data, id) => {
		this.cancelTripLoading = true;

		try {
			let res = await dataService.postInternalData(CANCEL_ORDER_URL, data);
			const response = await dataService.getAuthData(GET_ORDER_BY_ID + id);
			this.orderData = response?.data?.order || [];
			this.isCancelled = response?.data?.order?.cancelled;
			this.isCompleted = response?.data?.order?.completed;

			if (res) {
				alert.show("Order cancelled successfully!", {
					type: "success",
				});
			}
			return res;
		} catch (error) {
			errorHandler(alert, error);
		} finally {
			this.cancelTripLoading = false;
		}
	};

	updateOrderAmount = async (alert, data, id) => {
		this.updateOrderAmountLoading = true;
		try {
			let res = await dataService.postInternalData(
				UPDATE_ORDER_AMOUNT_URL,
				data
			);
			const response = await dataService.getAuthData(GET_ORDER_BY_ID + id);
			this.orderData = response?.data?.order || [];
			if (res) {
				alert.show("Order updated successfully!", {
					type: "success",
				});
			}
			return res;
		} catch (error) {
			errorHandler(alert, error);
		} finally {
			this.updateOrderAmountLoading = false;
		}
	};

	completeOrder = async (alert, data, id) => {
		this.completeTripLoading = true;

		try {
			let res = await dataService.postInternalData(COMPLETE_ORDER_URL, data);
			const response = await dataService.getAuthData(GET_ORDER_BY_ID + id);
			this.orderData = response?.data?.order || [];
			this.isCancelled = response?.data?.order?.cancelled;
			this.isCompleted = response?.data?.order?.completed;

			if (res) {
				alert.show("Order completed successfully!", {
					type: "success",
				});
			}

			return res;
		} catch (error) {
			errorHandler(alert, error);
		} finally {
			this.completeTripLoading = false;
		}
	};

	completeOrderOnly = async (alert, data, history) => {
		this.completeTripLoading = true;

		try {
			let res = await dataService.postInternalData(COMPLETE_ORDER_URL, data);

			if (res) {
				alert.show("Order completed successfully!", {
					type: "success",
				});
			}

			if (history) {
				history.push(LIVE_TRIPS);
			}
			return res;
		} catch (error) {
			errorHandler(alert, error);
		} finally {
			this.completeTripLoading = false;
		}
	};

	updateDelivery = async (alert, data, id) => {
		this.updateDeliveryLoading = true;
		try {
			let res = await dataService.postInternalData(UPDATE_DELIVERY, data);
			let response = await dataService.getAuthData(GET_USER_BY_ID + id);

			// let userResponse = res && res?.data && res?.data?.user;
			let userResponse = response?.data?.user;

			// let userResponse = response?.data?.user;
			// this.dataCached = true;
			this.userData = userResponse || [];
		} catch (error) {
			errorHandler(error, alert);
		} finally {
			this.updateDeliveryLoading = false;
		}
	};

	getTopOrderedPolygon = async (alert, id) => {
		this.polygonLoading = true;
		try {
			let res = await dataService.getAuthData(GET_TOP_ORDERED_POLYGON + id);
			this.topOrderedPolygon = res?.data?.data;
		} catch (err) {
			errorHandler(alert, err);
		} finally {
			this.polygonLoading = false;
		}
	};

	getRidersTopOrderedPolygon = async (alert, id) => {
		this.polygonLoading = true;
		try {
			let res = await dataService.getAuthData(
				GET_RIDER_TOP_ORDERED_POLYGON + id
			);
			this.topOrderedPolygon = res?.data?.data;
		} catch (err) {
			errorHandler(alert, err);
		} finally {
			this.polygonLoading = false;
		}
	};

	// CHANGE ADDRESS

	getCokitchenPolygonId = async (data) => {
		this.cordinateLoading = true;
		try {
			let res = await dataService.postInternalData(
				GET_COKITCHEN_POLYGON_ID,
				data
			);
			this.coordinatesDetails = res?.data;
			console.log(this.coordinatesDetails);
		} catch (err) {
			this.error = err;
		} finally {
			this.cordinateLoading = false;
		}
	};

	changeAddress = async (data, id, alert) => {
		this.addressLoading = true;
		try {
			let res = await dataService.postInternalData(CHANGE_ADDRESS, data);
			const response = await dataService.getAuthData(GET_ORDER_BY_ID + id);
			this.orderData = response?.data?.order || [];
			alert.show("Address Updated successfully!", {
				type: "success",
			});
		} catch (err) {
			this.error = err;
		} finally {
			this.addressLoading = false;
		}
	};

	creditWallet = async (data, alert) => {
		this.creditLoading = true;
		try {
			let res = await dataService.postInternalData(CREDIT_WALLET, data);
			alert.show("Wallet Credited successfully!", {
				type: "success",
			});
		} catch (error) {
			this.error = error;
		} finally {
			this.creditLoading = false;
		}
	};
	debitWallet = async (data, alert) => {
		this.debitLoading = true;
		try {
			let res = await dataService.postInternalData(DEBIT_WALLET, data);
			alert.show("Wallet Debited  successfully!", {
				type: "success",
			});
		} catch (error) {
			this.error = error;
		} finally {
			this.debitLoading = false;
		}
	};
}
export default new SearchStore();
