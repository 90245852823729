// Admin Routes
const HOME = "/home";
const TRIP_HISTORY = "/trip-history";
// Super Admin Routes
const HOME_S_ADMIN = "/super-admin-home";
const NOTIFICATIONS_S_ADMIN = "/notifications";
const USERS = "/users";
const PARTNER_DB_S_ADMIN = "/partner-database";
const LIVE_TRIPS = "/live-trips-history-feedback";
const PAYMENT_HISTORY = "/payment-history";
const FULL_TRIP_DETAILS = "/full-trip-details/:type/:id";
const ONGOING_TRIP_DETAILS = "/ongoing-trip-details/:type/:id";

// search
const SEARCH = "/search";
const SEARCH_RESULT = "/search/users/query=:query/:value";
const SEARCH_RESULT_DATE = "/search/users/query=:query/:value/:date";

// order details
const ORDER_DETAILS = "/search/order/:id";

// user profile

const USER_PROFILE = "/search/profile/:id";

//Auth Routes
const LOGIN = "/login";
const SIGNUP = "/signup";

//Super admin Auth Routes

const SIGNUP_SUPER_ADMIN = "/signup-super-admin";

export {
	//   Auth
	LOGIN,
	SIGNUP,
	//   Dashboard
	HOME,
	TRIP_HISTORY,
	// Super Admin
	HOME_S_ADMIN,
	NOTIFICATIONS_S_ADMIN,
	PARTNER_DB_S_ADMIN,
	LIVE_TRIPS,
	PAYMENT_HISTORY,
	FULL_TRIP_DETAILS,
	ONGOING_TRIP_DETAILS,
	USERS,
	SIGNUP_SUPER_ADMIN,
	SEARCH,
	SEARCH_RESULT,
	ORDER_DETAILS,
	USER_PROFILE,
	SEARCH_RESULT_DATE,
};
