import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header";
import {
	ArrowBack,
	AppMap,
	Clock,
	User,
	Stars,
} from "../../assets/icons/icons";
import { GiStabbedNote } from "react-icons/gi";
import OrderCard from "../../components/orderCard";
import Button from "../../components/buttons";
import { useHistory } from "react-router-dom";
import { LIVE_TRIPS } from "../../router/router";
import Prompt from "../../components/modals/prompt";
import homeStore from "../../stores/superAdminStores/homeStore";
import { withAlert } from "react-alert";
import db from "../../services/firebase.config";
import "./styles.scss";
import moment from "moment";
import Map from "../../components/map";
import { numberWithCommas } from "../../helpers/commaSeparator";
import IconInput from "../../components/inputs/iconInput";
import Select from "../../components/select";
import CardAccordion from "../../components/cardAccordion";
import NewIcon from "../../assets/images/NEW-icon.png";
import Modal from "../../components/modals";
import { CHANGE_ADDRESS } from "../../helpers/urls";
import searchStore, {
	SearchStore,
} from "../../stores/superAdminStores/searchStore";

const MapPage = ({ alert, ...props }) => {
	const history = useHistory();
	const path = props.match.params.id;
	const pathType = props.match.params.type;
	const [showModal, setShowModal] = useState(false);
	const [showPrompt, setShowPrompt] = useState(false);
	const [showPromptAlt, setShowPromptAlt] = useState(false);
	const [loading, setLoading] = useState(false);
	const [submitting, setSubmitting] = useState(null);
	const [cancellationNote, setCancellationNote] = useState("");
	const [details, setDetails] = useState(null);
	const [orderTotal, setOrderTotal] = useState(null);
	const [activeRiderPos, setActiveRiderPos] = useState("");
	const [newAmount, setNewAmount] = useState("");
	const [showDetails, setShowDetails] = useState(false);
	const [currentOrder, setCurrentOrder] = useState(null);

	const [completeForm, setCompleteForm] = useState({
		amount_paid: "",
		bank_transfer: "",
	});
	// load data from store
	useEffect(async () => {
		if (!homeStore.dataFBCached) {
			await loadData();
		}
		setLoading(false);

		findCurrentTrip();
	}, []);

	const loadData = async () => {
		setLoading(true);
		let ongoing = [];
		const response = await db.collection("tracking_orders").onSnapshot(
			(items) => {
				items.forEach((doc) => {
					ongoing.push(doc.data());
				});
				findCurrentTrip(ongoing);
				homeStore.updateOngoingTrips(ongoing);
			},
			(err) => {
				console.log(err);
			}
		);

		setLoading(false);
	};
	// FInd currently selected trip
	const findCurrentTrip = async (
		allTrips = (homeStore && homeStore.ongoingTrips) || []
	) => {
		let active = await allTrips.find((item) => item.order_code === path);
		setDetails(active);
		if (active && active.rider && active.rider.id) {
			const response = await db
				.collection("rider_locations")
				.doc(active && active.rider && active.rider.id)
				.onSnapshot(
					(items) => {
						setActiveRiderPos(items.data());
					},
					(err) => {
						console.log(err);
					}
				);
		} else {
			setActiveRiderPos(null);
		}

		return active;
	};

	const formSubmit = async () => {
		const { cancelOrders } = homeStore;
		setSubmitting(true);

		await cancelOrders(
			alert,
			{
				order_code: details && details.order_code,
				cancelled_note: cancellationNote,
			},
			history
		);
		setSubmitting(false);

		setShowModal(false);
	};

	const completeTrip = async () => {
		const { completeOrderOnly } = searchStore;

		setSubmitting(true);

		let body =
			details?.order_type?.name == "WALLET" ||
			details?.order_type?.name == "CARD"
				? { order_id: details?.id }
				: details?.order_type?.name == "CASH"
				? {
						order_id: details?.id,
						amount_paid: details?.calculated_order?.total_amount,
						bank_transfer: details?.bank_transfer,
				  }
				: null;
		await completeOrderOnly(alert, body, history);
		setSubmitting(false);
		setShowModal(false);
	};
	const updateOrderAmt = async () => {
		const { updateOrderAmount } = homeStore;
		if (!newAmount) {
			alert.show("Please enter new amount!", {
				type: "info",
			});
		} else {
			setSubmitting(true);

			let body = { order_code: path, new_amount: newAmount };

			await updateOrderAmount(alert, body);
			setSubmitting(false);
			setShowPromptAlt(false);
		}
	};

	// Reducer function to compute total
	const getTotal = (items) => {
		let total = items.reduce(function (acc, curr) {
			return acc + curr.quantity;
		}, 0);
		return total;
	};

	// To calculate the total number of items bought
	const getItemsTotal = (orders) => {
		let i;
		let tot = [];
		for (i = 0; i < orders.length; i++) {
			tot.push(getTotal(orders[i].meals));
		}
		let allTotals = tot.reduce((acc, curr) => {
			return acc + curr;
		}, 0);

		return allTotals;
	};
	const paid = details?.paid;
	const orderDetails = details?.order_details;

	const totalAmt = details?.order_details?.use_wallet
		? parseInt(details?.calculated_order?.total_amount) -
		  parseInt(details?.order_details?.wallet_amount)
		: parseInt(details?.calculated_order?.total_amount);

	const walletAmount =
		typeof orderDetails === "string"
			? JSON.parse(orderDetails)?.wallet_amount
			: typeof orderDetails === "object"
			? orderDetails?.wallet_amount
			: 0;

	// calculated total for single order
	const calculatedTotal = parseInt(details?.calculated_order?.total_amount);

	const serviceTotal = parseInt(details?.calculated_order?.service_charge);

	const deliveryFee = parseInt(
		(details?.calculated_order?.free_delivery && "0") ||
			details?.calculated_order?.delivery_fee
	);

	const amountDue =
		!paid && walletAmount && orderDetails?.use_wallet
			? calculatedTotal - parseInt(walletAmount)
			: !paid && !orderDetails?.use_wallet
			? calculatedTotal - 0
			: 0;

	//  serviceTotal + deliveryFee
	const extraCharges = serviceTotal + deliveryFee;

	const subTotal = calculatedTotal - extraCharges;
	//if order is a group order
	const isGroupOrder = details?.calculated_order?.is_group_order ? true : false;
	const subOrders = details?.calculated_order?.sub_calculated_order_ids || null;

	// calculated total for group order
	const calculatedGroupTotal = () => {
		let alltotal = subOrders?.reduce((acc, curr) => {
			return acc + (parseFloat(curr.total_amount) - extraCharges);
		}, 0);
		return alltotal + subTotal;
	};
	const amountDueGroup =
		!paid && walletAmount && orderDetails?.use_wallet
			? calculatedGroupTotal() - parseInt(walletAmount)
			: !paid && !orderDetails?.use_wallet
			? calculatedGroupTotal() - 0
			: 0;
	const groupCount =
		details?.calculated_order?.sub_calculated_order_ids?.length + 1;
	return (
		<main className="">
			<div className="">
				<Header />
				<section className="ongoing_trip">
					<div className="ongoing_trip_content ">
						<button
							className=" cursor_pointer mb_35"
							onClick={() => history.push(LIVE_TRIPS)}
						>
							<ArrowBack width="17px" height="17px" fill="#FF0000" />
						</button>
						<div>
							{details?.first_order ? (
								<img
									src={NewIcon}
									className="mb_5"
									alt="new"
									style={{ width: 60, height: 30 }}
								/>
							) : null}
						</div>
						<div className="ongoing_trip_header ">
							<div className="ongoing_trip_header_left">
								<div className="ongoing_flex_col">
									<h6 className=" path_text">
										Order <span className="uppercase ">#{path}</span>{" "}
									</h6>
									<div className="">
										{details?.kitchen_dispatched_time ? (
											<label className="ongoing_flex_col time_flex ">
												<p className="text_base">
													Order created at:{" "}
													<span>
														{moment(details?.created_at).format("LT")} |{" "}
														{moment(details?.created_at).format("DD MMM YYYY")}
													</span>
												</p>

												<p className="text_base">
													Order dispatched at:{" "}
													<span>
														{moment(details?.kitchen_dispatched_time).format(
															"LT"
														)}{" "}
														|{" "}
														{moment(details?.kitchen_dispatched_time).format(
															"DD MMM YYYY"
														)}
													</span>
												</p>
											</label>
										) : (
											<label className="gray normal_14px">
												Not dispatched by kitchen yet
											</label>
										)}
									</div>

									<div className="flex_column_left ongoing_flex_col">
										<div className="flex_row_align_center">
											<span className="normal_53px green_text mr_10">•</span>
											<p className="text_base">
												From: <span className="bold">CoKitchen Lekki</span>
											</p>
										</div>

										<div className="flex_row_align_center">
											<span className="normal_53px red_text mr_10">•</span>
											<a
												className="text_base gray text_wrap"
												href={`https://www.google.com/maps/search/?api=1&query=${details?.calculated_order?.lat},${details?.calculated_order?.lng}`}
												target="_blank"
											>
												To:{" "}
												<span className=" ">
													{
														details?.calculated_order?.address_details
															?.address_line
													}
												</span>
											</a>
										</div>
									</div>
									{/* top middle */}
									<div className="flex_column_left  ongoing_flex_col">
										<div className="flex_row_align_center mb_15">
											<span className="mr_10">
												<Clock
													fill=" rgba(0, 0, 0, 0.214)"
													width="7px"
													height="7px"
												/>
											</span>
											<p
												className={`text_base bold ${
													details && details.kitchen_dispatched
														? "green_text"
														: "gray"
												}`}
											>
												{details && details.kitchen_dispatched
													? "on-going"
													: "pending"}
											</p>
										</div>

										<div className="flex_row_align_center">
											<span className="mr_10">
												<GiStabbedNote
													color="rgba(0, 0, 0, 0.214)"
													size="7px"
												/>
											</span>
											<p className="text_base">
												{orderDetails?.rider_note || "___"}
											</p>
										</div>
									</div>
								</div>
							</div>

							<div>
								<div className="ongoing_flex_col order_details text_base">
									<div className="">
										<p className="title">
											{(isGroupOrder && "Group order details") ||
												"Order details"}
											:{" "}
											<span className="text_base ">
												for {groupCount}{" "}
												{groupCount === 1 ? "Person" : "People"}
											</span>{" "}
										</p>
									</div>
									<div className="ongoing_flex_col">
										<label className="">
											{(details?.calculated_order?.meals?.length > 1 &&
												details.calculated_order.meals.length + " brands") ||
												details?.calculated_order?.meals?.length + " brand"}
											,{" "}
											{`${
												details?.calculated_order?.meals &&
												getItemsTotal(details?.calculated_order?.meals)
											} ${
												details?.calculated_order?.meals &&
												getItemsTotal(details?.calculated_order?.meals) === 1
													? "item"
													: "items"
											}`}
										</label>
										<label className="">
											Amount paid by wallet: ₦
											{walletAmount ? numberWithCommas(walletAmount) : 0}
										</label>
										<label className="">
											Order total: ₦{" "}
											{isGroupOrder
												? numberWithCommas(totalAmt)
												: numberWithCommas(totalAmt)}
										</label>
										{!paid ? (
											<div className="d-justify-start w-100 mb-2">
												<p className="">Amount due:</p>
												{isGroupOrder ? (
													<p className="bold">
														₦
														{numberWithCommas(
															(amountDueGroup &&
																amountDueGroup + extraCharges) ||
																0
														)}
													</p>
												) : (
													<p className=" mb-1 bold">
														₦{numberWithCommas((amountDue && amountDue) || 0)}
														.00
													</p>
												)}
											</div>
										) : null}
										<label className="bold green_text">
											{details?.order_type?.name}
										</label>
										<label className="">__</label>
									</div>
								</div>
							</div>

							<div className="ongoing_flex_col text_base ongoing_trip_header_right">
								<div className="mb_35">
									<p className="title">
										Delivery Fee: ₦
										{details?.calculated_order?.delivery_fee &&
											numberWithCommas(
												parseInt(details.calculated_order.delivery_fee)
											)}
									</p>

									<p className=" ">
										Total trip time: 12mins <br />
									</p>

									<p className=" ">Total trip distance: 2km</p>
								</div>

								<div className="text_base ">
									<p className="title">Rider Details</p>
									<p className=" ">
										<span className=" ">First Name:</span>{" "}
										{details?.rider?.first_name}
									</p>
									<p className=" ">
										<span className=" ">Last Name:</span>{" "}
										{details?.rider?.last_name}
									</p>
									<p className=" ">
										<span className=" ">Email:</span> {details?.rider?.email}
									</p>
									<p className=" ">
										<span className=" ">Phone Number:</span>{" "}
										{details?.rider?.phone_number}
									</p>
								</div>
							</div>
						</div>

						<section className="d-lg-justify-evenly people">
							<section>
								{/* Order detail */}
								<div className="order_detail_table flex_column_left">
									{/* host order */}
									<div
										className={`py-2 d-flex d-justify-between d-align-center active-accordion-head`}
									>
										<p
											className="text_base bold blue_text pointer"
											style={{ width: "40%" }}
											onClick={() => {
												setCurrentOrder(details);
												setShowDetails(true);
											}}
										>
											1.{" "}
											{details?.calculated_order?.user?.first_name +
												" " +
												details?.calculated_order?.user?.last_name}{" "}
											{details?.first_order ? (
												<img
													src={NewIcon}
													className="ml-1"
													alt="new"
													style={{ width: 60, height: 30 }}
												/>
											) : null}
										</p>
										<p className={`bold_21px text-left`}>
											{` ${details?.calculated_order?.meals?.length} ${
												details?.calculated_order?.meals?.length === 1
													? "brand"
													: "brands"
											}`}
											,
											{` ${
												details?.calculated_order?.meals &&
												getItemsTotal(details?.calculated_order?.meals)
											} ${
												details?.calculated_order?.meals &&
												getItemsTotal(details?.calculated_order?.meals) === 1
													? "item"
													: "items"
											}`}
										</p>
									</div>

									{details?.calculated_order?.meals
										.filter((item) => item.brand.item_type === "FOOD")
										.map((item, i) => {
											return (
												<div>
													<p className="bold_21px mb_5">Food</p>
													<OrderCard
														title={item && item.brand && item.brand.name}
														items={item && item.meals}
														key={"item" + i}
													/>
												</div>
											);
										})}
									{details?.calculated_order?.meals
										.filter((item) => item.brand.item_type === "SHOP")
										.map((item, i) => {
											return (
												<div>
													<h3 className="bold_21px mb_5">Shop</h3>
													<OrderCard
														title={item && item.brand && item.brand.name}
														items={item && item.meals}
														key={"item" + i}
													/>
												</div>
											);
										})}

									{/* Sub orders start */}
									{subOrders?.map((item, i) => {
										return (
											<CardAccordion
												item={item}
												i={i}
												getItemsTotal={() => getItemsTotal(item?.meals)}
												setCurrentOrder={(e) => setCurrentOrder(e)}
												setShowDetails={(e) => setShowDetails(e)}
												body={
													item?.meals &&
													item?.meals?.map((itms, ind) => {
														return (
															<div
																className="order_card order_card_accordion flex-column_left mb-2 w-100"
																key={ind}
															>
																<div className="order_card_title mb_15 d-justify-left d-align-center p_20 w-100">
																	<label className="normal_17px bold">
																		{itms && itms.brand && itms.brand.name}
																	</label>
																</div>

																<div className="order_card_body px-1 w-100">
																	{itms &&
																		itms.meals.map((item, i) => {
																			return (
																				<div
																					key={i + "items"}
																					className="d-justify-start d-align-start order_card_item py-2"
																				>
																					<div className="flex-column d-align-start d-justify-start">
																						{item && item.order_note && (
																							<span className="normal_17px bold mr-2">
																								Note:{" "}
																							</span>
																						)}
																						<h6 className="normal_17px bold text-danger mr-2">
																							{item && item.quantity + "×"}
																						</h6>
																					</div>
																					<div className="flex-column d-align-start d-justify-start">
																						<label className="normal_14px text-dark mb-1">
																							{item && item.order_note}
																						</label>

																						<p className="normal_17px mb-1 ">
																							{item && item.name}
																						</p>
																						{item &&
																							item.addons &&
																							item.addons.map((itm, ind) => {
																								return (
																									<label
																										className="normal_14px text-black mt-1"
																										key={ind + "itm"}
																									>
																										{itm &&
																											itm.meal_data &&
																											itm.meal_data.name}
																										<span className="red_text">
																											{" "}
																											x {parseInt(itm.quantity)}
																										</span>
																									</label>
																								);
																							})}
																					</div>
																				</div>
																			);
																		})}
																</div>
															</div>
														);
													})
												}
											/>
										);
									})}
									{/* sub orders end */}
								</div>
							</section>

							<section>
								<section className="right_section_sec map_section ">
									<div className="flex_row_between map_label mt_35_mobile">
										<p className="mb_25  bold_21px">Trip route </p>
									</div>
									<Map
										className="app_map app_map_detail"
										userLat={
											details &&
											details.calculated_order &&
											details.calculated_order.lat &&
											parseFloat(details.calculated_order.lat)
										}
										userLng={
											details &&
											details.calculated_order &&
											details.calculated_order.lng &&
											parseFloat(details.calculated_order.lng)
										}
										lat={details && details?.calculated_order?.cokitchen?.lat}
										lng={details && details?.calculated_order?.cokitchen?.lng}
										riderLat={
											activeRiderPos &&
											activeRiderPos.lat &&
											parseFloat(activeRiderPos.lat)
										}
										riderLng={
											activeRiderPos &&
											activeRiderPos.lat &&
											parseFloat(activeRiderPos.lng)
										}
										dispatched={details && details.kitchen_dispatched}
									/>

									<div className="p_20 mt_35 card flex_column_start mb_25 width_full">
										<p className="mb_30 bold_21px">User information </p>
										<div className="flex_row_between tab_column width_full">
											<p className="normal_15px bold mb_15">
												Name:{" "}
												{details?.calculated_order?.user?.first_name +
													" " +
													details?.calculated_order?.user?.last_name}
											</p>
											<p className="normal_15px bold mb_15">
												Email: {details?.calculated_order?.user?.email}
											</p>
										</div>
										<div className="flex_row_between tab_column width_full mb_35">
											<p className="normal_15px bold mb_15">
												Phone: {details?.calculated_order?.user?.phone_number}
											</p>
											<p className="normal_15px bold mb_15">
												user since:{" "}
												{details?.calculated_order?.user?.created_at &&
													moment(
														details.calculated_order.user.created_at
													).format("DD/MM/YYYY")}
											</p>
										</div>
										
									</div>
									<div className="width_full flex_column_start  mb-25">
										<Button
											className="red_button_thick width_full mb_12"
											text="Cancel trip"
											onClick={() => setShowModal(true)}
										/>

										<Button
											className="green_button width_full mb_12"
											text="Complete trip"
											onClick={() => setShowPrompt(true)}
										/>
										<Button
											className="black_button width_full"
											text="Update order amount"
											onClick={() => setShowPromptAlt(true)}
										/>
									</div>
								</section>
							</section>
						</section>
					</div>
					<Prompt
						show={showModal}
						closeModal={() => setShowModal(!showModal)}
						title="Please state your reason for cancelling this trip"
						onSubmit={(e) => {
							e.preventDefault();
							formSubmit();
						}}
						content={
							<textarea
								className="text_area text_area_short mb_15"
								placeholder="Type cancellation reason here "
								value={cancellationNote}
								onChange={(e) => setCancellationNote(e.target.value)}
								required
							/>
						}
						saveText={submitting ? "Canceling..." : "Confirm cancellation"}
						loading={submitting}
						submitting={submitting}
						withGreen
						withRed
					/>

					<Prompt
						show={showPrompt}
						closeModal={() => setShowPrompt((prev) => !prev)}
						title={"Are you sure you want to complete this trip?"}
						onSubmit={(e) => {
							e.preventDefault();
							completeTrip();
						}}
						saveText={submitting ? "Completing..." : "Confirm completion"}
						loading={submitting}
						submitting={submitting}
						withGreen
						withRed
					/>
					<Prompt
						show={showPromptAlt}
						closeModal={() => setShowPromptAlt((prev) => !prev)}
						title={"Fill in the new order amount"}
						content={
							<div className="flex_column_start width_full">
								<IconInput
									required
									withLabel
									label="New amount"
									placeholder="Enter new amount"
									bold
									type="number"
									containerClass="mb_25 width_full"
									value={newAmount}
									onChange={(e) => setNewAmount(e)}
								/>
							</div>
						}
						onSubmit={(e) => {
							e.preventDefault();
							updateOrderAmt();
						}}
						saveText={submitting ? "Saving..." : "Save"}
						loading={submitting}
						submitting={submitting}
						withGreen
						withRed
					/>
				</section>
			</div>
			<Modal
				showModal={showDetails}
				closeModal={() => setShowDetails(!showDetails)}
				title={
					currentOrder &&
					currentOrder.order_code &&
					currentOrder.order_code.toUpperCase() + " User Information"
				}
				content={
					<div className="flex-column d-justify-center d-align-center w-100">
						<p className="mb-4 text-black bold">
							Name:{" "}
							<span className="capitalize">
								{currentOrder?.user?.phone_number
									? currentOrder?.user?.first_name +
									  " " +
									  currentOrder?.user?.last_name
									: currentOrder?.calculated_order?.user?.first_name +
									  " " +
									  currentOrder?.calculated_order?.user?.last_name}
							</span>
						</p>
						<p className="mb-4 text-black bold">
							Phone:{" "}
							{currentOrder?.user?.phone_number
								? currentOrder?.user?.phone_number
								: currentOrder?.calculated_order?.user?.phone_number}
						</p>
						<p className="mb-4 text-black bold">
							Email:{" "}
							{currentOrder?.user?.phone_number
								? currentOrder?.user?.email
								: currentOrder?.calculated_order?.user?.email}
						</p>
						<p className="mb-4 text-black bold">
							Order location:{" "}
							{currentOrder?.address_details?.address_line
								? currentOrder?.address_details?.address_line
								: currentOrder?.calculated_order?.address_details?.address_line}
						</p>
						<p className="mb-1 text-black bold">
							User since:{" "}
							{moment(
								currentOrder?.user?.phone_number
									? currentOrder?.user?.created_at
									: currentOrder?.calculated_order?.user?.created_at
							).format("LLL")}
						</p>
					</div>
				}
			/>
		</main>
	);
};
export default withAlert()(MapPage);
