import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import homeStore from "../stores/superAdminStores/homeStore";
import dataService from "../services/data.service";
import { GET_LOGISTICS_URL } from "./urls";
import errorHandler from "./errorHandler";

function useInfiniteLoad(page, alert, moment) {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(false);

	const [errorEncountered, setErrorEncountered] = useState(false);
	const [list, setList] = useState(homeStore?.allTrips);
	const [todayStart, setTodayStart] = useState([]);
	const [yesterdayStart, setYesterdayStart] = useState([]);

	const sendQuery = useCallback(async () => {
		try {
			await setIsLoading(true);
			await setError(false);
			const response = await dataService.getAuthData(GET_LOGISTICS_URL + page);
			let res = response?.data?.trips?.filter((item, i) => item.completed);

			let todTrips = [];
			let yesTrips = [];

			let today = moment().format("DD-MM-YYYY");
			let yesterday = moment().add(-1, "days").format("DD-MM-YYYY");
			let dataCopy = [...list];
			let moments = await res?.map((x, i) => {
				dataCopy = [...dataCopy, x];

				return moment(x.completed_time);
			});
			const ids = list?.map((o) => o?.order_code);
			const filteredCopy = dataCopy?.filter(
				(item, index) => !ids.includes(item?.order_code, index + 1)
			);
			setList(filteredCopy);
			let starts = await [...list]?.map((x, i) => {
				if (moment(x?.completed_time).format("DD-MM-YYYY") === today) {
					todTrips.push(moment(x?.completed_time));
				} else if (
					moment(x?.completed_time).format("DD-MM-YYYY") === yesterday
				) {
					yesTrips.push(moment(x?.completed_time));
				}
			});

			let maxDate = moment.max(moments)?.format("D MMM YY");
			let minDate = moment.min(moments)?.format("D MMM YY");
			res.max_date = maxDate;
			res.min_date = minDate;

			setTodayStart(moment.max(todTrips).format("LLL"));
			setYesterdayStart(moment.max(yesTrips).format("LLL"));

			setIsLoading(false);

			setErrorEncountered(false);
		} catch (error) {
			let err = errorHandler(error, alert);
			setError(err);
			setErrorEncountered(true);
		}
	}, [page]);

	useEffect(() => {
		sendQuery();
	}, [sendQuery, page]);

	return {
		isLoading,
		error,
		list,
		todayStart,
		yesterdayStart,
		errorEncountered,
	};
}

export default useInfiniteLoad;
