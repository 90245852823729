import React, { useState, useEffect } from "react";
import moment from "moment";
import SearchStore from "../../../stores/superAdminStores/searchStore";
import RemoveFunds from "./removeFunds";
import AddFunds from "./addFunds";
import SearchButton from "../../../components/buttons/SearchButton";
import { useParams } from "react-router";
import { observer } from "mobx-react";
import CommonStore from "../../../stores/superAdminStores/commonStore";
import errorHandler from "../../../helpers/errorHandler";
import Prompt from "../../../components/modals/prompt";
const UserProfileHeader = observer((user, alert) => {
	const { id } = useParams();
  
	const { creditWallet, debitWallet, updateDelivery, updateDeliveryLoading } =
		SearchStore;
	const [form, setForm] = useState({
		email: "",
		amount: "",
		reason: "",
	});
	const [showModal, setShowModal] = useState(false);
	const [riderBlacklist, setRiderBlacklist] = useState(false);
	const [blacklistLoading, setBlacklistLoading] = useState(false);
	const [showBlacklistPrompt, setShowBlacklistPrompt] = useState(false);
	const [showBtn, setShowBtn] = useState(false);
	const [freeDelivery, setFreeDelivery] = useState(0);
	// const [updateDeliveryLoading, setUpdateDeliveryLoading] = useState(false);

	useEffect(() => {
		setForm({ ...form, email: user?.user?.email });
	}, [user]);
	useEffect(() => {
		setRiderBlacklist({rider_blacklist:user.user?.rider_blacklist})
	}, [user]);

	useEffect(() => {
		setFreeDelivery(user?.user?.free_deliveries);
	}, []);

	const onCreditWallet = async (val) => {
		await creditWallet({ ...val });
	};

const blacklistRider= async (item)=>{
	setBlacklistLoading(true)
	try {
		riderBlacklist.rider_blacklist? await CommonStore.unblacklistRiders(alert, user?.id, "Un-blacklisted Successfully"): await CommonStore.blacklistRiders(alert, user?.id, "Blacklisted Successfully")
	} catch (error) {
		errorHandler(alert, error);
	} finally {	
		riderBlacklist.rider_blacklist = !riderBlacklist.rider_blacklist
   setRiderBlacklist({...riderBlacklist, rider_blacklist:riderBlacklist.rider_blacklist})
	setBlacklistLoading(false)
	setShowBlacklistPrompt(false)
	

};}

	const onDebitWallet = async (val) => {
		await debitWallet({ ...val });
	};

	const handleDefaultDelivery = () => {
		setShowBtn(false);
		setFreeDelivery(user?.user?.free_deliveries);
	};

	const incrementDelivery = () => {
		setFreeDelivery(freeDelivery + 1);
	};

	const decrementDelivery = () => {
		setFreeDelivery(freeDelivery - 1);
	};

	const handleUpdateDelivery = async () => {
		// setUpdateDeliveryLoading(true);
		let data = {
			user_id: id,
			free_deliveries: freeDelivery,
		};
		await updateDelivery(alert, data, id);
		// setUpdateDeliveryLoading(false);
		setShowBtn(false);
	};

	let rider = "RIDER";
	let role = user?.user?.role;

	return (
		<div>
			<div className="flex flex-col  justify-center items-center sm:flex-row sm:items-center sm:justify-between flex-wrap gap-3 sm:gap-6">
				<div className="flex flex-col font-bold ">
					<span className="text-lg sm:text-xl  md:text-2xl mdLg:text-4xl mb-2 capitalize">
						{" "}
						{user?.user?.first_name + " " + user?.user?.last_name}
					</span>
					<div className="flex gap-2 items-center font-semibold   text-base sm:text-lg flex-col sm:flex-row  sm:flex-wrap my-2">
						<a
							href={`tel:${user?.user?.phone_number}`}
							className="hover:text-green-300 hover:underline"
						>
							<p>{user?.user?.phone_number}</p>
						</a>
						<p>.</p>
						<p>{user?.user?.email}</p>
						<p>.</p>
						<p>DOB:{moment(user?.user?.dob).format("DD MMM YYYY")}</p>
						<p>.</p>
						<p>{user?.user?.user_gender}</p>
					</div>
					<span
						className="text-base sm:text-lg  text-left font-medium mb-4 mt-1"
						
					>
						User since: {moment(user?.user?.created_at).format("DD MMM YYYY")}
					</span>
			
				</div>
				
				<div className="flex flex-col font-bold mb-4 gap-2">
					{role !== rider && (
						<>
							{" "}
							<h6 className="font-bold text-lg sm:text-xl">Free Deliveries:</h6>
							<div className="flex gap-2 items-center text-lg sm:text-xl mdLg:text-3xl mb-1">
								{" "}
								{!freeDelivery == 0 ? (
									<div
										onClick={() => {
											setShowModal(!showModal);
											setShowBtn(true);
										}}
										className=" cursor-pointer"
									>
										<RemoveFunds
											userEmail={user?.user?.email}
											onRemove={onDebitWallet}
											// loading={debitLoading}
											debitWallet={debitWallet}
											id={user?.id}
											onClick={decrementDelivery}
										/>
									</div>
								) : null}
								<p className=" font-bold text-xl ">{freeDelivery}</p>{" "}
								<div
									onClick={() => {
										setShowModal(!showModal);
										setShowBtn(true);
									}}
									className=" cursor-pointer"
								>
									<AddFunds
										userEmail={user?.user?.email}
										onCredit={onCreditWallet}
										creditWallet={creditWallet}
										onClick={incrementDelivery}
										id={user?.id}
									/>{" "}
								</div>
							</div>{" "}
						</>
					)}
					{
					role==="RIDER" &&
					
					<SearchButton
					onClick={() => {
						setShowBlacklistPrompt(true);	
						

					}}
					text={riderBlacklist.rider_blacklist?"Unblacklist Rider":"Blacklist Rider"}					
					isRed = {riderBlacklist.rider_blacklist === false}
					isGreen = {riderBlacklist.rider_blacklist === true }
					type= "button"
					className={"partner-btn"}
					textClass={" normal_15px"}
				/>
					}
					{showBtn ? (
						<div className="flex gap-2">
							<SearchButton
								isDisabled={updateDeliveryLoading}
								isBlack
								text={"Cancel"}
								onClick={handleDefaultDelivery}
							/>
							<SearchButton
								isDisabled={updateDeliveryLoading}
								isGreen
								text={"Save"}
								onClick={handleUpdateDelivery}
								isLoading={updateDeliveryLoading}
							/>
						</div>
					) : null}

					<div></div>
				</div>

				{/* <Button isGray text="Advanced Analytics" /> */}
			</div>
			<Prompt
				show={showBlacklistPrompt}
				closeModal={() => setShowBlacklistPrompt(!showBlacklistPrompt)}
				isLight
				title={`${riderBlacklist.rider_blacklist? "Unblacklist":"Blacklist"}  ${user?.user?.first_name} ${user?.user?.last_name}`}
				onClick={() => {
					blacklistRider()
				}}
				type={"button"}
				saveText={blacklistLoading? "Updating...":riderBlacklist.rider_blacklist? "Un-blacklist":"Blacklist"}
				loading={blacklistLoading}
				submitting={blacklistLoading}
				disabled={blacklistLoading}
				withGreen
				withRed
				
			/>
		</div>
	);
});

export default UserProfileHeader;
