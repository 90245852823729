import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GoSearch } from "react-icons/go";
import RadioGroup from "../../../components/inputs/RadioGroup";
import IconInput from "../../../components/inputs/iconInput";
import Button from "../../../components/buttons";
import SearchButton from "../../../components/buttons/SearchButton";

const SearchByKeyword = ({ onSearchClick, loading, disabled }) => {
	const [searchByKeyword, setSearchByKeyword] = useState(null);
	const [query, setQuery] = useState("");
	const emptyForm = {
		filter_type: "",
		filter_value: "",
	};
	const [form, setForm] = useState({ ...emptyForm });
	const handleChange = (prop, val) => {
		setForm({ ...form, [prop]: val?.value || val });
	};
	function onClick() {
		onSearchClick(form);
	}
	function onChange(e) {
		setForm({ ...form, filter_type: e.target.value });
		setSearchByKeyword(e.target.value);
	}

	return (
		<div className="w-full font-bold space-y-5 ">
			<IconInput
				placeholder={"Input search query & select criteria to search…"}
				onChange={(val) => handleChange("filter_value", val)}
				value={form.filter_value}
			/>
			<p className="text-center font-bold md:text-left">Search with…</p>
			<div className=" ">
				<RadioGroup
					name="keyword"
					selectedOption={searchByKeyword}
					options={[
						{ title: "Full name", value: "full_name" },
						{ title: "Name", value: "name" },
						{ title: "Phone number", value: "phone_number" },
						{ title: "Email", value: "email" },
						{ title: "Username", value: "username" },
						{ title: "Order number", value: "order_code" },
						{ title: "Rider name", value: "rider_name" },
					]}
					onChange={onChange}
					className=" font-bold"
				/>
			</div>
			<div className="w-fit mx-auto pt-2">
				<SearchButton
					icon={<GoSearch />}
					text="Search"
					textClass="text-base"
					onClick={onClick}
					isLoading={loading}
					isDisabled={!(form.filter_type && form.filter_value.length > 1)}
				/>
			</div>
		</div>
	);
};

SearchByKeyword.propTypes = {
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	onSearchClick: PropTypes.func,
};
export default SearchByKeyword;
