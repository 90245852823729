import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import AlertCard from "../../components/card/notificationsCard";
import { withAlert } from "react-alert";
import db from "../../services/firebase.config";
import moment from "moment";
import Loader from "react-loader-spinner";
import "./styles.scss";
import CommonStore from "../../stores/superAdminStores/commonStore";
import homeStore from "../../stores/superAdminStores/homeStore";
import IconInput from "../../components/inputs/iconInput";
import Button from "../../components/buttons";
import Modal from "../../components/modals";
import { IconButton } from "../../components/buttons";
const Notifications = ({ alert }) => {
  const partners = [{}, {}, {}];
  const [cokitchens, setCokitchens] = useState([]);
  const [blacklistForm, setBlacklistForm] = useState([]);
  const [blacklistSetting, setBlacklistSettings] = useState({});
  const [trips, setTrips] = useState([]);
  const [activeTrip, setActiveTrip] = useState("");
  const [activePartner, setActivePartner] = useState("");
  const [location, setLocation] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blackListLoading, setBlacklistLoading] = useState(false)
  
  const [updateBlackListLoading, setUpdateBlacklistLoading] = useState(false)

  // load data from store
  useEffect(async () => {
    // if (!homeStore.dataCached) {
    await loadData();
    //  }
    setLoading(false);
  }, []);

  // load data from store
  const loadData = async () => {
    setLoading(true);
    let ongoing = [];
    homeStore.updateOngoingTrips([]);
    const response = db.collection("tracking_orders").onSnapshot(
      (items) => {
        ongoing = [];
        items.forEach((doc, i, arr) => {
          if (doc.data().completed) {
            ongoing.push(doc.data());
          }
        });
        setTrips(ongoing);
        homeStore.updateOngoingTrips(ongoing);
      },
      (err) => {
        // console.log(err);
      }
    );
	setBlacklistLoading(true)
    const data = await Promise.all([
      CommonStore.getCokitchens(alert),
      CommonStore.getBlacklistSettings(),
    ]);

    setBlacklistForm(CommonStore.blacklistSetting);
	setBlacklistLoading(false)
    setLoading(false);
    setCokitchens(CommonStore.cokitchens);
  };
  const updateBlacklistSetting = async () => {
    const { updateBlacklistSettings } = CommonStore;
    setUpdateBlacklistLoading(true);
    let payload = {
      max_average_delivery_time: blacklistForm?.max_average_delivery_time,
      min_average_delivery_time: blacklistForm?.min_average_delivery_time,
      no_of_deliveries: blacklistForm?.no_of_deliveries,
    };
 await updateBlacklistSettings(alert, payload, "Blacklist Setting Updated Successfully");
 setUpdateBlacklistLoading(false)
 setShowModal(false)
  };

  let active = activePartner && activePartner;
  return (
    <Layout
      locationChange={setLocation}
      notifications="active_nav_item"
      withHistoryBtn
      title={<h6 className="bold_24px mr_30">Notifications & Alerts!</h6>}
    >
      <section className="main_section  content_section flex_column_start">
        <section className="flex_row_top tab_column mb_45 width_full">
          <section className="left_section width_full">
            {/* Left */}
            {!loading && trips.length === 0 ? (
              <div className="gray_label bold_24px mb_35">
                Notifications are empty
              </div>
            ) : null}
            {trips.map((item, i) => {
              return (
                <AlertCard
                  key={i + "card"}
                  name={item.name}
                  pathType="notification"
                  time={
                    item &&
                    item.order_type &&
                    item.order_type.created_at &&
                    moment(item.order_type.created_at).format("LT")
                  }
                  date={
                    item &&
                    item.order_type &&
                    item.order_type.created_at &&
                    moment(item.order_type.created_at).format("DD MMM YYYY")
                  }
                  id={item && item.order_code}
                  from={"CoKitchen"}
                  address={
                    item &&
                    item.calculated_order &&
                    item.calculated_order.address_details &&
                    item.calculated_order.address_details.address_line
                  }
                  price={
                    item &&
                    item.calculated_order &&
                    item.calculated_order.delivery_fee
                  }
                  toDestination={item.g}
                  logistics={item.g}
                  rider={
                    (item &&
                      item.rider &&
                      item.rider.first_name +
                        " " +
                        item.rider.last_name +
                        " (Rider)") ||
                    "No rider yet"
                  }
                />
              );
            })}
			 <div className="flex justify-end">
			 <IconButton
														text="Update Blacklist Setting"
														className={`mr_25`}
														isBlack
														onClick={()=>{setShowModal(true)}}
														isGreen
													    isDisabled={blackListLoading}
													/>
         
        </div>
          </section>
		  
        </section>

        {/* Table end */}
		<Modal
				showModal={showModal}
				closeModal={() => setShowModal(!showModal)}
				title={"Update blacklist settings"}
				content={
					<div className="w-full">
						 <div className="w-64">
            <IconInput
			 loading={blackListLoading}
              required
              withLabel
              label="Number of rides"
              placeholder="Enter number of rides"
              value={blacklistForm.no_of_deliveries}
              onChange={(e) =>
                setBlacklistForm({ ...blacklistForm, no_of_deliveries: e })
              }
			
              type="number"
              containerClass="mb_25"
            />
            <IconInput
			 loading={blackListLoading}
              required
              withLabel
              label="Minimum delivery time (mins)"
              placeholder="Minimum delivery time (mins)"
              value={blacklistForm?.min_average_delivery_time}
              onChange={(e) =>
                setBlacklistForm({
                  ...blacklistForm,
                  min_average_delivery_time: e,
                })
              }
              type="number"
              containerClass="mb_25"
            />
            <IconInput
              required
              withLabel
			  loading={blackListLoading}
              label="Maximum delivery time (mins)"
              placeholder="Maximum delivery time (mins)"
              value={blacklistForm.max_average_delivery_time}
              onChange={(e) =>
                setBlacklistForm({
                  ...blacklistForm,
                  max_average_delivery_time: e,
                })
              }
              type="number"
              containerClass="mb_25"
            />
   
          </div>
					</div>
				}
				onSubmit={(e) => {e.preventDefault();updateBlacklistSetting()}}
				saveText={updateBlackListLoading?"Updating...":"Update Blacklist Setting"}
				submitting={updateBlackListLoading}
			/>
      </section>
    </Layout>
  );
};

export default withAlert()(Notifications);
