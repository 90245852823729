import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import Button from "../../../components/buttons";
import Input from "../../../components/inputs";
import Modal from "../../../components/modals";
import { AiOutlineMinus } from "react-icons/ai";

const RemoveFunds = ({
	userEmail,
	onRemove,
	loading,
	debitWallet,
	id,
	onClick,
}) => {
	const [form, setForm] = useState({
		email: "",
		amount: "",
		reason: "",
	});
	const [showModal, setShowModal] = useState(false);
	useEffect(() => {
		setForm({ ...form, email: userEmail });
	}, []);

	const handleChange = (prop, val) => {
		setForm({ ...form, [prop]: val?.value || val });
	};
	// function onRemoveWallet() {
	// 	onRemove(form);
	// 	setForm({
	// 		email: "",
	// 		amount: "",
	// 		reason: "",
	// 	});
	// 	setShowModal(!showModal);
	// }

	const onRemoveWallet = async () => {
		await debitWallet(form, id);
		setForm({
			email: "",
			amount: "",
			reason: "",
		});
		setShowModal(!showModal);
	};

	return (
		<div>
			<AiOutlineMinus size={20} color="#F3564D" onClick={onClick} />{" "}
		</div>
	);
};
RemoveFunds.propTypes = {
	onCreditWallet: PropTypes.func,
};

export default RemoveFunds;
