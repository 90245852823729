import React, { Fragment, useRef } from "react";
import { useState } from "react";
import ReactToPrint from "react-to-print";
import SearchButton from "../../../components/buttons/SearchButton";
import OrderReceipt from "./OrderReceipt";

const OrderButtons = ({
	onCancelClick,
	onCompleteClick,
	updateOrderAmount,
	orderData,
	showButtons,
	changeAddress,
}) => {
	let receiptRef = useRef(null);
	const [print, setPrint] = useState(false);
	return (
		<div className="flex flex-wrap gap-4 mt-6 ">
			<ReactToPrint
				trigger={() => (
					<SearchButton
						isGray
						text="Print Docket"
						onClick={() => setPrint(!print)}
					/>
				)}
				content={() => receiptRef}
			/>

			<div className="receipt py-4" ref={(el) => (receiptRef = el)}>
				<OrderReceipt orderData={orderData} />
			</div>

			{showButtons && (
				<Fragment>
					<SearchButton isRed text="Cancel Trip" onClick={onCancelClick} />
					<SearchButton
						isGreen
						text="Complete Trip"
						onClick={onCompleteClick}
					/>
					<SearchButton
						isBlack
						text="Update Order Amount"
						onClick={updateOrderAmount}
					/>
					<SearchButton
						isYellow
						text="Change Address"
						onClick={changeAddress}
					/>
				</Fragment>
			)}
		</div>
	);
};

export default OrderButtons;
