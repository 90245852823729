const  reasons=[
    {value:"1",  time:"1",   label:"No surge", alt:""},
    {value:"2",  time:"1.1", label:"Rider shortage/ minor rain fall", alt:"delivery-surge"},
    {value:"3", time:"1.2", label:"Mid-high traffic levels", alt:"delivery-surge"},
    {value:"4", time:"1.25", label:"Heavy rain", alt:"heavy-rain"},
    {value:"5", time:"1.25", label:"Election days", alt:"delivery-surge"},
    {value:"6", time:"1.3", label:"Heavy/ abnormal traffic", alt:"heavy-traffic"},
    {value:"7", time:"1.5", label:"Force majure, fuel scarcity, natural events, etc.", alt:"heavy-traffic"},
]
let surgeReasons= reasons?.map(item=>{return({...item, label:`${item?.label} - ${item?.time}x`})})

export default surgeReasons