// Super Admin
export const CREATE_PARTNERS_URL =
	"logistics/super-admin/register-all-logistics";
export const GET_PARTNERS_URL = "logistics/super-admin/delivery-partner";
export const UPDATE_PAYMENTS_URL = "logistics/super-admin/delivery-partner/pay";
export const DELETE_PARTNER_URL = "logistics/super-admin/logistics-company/";
export const PAYMENT_HISTORY_URL = "logistics/super-admin/transactions";
export const REASSIGN_RIDER_URL = "logistics/super-admin/order/rider/change";
export const UNASSIGN_RIDER_URL = "logistics/super-admin/order/rider/unassign";
export const DELETE_ACCOUNT_URL = "user/";

// Cokitchens
export const GET_COKITCHENS_URL = "internal/cokitchen";

// Logistics
export const GET_LOGISTICS_URL = "logistics/";
export const GET_ALL_ORDERS_URL = "logistics/order-stats-csv";
export const SEARCH_ORDER_URL = "logistics/super-admin/order/search/";
export const SWITCH_ORDER_URL = "logistics/order/delivery-type/switch";
export const ASSIST_RIDER_URL = "/logistics/order/rider/assist";

// Orders
// orderPage
export const GET_ORDER_BY_ID = "order/one/";

export const CANCEL_ORDER_URL = "logistics/super-admin/order/cancel";
export const COMPLETE_ORDER_URL = "logistics/order/complete";
export const UPDATE_ORDER_AMOUNT_URL = "order/amount/update";
// Users
export const GET_USERS_URL = "marketing/user";
export const GET_BLOCKED_USERS_URL = "logistics/users/blocked";
export const UNBLOCK_USER_URL = "logistics/user/unblock";
export const BLOCK_USER_URL = "logistics/user/block";
// Users owe
export const USER_OWE_URL = "admin/user/owe";

// Wallet
export const ADD_TO_WALLET_URL = "user/wallet/add";
export const CREATE_PAYSTACK_ORDER_URL =
	"logistics/super-admin/user/order/create";
export const SUBTRACT_FROM_WALLET_URL = "user/wallet/subtract";
// Last order
export const CREATE_LAST_ORDER = "logistics/super-admin/user/order/create";
// Notification
export const CREATE_NOTIFICATION = "marketing/notification";
// Suge
export const COKITCHEN_DELIVERY_SURGE_URL = "marketing/surge";

// search
export const GET_USER_BY_ID = "user/one/";
export const GET_SEARCH_RESULT_BY_KEYWORD = "cs/search/keyword/";
export const GET_SEARCH_RESULT_BY_DATE = "cs/search/date/";
export const GET_USER_STATS = "user/one/stats/order/";
export const GET_RIDER_STATS = "rider/one/stats/order/";
export const GET_USER_BRANDS = "user/one/brands/";
export const GET_USER_MEALS = "user/one/meals/";
export const GET_USER_ORDERS = "user/one/orders/";
export const GET_RIDER_ORDERS = "rider/one/orders/";
export const UPDATE_DELIVERY = "logistics/user/update-free-deliveries";

// Top Ordered Polygon
export const GET_TOP_ORDERED_POLYGON = "stats/polygon/user/one/";
export const GET_RIDER_TOP_ORDERED_POLYGON = "stats/polygon/rider/one/";

// CHANGE ADDRESS
export const GET_COKITCHEN_POLYGON_ID = "internal/location";

export const CHANGE_ADDRESS = "logistics/super-admin/order/address/change";

// user's wallet
export const CREDIT_WALLET = "user/wallet/add";
export const DEBIT_WALLET = "user/wallet/subtract";

export const UPDATE_BLACKLIST_SETTING = "logistics/blacklist-settings"
export const GET_BLACKLIST_SETTING = "logistics/riders/blacklist-settings"


