import React from "react";
import ReactPaginate from "react-paginate";
import "./styles.scss";
const Paginate = ({
	pageCount,
	currentPageData,
	setCurrentPage,
	containerClass,
}) => {
	function handlePageClick({ selected: selectedPage }) {
		setCurrentPage(selectedPage);
	}
	return (
		<div className={`${containerClass} `}>
			{currentPageData}
			{pageCount === 0 ? null : (
				<div className="pagination_container center ">
					<ReactPaginate
						previousLabel={"<"}
						nextLabel={">"}
						pageCount={pageCount}
						breakLabel={<a href="">...</a>}
						marginPagesDisplayed={2}
						onPageChange={handlePageClick}
						//pageRangeDisplayed={10}
						containerClassName="pagination "
						previousLinkClassName={"prev_link"}
						nextLinkClassName={"next_link"}
						disabledClassName={"pagination__link--disabled"}
						activeClassName={"pagination__link--active "}
					/>
				</div>
			)}
		</div>
	);
};

export default Paginate;
