import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router";
import Layout from "../../../components/layout";
import Paginate from "../../../components/pagination";
import { numberWithCommas } from "../../../helpers/commaSeparator";
import SearchStore from "../../../stores/superAdminStores/searchStore";
import { BiArrowBack } from "react-icons/bi";
import { ThreeDots } from "react-loader-spinner/dist/loader/ThreeDots";
import { withAlert } from "react-alert";
import { SEARCH, SEARCH_RESULT } from "../../../router/router";
import "./styles.scss";

const SearchResult = observer(({ alert }) => {
	const [userResults, setUserResults] = useState([]);
	const history = useHistory();
	const { query, value, date } = useParams();
	const { loading, users, searchByKeyword, searchByDate } = SearchStore;
	const [firstLoading, setFirstPageLoading] = useState(false);
	useEffect(() => {
		setUserResults(users?.data);
	}, [users]);

	useEffect(() => {
		loadPage();
	}, []);

	useEffect(() => {
		localStorage.setItem("query", query);
		localStorage.setItem("value", value);
		localStorage.setItem("date", date);
	}, [query, value, date]);

	const loadPage = async () => {
		setFirstPageLoading(true);

		// const val = location.state.state;
		let count = 1;
		if (date) {
			let val = {
				end_date_range: query,
				filter_type: value,
				start_date_range: date,
			};
			await searchByDate(alert, { ...val }, count);
			localStorage.setItem("type", "date");

			setFirstPageLoading(false);
		} else {
			let val = {
				filter_type: value,
				filter_value: query,
			};
			await searchByKeyword(alert, val, count);
			setFirstPageLoading(false);
			localStorage.setItem("type", "keyword");
		}
	};

	function onUserClick(item) {
		history.push(`/search/profile/${item.id}`);
	}

	function setOrderPage(item) {
		history.push(`/search/order/${item.id}`);
	}

	const changePage = async (selectedPage) => {
		// const val = location.state.state;
		// console.log(val);

		const count = selectedPage + 1;
		if (date) {
			let val = {
				end_date_range: query,
				filter_type: value,
				start_date_range: date,
			};
			await searchByDate(alert, { ...val }, count);
			localStorage.setItem("type", "date");
		} else {
			let val = {
				filter_type: value,
				filter_value: query,
			};
			await searchByKeyword(alert, val, count);
			localStorage.setItem("type", "keyword");
		}
	};

	return (
		<Layout
			// locationChange={setLocation}
			search="active_nav_item"
			withHistoryBtn
			title={<h6 className="bold_24px mr_30">SearchLayout</h6>}
			searchLink={SEARCH_RESULT}
		>
			<div
				className="space-y-3 search_container 
				"
				// overflow-x-scroll whitespace-nowrap mdLg:w-full
				style={{
					paddingBottom: "40px",
				}}
			>
				<div className="flex items-center space-x-2 m-1">
					<BiArrowBack
						size={20}
						color="#FF0000"
						className="cursor-pointer"
						onClick={() => history.push(SEARCH)}
					/>
					<span className="text-md font-bold">User Search Engine</span>
				</div>
				{firstLoading ? (
					<div
						className="flex justify-center"
						style={{
							paddingBottom: "40px",
						}}
					>
						<ThreeDots color="#ff000060" height={10} width={50} />
					</div>
				) : (
					<Paginate
						pageCount={Math.ceil(users.total / 10)}
						setCurrentPage={changePage}
						containerClass=""
						currentPageData={
							<table className="w-full ">
								<thead className="">
									<tr>
										{userResults && !userResults[0]?.calculated_order ? (
											<>
												<th className="md:pl-6 pl-12">Name</th>
												<th className="pl_0">Date Registered</th>
												<th className="pl_0">Gender/Role</th>
												<th className="pl_0">Contact</th>
												<th className="pl_0">Wema Acct (Bani)</th>
                                                <th className="pl_0">Providus Acct (Bani)</th>
												<th className="px_0">Free Deliveries</th>
											</>
										) : (
											<>
												<th className="md:pl-6 pl-12">Name</th>
												<th className="pl_0">Order amount </th>
												<th className="pl_0">Order Code</th>
												<th className="px_0">Address</th>
												<th className="pl_0">Wema Acct (Bani)</th>
                                                <th className="pl_0">Providus Acct (Bani)</th>
												<th className="px_0">Phone Number</th>
											</>
										)}
									</tr>
								</thead>
								<tbody>
									{!loading && userResults?.length === 0 ? (
										<div>
											<h6 className="font-bold "> No Users Found ...</h6>
										</div>
									) : userResults && !userResults[0]?.calculated_order ? (
										userResults &&
										userResults.map((item, i) => {
											return (
												<tr
													key={i + "item"}
													onClick={() => onUserClick(item)}
													className="text-sm hover:bg-slate-100 cursor-pointer bg-white-100 text-black-100"
												>
													<td className="place-self-center">
														<div className="flex_column_left">
															<span className="md:pl-6 pl-12">
																{item?.first_name
																	? item?.first_name + " " + item?.last_name
																	: item?.user?.first_name +
																	  " " +
																	  item?.user?.last_name}
															</span>
														</div>
													</td>
													<td>
														<div className="flex_column_left">
															<p className="mb_4">
																{item &&
																	item.created_at &&
																	moment(item.created_at).format("LT")}
															</p>
															<span className="">
																{item &&
																	item.created_at &&
																	moment(item.created_at).format("DD MMM YYYY")}
															</span>
														</div>
													</td>
													<td>
														<div className="flex_column_left">
															<div className="items-center mb_10">
																<span className=" mb_4">
																	{item?.user_gender || item?.user?.user_gender}
																</span>
															</div>

															<div className="flex_row_align_center">
																<span className="">
																	{item?.role || item?.user?.role}
																</span>
															</div>
														</div>
													</td>
													
										

													<td>
														<div className="flex_column_left">
															<div className="flex_row_center mb_17">
																<label className="text-dark-50 mb_4">
																	{item?.phone_number}
																</label>
															</div>

															<div className="flex_row_align_center">
																<label className="text-dark-50">
																	{item?.email || item?.user?.email}
																</label>
															</div>
														</div>
													</td>

													<td>
														<div className="flex_column_left">
															<div className="items-center mb_10">
																<span className=" mb_4">
																	{item?.bani_account_number || "Not available"}
																</span>
															</div>


														</div>
													</td>
													
												
													
														<td>
														<div className="flex_column_left">
															<div className="items-center mb_10">
																<span className=" mb_4">
																	{item?.bani_account_number_providus || "Not available"}
																</span>
															</div>

						
														</div>
													</td>

													<td>
														<div className="">
															<span className="bold_21px green_text mb_17">
																{item?.free_deliveries || 0}
															</span>
														</div>
													</td>

													{item && item.calculated_order ? (
														<td>
															<div className="">
																<span className="bold_21px green_text mb_17">
																	{item?.balance ||
																		item?.calculated_order.total_amount}
																</span>
															</div>
														</td>
													) : null}
													{item && item.calculated_order ? (
														<td>
															<div className="">
																<span className="bold_21px green_text mb_17">
																	₦{item?.balance || item?.order_code}
																</span>
															</div>
														</td>
													) : null}
												</tr>
											);
										})
									) : (
										userResults &&
										userResults.map((item, i) => {
											return (
												<tr
													key={i + "item"}
													className="text-sm text-gray-700 hover:bg-slate-200"
												>
													<td className="place-self-center">
														<div className="flex_column_left">
															<span
																className="md:pl-6 pl-12 cursor-pointer hover:underline hover:font-bold hover:text-orange-600"
																onClick={() => onUserClick(item)}
															>
																{item?.first_name
																	? item?.first_name + " " + item?.last_name
																	: item?.user?.first_name +
																	  " " +
																	  item?.user?.last_name}
															</span>
														</div>
													</td>
													<td>
														<div className="flex_column_left">
															<p className="mb_4">
																{`₦${numberWithCommas(
																	(item &&
																		item.calculated_order.total_amount) ||
																		0
																)}`}
															</p>
														</div>
													</td>
													<td>
														<div className="flex_column_left">
															<div
																className={`items-center mb_10 cursor-pointer hover:underline font-bold ${
																	item?.completed === true && "text-green-100"
																} ${
																	item?.cancelled === true && "text-red-100"
																}  ${
																	item?.completed === false &&
																	item?.cancelled === false &&
																	"text-yellow-500"
																} `}
																onClick={() => setOrderPage(item)}
															>
																<p className="mb_4 uppercase">
																	{item && item.order_code}
																</p>
															</div>
														</div>
													</td>

													<td>
														<div className="flex_column_left">
															<div className="flex_row_center mb_17">
																<label className="text-dark-50 mb_4">
																	{item?.phone_number}
																</label>
															</div>

															<div className="flex_row_align_center">
																<label className="cursor-pointer hover:text-orange-600 hover:underline text-grey-backdrop">
																	{item?.email ||
																		item?.calculated_order.address_details
																			.address_line}
																</label>
															</div>
														</div>
													</td>
											
														<td>
														<div className="flex_column_left">
															<div className="items-center mb_10">
																<span className=" mb_4">
																	{item?.user?.bani_account_number || "Not available"}
																</span>
															</div>


														</div>
													</td>
													
												
													
														<td>
														<div className="flex_column_left">
															<div className="items-center mb_10">
																<span className=" mb_4">
																	{item?.user?.bani_account_number_providus || "Not available"}
																</span>
															</div>

						
														</div>
													</td>
													

													<td>
														<div className="">
															<span className="bold_21px green_text mb_17">
																{item.calculated_order &&
																	item.user?.phone_number}
															</span>
														</div>
													</td>
												</tr>
											);
										})
									)}
								</tbody>
							</table>
						}
					/>
				)}
				{!firstLoading && loading && (
					<div
						className="flex justify-center"
						style={{
							paddingBottom: "40px",
						}}
					>
						<ThreeDots color="#ff000060" height={10} width={50} />
					</div>
				)}
			</div>
		</Layout>
	);
});

export default withAlert()(SearchResult);
