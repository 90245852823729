import React from "react";
import Select from "react-select";

const AppSelect = ({
  options,
  value,
  placeholder,
  handleChange,
  isLoading,
  defaultValue,
  containerClass = "app_select",
  isMulti,
  name = "label",
  id = "value",
  isCustom,
  customNameOne,
  customNameTwo,
}) => {
  return (
    <Select
      className={`react-select-container ${containerClass}`}
      classNamePrefix="react-select"
      hideSelectedOptions="true"
      placeholder={placeholder}
      closeMenuOnSelect={isMulti ? false : true}
      isSelected={true}
      options={options}
      backspaceRemovesValue={true}
      onChange={handleChange}
      value={value}
      isLoading={isLoading}
      defaultValue={defaultValue}
      isMulti={isMulti}
      // menuIsOpen
      getOptionLabel={(option) =>
        isCustom ? option[customNameOne][customNameTwo] : option[name]
      }
      getOptionValue={(option) => option[id]}
    />
  );
};
export default AppSelect;
