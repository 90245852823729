import React, { useState } from 'react'
import Live from '../live'
import Select from '../select'
import './styles.scss'
import { CSVLink } from 'react-csv'
import CommonStore from '../../stores/superAdminStores/commonStore'
import moment from 'moment'
const Caps = ({
  children,
  tabClass,
  options,
  tripsLength,
  csvBody,
  titles,
  date,
  alert
}) => {
  const [active, setActive] = useState(0)

  const selectTab = i => {
    setActive(i)
  }

  const renderTabs = () => {
    return React.Children.map(children, (item, i) => {
      if (i % 2 === 0) {
        let activeTab = active === i ? 'active_cap_tab' : ''
        return (
          <button
            onClick={() => selectTab(i)}
            className={`cap_tab ${activeTab} cap_tab${i}`}
          >
            <h6>{item}</h6>
          </button>
        )
      }
    })
  }
  const renderContent = () => {
    return React.Children.map(children, (item, i) => {
      if (i - 1 === active) {
        return <div className='db_tab_content'>{item}</div>
      } else {
        return
      }
    })
  }
  const sendCsv = async () => {
    let startDate =moment(date.startDate).format("MM-DD-YYYY")
    let endDate =moment(date.endDate).format("MM-DD-YYYY")
    await CommonStore.getAllOrders(alert, true, {start_date: startDate, end_date: endDate});
    
  }

  return (
    <section className='bottom_left_section cap_tab_container'>
      <div className='border_bottom_thin flex_row_between'>
        <p className='bold_21px mb_15'>All Trips ({tripsLength})</p>

        <div className='caps_container_cont'>
          <div className='caps_container' />
          {renderTabs()}
        </div>
        <div className='flex-column d-justify-start d-align-end'>
          <div className='flex_row_right mb-1'>
            <Live className='mr_30' />
            <Select
              placeholder='Locations: ALL'
              //  defaultValue={{ label: "Locations: ALL", value: "all" }}
              options={options}
            />
          </div>
          <div>
          <label className="gray_label bold cursor_pointer "onClick={()=> sendCsv()}>
									Export via csv
								</label>
                </div>
           
              
            
          
        </div>
      </div>

      {renderContent()}
    </section>
  )
}
export default Caps
