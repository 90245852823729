import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import TextCard from "../../components/textCard";

import { ChevronRight, Clock, User } from "../../assets/icons/icons";
import Button from "../../components/buttons";
import { Link } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import Live from "../../components/live";
import "./styles.scss";
import Caps from "../../components/tabs/capTabs";
import { IoMdWallet } from "react-icons/io";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { FULL_TRIP_DETAILS, PARTNER_DB_S_ADMIN } from "../../router/router";
import { withAlert } from "react-alert";
import homeStore from "../../stores/superAdminStores/homeStore";
import CommonStore from "../../stores/superAdminStores/commonStore";
import Loader from "react-loader-spinner";
import moment from "moment";
import db from "../../services/firebase.config";
import { CSVLink } from "react-csv";
const HomePage = ({ alert }) => {
	const [selectionRange, setSelectionRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: "selection",
	});
	const [showDateRange, setShowDateRange] = useState(true);
	const [showDateRangeFilters, setShowDateRangeFilters] = useState(false);

	const [cokitchens, setCokitchens] = useState([]);
	const [logistics, setLogistics] = useState(null);
	const [logisticsCopy, setLogisticsCopy] = useState(null);
	const [pendingTrips, setPendingTrips] = useState([]);
	const [bikesOnline, setBikesOnline] = useState([]);
	const [bikesOffline, setBikesOffline] = useState([]);
	const [ongoingTrips, setOngoingTrips] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingAlt, setLoadingAlt] = useState(false);
	const [location, setLocation] = useState("");
	const [refreshCount, setRefreshCount] = useState(0);
	const [filterCount, setFilterCount] = useState(0);
	const [todayTrips, setTodayTrips] = useState([]);
	const [yesterdayTrips, setYesterdayTrips] = useState([]);
	const [revenueStats, setRevenueStats] = useState({
		revenue: 0,
		averageOrderValue: 0,
	});
	// const valid = (current) => {
	//   return current.isAfter(moment(startDate));
	// };
	const today = moment().format("DD-MM-YYYY");
	const yesterday = moment().add(-1, "days").format("DD-MM-YYYY");
	// load data from store
	useEffect(async () => {
		if (!homeStore.dataCached) {
			setLoading(true);
		} else {
			sortBikers();
			await sortTrips();
			await loadData();
		}
		
		setLoadingAlt(false);
		if (!homeStore.dataCached) {
			await Promise.all([
				CommonStore.getCokitchens(alert),
				homeStore.getLogistics(alert, false),
			]);
		}

		setLoading(false);
		sortBikers();
		await sortTrips();
		await loadData();
		setCokitchens(CommonStore.cokitchens);
	}, []);
	// Trigger Refresh
	useEffect(async () => {
		if (refreshCount) {
			await loadData();
			await sortTrips();
		}
	}, [refreshCount]);
	const numberWithCommas = (x) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	//csv headers
	const titles = [
		{ label: "Order Code", key: "order_code" },
		{ label: "Customer", key: "customer" },
		{ label: "Rider", key: "rider" },
		{ label: "Address", key: "address_line" },

		{ label: "Logistics Company", key: "logistics_companies" },
		{ label: "Delivery Fee (₦)", key: "delivery_fee" },
		{ label: "Date/Time", key: "date" },
	];
	// csv body
	const csvBody = CommonStore?.orders
		.sort(
			(a, b) =>
				new Date(a?.calculated_order?.created_at) -
				new Date(b?.calculated_order?.created_at)
		)
		.sort(
			(a, b) =>
				new Date(b.kitchen_dispatched_time) -
				new Date(a.kitchen_dispatched_time)
		)
		.map((item, i) => {
			let riderObj = homeStore?.logistics?.fleets?.find(
				(itm) => itm?.id === item?.rider_id
			);

			return {
				order_code: item?.order_code,
				customer:
					item?.calculated_order?.user?.first_name +
					" " +
					item?.calculated_order?.user?.last_name,
				rider: riderObj?.first_name + " " + riderObj?.last_name,
				address_line: item?.calculated_order?.address_details?.address_line,

				logistics_companies: homeStore?.logistics?.logistics_companies?.find(
					(itm) => itm?.id === item?.rider?.logistics_company_id
				)?.name,
				delivery_fee: `${
					item?.calculated_order?.delivery_fee &&
					numberWithCommas(parseInt(item?.calculated_order?.delivery_fee))
				}`,
				date: moment(item.created_at).format("LLLL"),
			};
		});
	// Filter stats by date
	useEffect(async () => {
		if (filterCount > 1) {
			let tripsCopy = CommonStore?.orders;
			let currentTrips = [];

			for (let i = 0, len = tripsCopy.length; i < len; i++) {
				if (
					moment(
						moment(tripsCopy[i].created_at).format("YYYY-MM-DD")
					).isBetween(
						moment(moment(selectionRange.startDate).subtract(1, "d")).format(
							"YYYY-MM-DD"
						),
						moment(moment(selectionRange.endDate).add(1, "d")).format(
							"YYYY-MM-DD"
						)
					)
				) {
					currentTrips.push(tripsCopy[i]);
				}
			}

			setLogistics(currentTrips);
			// Get revenue total
			const revTotal = getTotal(currentTrips) || 0;
			// Get average total
			const avrgVal = revTotal / currentTrips?.length;
			// setRevenue(getTotal(currentTrips))
			setRevenueStats({
				...revenueStats,
				revenue: revTotal,
				averageOrderValue: avrgVal,
			});
		} else {
			// Sort brand and meal orders

			const revTotal = getTotal(todayTrips) || 0;
			const avrgVal = revTotal / todayTrips?.length;
			setLogistics(todayTrips);
			setRevenueStats({
				...revenueStats,
				revenue: revTotal,
				averageOrderValue: avrgVal,
			});

			setSelectionRange({
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			});
		}
	}, [filterCount]);

	const handleSelect = (ranges) => {
		setSelectionRange(ranges.selection);
		setFilterCount(filterCount + 1);
	};
	// load data from store

	const loadData = async () => {
		setLoading(true);
		let ongoing = [];
		homeStore.updateOngoingTrips([]);
		const response = db.collection("tracking_orders").onSnapshot(
			(items) => {
				ongoing = [];
				items.forEach((doc, i, arr) => {
					ongoing.push(doc.data());
				});
				setOngoingTrips(ongoing);
				homeStore.updateOngoingTrips(ongoing);
			},
			(err) => {
				// console.log(err);
			}
		);

		sortBikers();
		setCokitchens(CommonStore.cokitchens);
		setLoading(false);
	};

	const sortBikers = () => {
		let online = [];
		let offLine = [];
		let allRiders = homeStore?.logistics?.fleets || [];
		let i;
		for (i = 0; i < allRiders.length; i++) {
			if (allRiders[i].online) {
				online.push(allRiders[i]);
			} else {
				offLine.push(allRiders[i]);
			}
		}
		setBikesOnline(online);
		setBikesOffline(offLine);
	};

	// Reducer function to compute total
	const getTotal = (items = []) => {
		let total =
			items && items[0]
				? items.reduce(function (acc, curr) {
						return acc + parseFloat(curr?.calculated_order?.delivery_fee);
				  }, 0)
				: 0;
		return total;
	};

	// Set current filter date
	const setFilterDate = () => {
		let currDate = "";
		if (
			moment(selectionRange?.startDate).format("DD-MM-YYYY") === today &&
			moment(selectionRange?.endDate).format("DD-MM-YYYY") === today
		) {
			currDate = "Today";
		} else if (
			moment(selectionRange?.startDate).format("DD-MM-YYYY") === yesterday &&
			moment(selectionRange?.endDate).format("DD-MM-YYYY") === yesterday
		) {
			currDate = "Yesterday";
		} else {
			currDate =
				moment(selectionRange?.startDate).format("D MMM 'YY") +
				" - " +
				moment(selectionRange?.endDate).format("D MMM 'YY");
		}

		return currDate;
	};
	// Sort trips into today, yesterday and all time, cash, card
	const sortTrips = async () => {
		let todTrips = [];
		let yesTrips = [];
		let allTrips = CommonStore?.orders || [];
		for (let i = 0; i < allTrips.length; i++) {
			if (moment(allTrips[i].created_at).format("DD-MM-YYYY") === today) {
				todTrips.push(allTrips[i]);
			} else if (
				moment(allTrips[i].created_at).format("DD-MM-YYYY") === yesterday
			) {
				yesTrips.push(allTrips[i]);
			}
		}

		const revTotal = (await getTotal(todTrips)) || 0;
		// const allTotal = (await getTotal(allTrips || [])) || 0;
		const avrgVal = revTotal / todTrips?.length;

		setRevenueStats({
			...revenueStats,
			revenue: revTotal,
			averageOrderValue: avrgVal,
		});

		setLogistics(todTrips);
		setTodayTrips(todTrips);
		setYesterdayTrips(yesTrips);
	};

	return (
		<Layout
			locationChange={setLocation}
			home="active_nav_item"
			options={cokitchens}
			onRefresh={() => setRefreshCount(refreshCount + 1)}
			refreshLoading={refreshCount && loading}
		>
			<section className="main_section d-lg-justify-center content_section tab_column mb_45 mt_35_mobile">
				<section className="left_section pr_25 width_full">
					<section className="top_left grid_third mb_35">
						<TextCard
							cardLabel="Total delivery revenue"
							cardValue={`₦${numberWithCommas(revenueStats?.revenue || 0)}`}
							withFooter
							cardFooter={setFilterDate()}
							loading={loadingAlt}
						/>

						<TextCard
							cardLabel="Trips"
							cardValue={logistics?.length || "0"}
							withFooter
							cardFooter={setFilterDate()}
							loading={loadingAlt}
						/>
						<TextCard
							cardLabel="Average price per trip"
							cardValue={`₦${numberWithCommas(
								parseFloat(revenueStats?.averageOrderValue || 0).toFixed(0)
							)}`}
							withFooter
							cardFooter={setFilterDate()}
							loading={loadingAlt}
						/>
						<TextCard
							cardLabel="Total bikes online"
							cardValue={(bikesOnline && bikesOnline.length) || "0"}
							link={<Live />}
							withFooter
							cardFooter="View all bikes & partners online"
							loading={loading}
						/>
						<TextCard
							cardLabel="Total on-going trips"
							cardValue={ongoingTrips?.length || "0"}
							withLink
							link={<Live />}
							withFooter
							cardFooter="View on-going trips"
							loading={loading}
						/>
					</section>
					{
						<Caps
							options={cokitchens}
							tripsLength={logistics?.pending_trips?.length}
							csvBody={csvBody}
							titles={titles}
							date={selectionRange}
							alert={alert}
						>
							Unassigned
							<table className="width_full">
								<tbody>
									{/* Loader */}
									{loading ? (
										<tr>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_center">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
										</tr>
									) : null}
									{/* Loader End */}
									{/* Row One */}
									{logistics &&
										logistics.pending_trips &&
										logistics.pending_trips
											.sort(
												(a, b) =>
													new Date(b.created_at) - new Date(a.created_at)
											)

											.map((item, i) => {
												if (item && !item.rider_assigned) {
													return (
														<tr key={i + "item" + item.id}>
															<td>
																<div className="flex_column_left">
																	<label className="gray_label mb_4">
																		{item &&
																			item.created_at &&
																			moment(item.created_at).format("LT")}
																	</label>
																	<label className="gray_label mb_4">
																		{item &&
																			item.created_at &&
																			moment(item.created_at).format(
																				"DD MMM YYYY"
																			)}
																	</label>
																	<label className="gray_label">
																		#{item && item.order_code}
																	</label>
																	<div className="flex_row_right">
																		<Link
																			to={`full-trip-details/home/${
																				item && item.order_code
																			}`}
																		>
																			<p className="mr_10 normal_15px black">
																				See full trip details
																			</p>
																		</Link>
																	</div>
																</div>
															</td>
															<td>
																<div className="flex_column_left">
																	<div className="flex_row_align_center mb_10">
																		<span className="normal_53px green_text mr_10">
																			•
																		</span>
																		<p className="normal_15px">
																			From:{" "}
																			<span className="bold">CoKitchen</span>
																		</p>
																	</div>

																	<div className="flex_row_align_center">
																		<span className="normal_53px red_text mr_10">
																			•
																		</span>
																		<p className="normal_15px">
																			To:{" "}
																			<span className="bold">
																				{item &&
																					item.calculated_order &&
																					item.calculated_order
																						.address_details &&
																					item.calculated_order.address_details
																						.address_line}
																			</span>
																		</p>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_left">
																	<div className="flex_row_align_center mb_17">
																		<IoMdWallet
																			fill=" #939393"
																			width="8px"
																			height="8px"
																			className="mr_15"
																		/>

																		<p className="normal_15px bold">
																			₦
																			{item?.calculated_order?.delivery_fee &&
																				numberWithCommas(
																					parseInt(
																						item.calculated_order.delivery_fee
																					)
																				)}
																		</p>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_right">
																	<span className="normal_15px bold mb_17">
																		{item?.kitchen_dispatched_time
																			? moment(
																					item?.kitchen_dispatched_time
																			  ).fromNow()
																			: "Not yet dispatched"}
																	</span>
																	<p className="normal_15px bold red_text">
																		Ready for pick up
																	</p>
																</div>
															</td>
														</tr>
													);
												}
											})}

									{/* Row One End */}
								</tbody>
							</table>
							Assigned
							<table className="width_full">
								<tbody>
									{/* Loader */}
									{loading ? (
										<tr>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_center">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
										</tr>
									) : null}
									{/* Loader End */}
									{/* Row One */}
									{logistics &&
										logistics.pending_trips &&
										logistics.pending_trips

											.sort(
												(a, b) =>
													new Date(b.created_at) - new Date(a.created_at)
											)
											.map((item, i) => {
												if (item && item.rider_assigned) {
													return (
														<tr key={i + "item" + item.id}>
															<td>
																<div className="flex_column_left">
																	<label className="gray_label mb_4">
																		{item &&
																			item.created_at &&
																			moment(item.created_at).format("LT")}
																	</label>
																	<label className="gray_label mb_4">
																		{item &&
																			item.created_at &&
																			moment(item.created_at).format(
																				"DD MMM YYYY"
																			)}
																	</label>
																	<label className="gray_label">
																		#{item && item.order_code}
																	</label>
																	<div className="flex_row_right">
																		<Link
																			to={`full-trip-details/home/${
																				item && item.order_code
																			}`}
																		>
																			<p className="mr_10 normal_15px black">
																				See full trip details
																			</p>
																		</Link>
																	</div>
																</div>
															</td>
															<td>
																<div className="flex_column_left">
																	<div className="flex_row_align_center mb_10">
																		<span className="normal_53px green_text mr_10">
																			•
																		</span>
																		<p className="normal_15px">
																			From:{" "}
																			<span className="bold">CoKitchen</span>
																		</p>
																	</div>

																	<div className="flex_row_align_center">
																		<span className="normal_53px red_text mr_10">
																			•
																		</span>
																		<p className="normal_15px">
																			To:{" "}
																			<span className="bold">
																				{item &&
																					item.calculated_order &&
																					item.calculated_order
																						.address_details &&
																					item.calculated_order.address_details
																						.address_line}
																			</span>
																		</p>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_left">
																	<div className="flex_row_align_center mb_17">
																		<div className="flex_row_align_center mr_20">
																			<IoMdWallet
																				fill=" #939393"
																				width="8px"
																				height="8px"
																				className="mr_15"
																			/>

																			<p className="normal_15px bold">
																				₦
																				{item?.calculated_order?.delivery_fee &&
																					numberWithCommas(
																						parseInt(
																							item.calculated_order.delivery_fee
																						)
																					)}
																			</p>
																		</div>
																		<div className="flex_row_align_center">
																			<User
																				fill="#939393"
																				width="9px"
																				height="9px"
																				className="mr_15"
																			/>

																			<p className="normal_15px">
																				{item &&
																					item.rider &&
																					item.rider.first_name +
																						" " +
																						item.rider.last_name +
																						" (Rider)"}
																			</p>
																		</div>
																	</div>

																	<div className="flex_row_align_center">
																		<HiOutlineOfficeBuilding
																			width="9px"
																			height="9px"
																			className="mr_15"
																			style={{
																				stroke: "#939393",
																			}}
																		/>

																		<p className="normal_15px">
																			Yikes Logistics Limited
																		</p>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_right">
																	<span className="normal_15px bold mb_17">
																		{item?.kitchen_dispatched_time
																			? moment(
																					item?.kitchen_dispatched_time
																			  ).fromNow()
																			: "Not yet dispatched"}
																	</span>
																	<p className="normal_15px bold red_text">
																		Ready for pick up
																	</p>
																</div>
															</td>
														</tr>
													);
												}
											})}

									{/* Row One End */}
								</tbody>
							</table>
						</Caps>
					}
				</section>

				<section className="right_section px_10 second_width_desktop">
					<div className="flex_row_right mb_25">
						<a
							href="#/"
							onClick={() => {
								setLogistics(CommonStore?.orders);

								setFilterCount((prev) => prev + 1);
								setSelectionRange(CommonStore?.ordersDateRange);
							}}
						>
							<label className="gray_label bold cursor_pointer">
								Show all time data
							</label>
						</a>
					</div>

					<div
						className="button red_border borderless_dates flex_row_center mb_30"
						onClick={() => {
							if (showDateRange) {
								return;
							} else {
								setShowDateRange(true);
							}
						}}
					>
						{showDateRange && (
							<div
								className={showDateRangeFilters ? "" : "collapsed_date_range"}
							>
								<div className="flex_row_between">
									<button
										className="cursor_pointer mb_12 date_filter_button"
										onClick={() =>
											setShowDateRangeFilters(!showDateRangeFilters)
										}
									>
										{showDateRangeFilters
											? "Hide date filters"
											: " Show date filters"}
									</button>
									{filterCount > 1 ? (
										<button
											className="cursor_pointer mb_12 date_filter_button"
											onClick={() => setFilterCount(0)}
										>
											Clear date filter
										</button>
									) : null}
								</div>
								<DateRangePicker
									ranges={[selectionRange]}
									onChange={handleSelect}
									showMonthAndYearPickers={false}
								/>
							</div>
						)}
					</div>

					{/* Summary item */}
					<div className="flex_column">
						<TextCard
							cardLabel="Total registered partners"
							cardValue={
								homeStore?.logistics?.logistics_companies?.length || "-"
							}
							withFooter
							cardFooter="View all bikes & partners"
							className="mb_17"
							loading={loading}
							to={PARTNER_DB_S_ADMIN}
						/>
						<TextCard
							cardLabel="Total Fleet"
							cardValue={homeStore?.logistics?.fleets?.length || "-"}
							withFooter
							cardFooter="View all bikes & partners"
							className="mb_17"
							loading={loading}
							to={PARTNER_DB_S_ADMIN}
						/>
					</div>
				</section>
			</section>
		</Layout>
	);
};

export default withAlert()(HomePage);
