import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import KItchenMark from "../../assets/images/kitchenMarker.png";
import UserMark from "../../assets/images/userMarker.png";
import RiderMark from "../../assets/images/riderMarker.png";

const KItchenMarker = ({ text }) => (
	<div style={{ color: "red" }}>
		<img src={KItchenMark} style={{ width: "30px", height: "43px" }} />
	</div>
);

const UserMarker = ({ text }) => (
	<div style={{ color: "red" }}>
		<img src={UserMark} style={{ width: "30px", height: "43px" }} />
	</div>
);

const RiderMarker = ({ text }) => (
	<div style={{ color: "red" }}>
		<img src={RiderMark} style={{ width: "30px", height: "45px" }} />
	</div>
);

const Map = ({
	className,
	lat = 6.448591,
	lng = 3.472095,
	userLat,
	userLng,
	riderLat,
	riderLng,
	dispatched,
}) => {
	const getMapOptions = (maps) => {
		return {
			disableDefaultUI: true,
			mapTypeControl: true,
			streetViewControl: true,
			styles: [
				{
					featureType: "poi",
					elementType: "labels",
					stylers: [{ visibility: "on" }],
				},
			],
		};
	};

	const [zoom, setZoom] = useState(11);

	return (
		<div style={{ height: "500px", width: "100%" }} className={className}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
				defaultCenter={{ lat: lat, lng: lng }}
				defaultZoom={zoom}
				options={getMapOptions}
			>
				<UserMarker lat={userLat} lng={userLng} text="Marker" />
				{dispatched ? (
					<RiderMarker lat={riderLat} lng={riderLng} text="Marker" />
				) : null}
				<KItchenMarker lat={lat} lng={lng} text="Marker" />
			</GoogleMapReact>
		</div>
	);
};

export default Map;
