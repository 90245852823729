import React from "react";
import { useParams } from "react-router";
import Button from "../../../../components/buttons";
import SearchButton from "../../../../components/buttons/SearchButton";
import Modal from "../../../../components/modals";
import SearchModal from "../../../../components/modals/SearchModal";

const CompleteTrip = ({
	completeTripModal,
	setCompleteTripModal,
	handleChange,
	form,
	completeTripLoading,
	completeOrder,
	orderData,
	setForm,
	emptyForm,
	alert,
	ongoingTrips,
	completeOrderOnly,
}) => {
	const { id } = useParams();

	const handleCompleteTrip = async () => {
		let body =
			orderData?.order_type?.name == "WALLET" ||
			orderData?.order_type?.name == "CARD"
				? { order_id: orderData?.id }
				: orderData?.order_type?.name == "CASH"
				? {
						order_id: orderData?.id,
						amount_paid: orderData?.calculated_order?.total_amount,
						bank_transfer: orderData?.bank_transfer,
				  }
				: null;

		if (ongoingTrips) {
			await completeOrderOnly(alert, body);
		} else {
			await completeOrder(alert, body, id);
		}

		setCompleteTripModal(!completeTripModal);
		setForm({
			...form,
			payment_type: "",
			amount_paid: "",
		});
	};
	return (
		<Modal
			showModal={completeTripModal}
			closeModal={() => setCompleteTripModal(!completeTripModal)}
			title=""
			content={
				<div className="flex flex-col space-y-12">
					<p className="font-bold text-lg text-center">
						{" "}
						Are You Sure You Want To Complete{" "}
						<span className="text-orange-600 uppercase">
							{" "}
							#{orderData.order_code}
						</span>{" "}
						?
					</p>

					<div className="flex justify-between gap-2 flex-wrap -z-1">
						<SearchButton
							className=""
							isGreen
							text="Go Back"
							onClick={() => setCompleteTripModal(!completeTripModal)}
						/>
						<SearchButton
							className=""
							isBlack
							text="Complete Trip"
							onClick={handleCompleteTrip}
							isLoading={completeTripLoading}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default CompleteTrip;
