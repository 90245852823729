import React from "react";
import OrderReceiptCard from "./OrderReceiptCard";

const OrderReceipt = ({ orderData }) => {
	const id =
		orderData && orderData.order_code && orderData.order_code.toUpperCase();

	const calculatedOrder = orderData?.calculated_order;
	const isGroupOrder = calculatedOrder?.is_group_order ? true : false;
	let orders = orderData?.calculated_order?.meals;
	const time = orderData?.created_at || orderData?.calculated_order?.created_at;
	let orderDetails = orderData?.order_details;
	const calculatedTotal = parseInt(calculatedOrder?.total_amount);
	const paid = orderData?.paid;
	const orderType = orderData?.order_type;

	const serviceTotal = parseInt(calculatedOrder?.service_charge);
	const deliveryFee = parseInt(
		(calculatedOrder?.free_delivery && "0") || calculatedOrder?.delivery_fee
	);

	const prevAmount = parseInt(calculatedOrder?.prev_price);
	const extraCharges = serviceTotal + deliveryFee;
	const subTotal = calculatedTotal - extraCharges;
	// Reducer function to compute total
	const getTotal = (items) => {
		let total = items.reduce(function (acc, curr) {
			return acc + curr.quantity;
		}, 0);
		return total;
	};

	// To calculate the total number of items bought
	const getItemsTotal = (ords = orders) => {
		let i;
		let tot = [];
		for (i = 0; i < ords.length; i++) {
			tot.push(getTotal(ords[i].meals));
		}
		let allTotals = tot.reduce((acc, curr) => {
			return acc + curr;
		}, 0);

		return allTotals;
	};

	// To calculate total price of each meal

	const mealTotal = (meals) => {
		let total;
		total = parseFloat(meals?.amount) * parseFloat(meals?.quantity);
		let addonTotal = meals?.addons?.reduce((acc, curr) => {
			return acc + parseFloat(curr.amount);
		}, 0);
		return total + addonTotal;
	};

	//if order is a group order
	const subOrders =
		calculatedOrder?.sub_calculated_order_ids &&
		calculatedOrder?.sub_calculated_order_ids[0]
			? calculatedOrder?.sub_calculated_order_ids
			: [];
	// calculated total for group order
	const calculatedGroupTotal = () => {
		let alltotal = subOrders?.reduce((acc, curr) => {
			return acc + (parseFloat(curr.total_amount) - extraCharges);
		}, 0);

		return subTotal;
	};

	const discountValue =
		prevAmount && !calculatedOrder?.is_group_order
			? prevAmount - calculatedTotal
			: 0;

	orderDetails =
		typeof orderDetails === "string" ? JSON.parse(orderDetails) : orderDetails;
	const walletAmount =
		typeof orderDetails === "string" && JSON.parse(orderDetails)?.wallet_amount
			? JSON.parse(orderDetails)?.wallet_amount
			: typeof orderDetails === "object" && orderDetails?.wallet_amount
			? orderDetails?.wallet_amount
			: 0;

	const isCutlery =
		typeof orderDetails === "string"
			? JSON.parse(orderDetails)?.cutlery
			: typeof orderDetails === "object"
			? orderDetails?.cutlery
			: true;

	const amountDueGroup =
		!paid && walletAmount && orderDetails?.use_wallet
			? calculatedGroupTotal() - parseInt(walletAmount)
			: !paid && !orderDetails?.use_wallet
			? calculatedGroupTotal() - 0
			: 0;

	const amountDue =
		!paid && walletAmount && orderDetails?.use_wallet
			? calculatedTotal - parseInt(walletAmount)
			: !paid && !orderDetails?.use_wallet
			? calculatedTotal - 0
			: 0;

	return (
		<div className="min-w-[490px] w-[100%]">
			<OrderReceiptCard
				calculatedOrder={calculatedOrder}
				isGroupOrder={isGroupOrder}
				orders={orders}
				getItemsTotal={getItemsTotal}
				time={time}
				order={orderData}
				mealTotal={mealTotal}
				subOrders={subOrders}
				isCutlery={isCutlery}
				discountValue={discountValue}
				calculatedGroupTotal={calculatedGroupTotal}
				subTotal={subTotal}
				deliveryFee={deliveryFee}
				serviceTotal={serviceTotal}
				extraCharges={extraCharges}
				calculatedTotal={calculatedTotal}
				paid={paid}
				amountDueGroup={amountDueGroup}
				amountDue={amountDue}
				orderType={orderType}
				id={id}
			/>
		</div>
	);
};

export default OrderReceipt;
