import { observable, runInAction, action } from "mobx";
import dataService from "../../services/data.service";
import {
	GET_LOGISTICS_URL,
	CANCEL_ORDER_URL,
	PAYMENT_HISTORY_URL,
	COMPLETE_ORDER_URL,
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
import { LIVE_TRIPS } from "../../router/router";
import moment from "moment";

export class HomeStore {
	@observable logistics = {};

	@observable ongoingTrips = [];
	@observable dataCached = false;
	@observable dataFBCached = false;
	@action updateOngoingTrips = (item) => {
		this.ongoingTrips = item;

		this.dataFBCached = true;
	};

	getLogistics = async (alert, shouldAlert) => {
		try {
			let response = await dataService.getAuthData(GET_LOGISTICS_URL);

			runInAction(() => {
				let res = response.data && response.data;
				let moments = res && res.trips.map((x) => moment(x.created_at));
				let maxDate = moment.max(moments).format("D MMM YY");
				let minDate = moment.min(moments).format("D MMM YY");
				res.max_date = maxDate;
				res.min_date = minDate;
				this.dataCached = true;
				this.logistics = res;
				shouldAlert &&
					alert.show("Logistics data loaded successfully!", {
						type: "success",
					});
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};

	cancelOrders = async (alert, data, history) => {
		try {
			let response = await dataService.postAuthData(CANCEL_ORDER_URL, data);
			let res;
			runInAction(() => {
				res = response && response.data;
				if (res) {
					alert.show("Order cancelled successfully!", {
						type: "success",
					});

					this.dataCached = true;

					history.push(LIVE_TRIPS);
				}
			});
			return res;
		} catch (error) {
			errorHandler(error, alert);
			const errMsg =
				error && (error.response || error.message || error.toString() || error);
			let errorMsg = (errMsg && errMsg.data && errMsg.data.message) || errMsg;
		}
	};

	completeOrders = async (alert, data, history) => {
		try {
			let response = await dataService.postAuthData(COMPLETE_ORDER_URL, data);
			let res;
			runInAction(() => {
				res = response && response.data;
				console.log(res);
				if (res) {
					alert.show("Order completed successfully!", {
						type: "success",
					});

					history.push(LIVE_TRIPS);
				}
			});
			return res;
		} catch (error) {
			errorHandler(error, alert);
			const errMsg =
				error && (error.response || error.message || error.toString() || error);
			let errorMsg = (errMsg && errMsg.data && errMsg.data.message) || errMsg;
		}
	};
}
export default new HomeStore();
