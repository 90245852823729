import React, { useState } from "react";
import SearchButton from "../../../../components/buttons/SearchButton";
import IconInput from "../../../../components/inputs/iconInput";
import { numberWithCommas } from "../../../../helpers/commaSeparator";

const AddToWallet = ({
	creditWallet,
	creditLoading,
	orderData,
	setChangeBtn,
	new_fee,
	setAddWallet,
	balance,
}) => {
	const [amount, setAmount] = useState("");

	const handleCreditWallet = async () => {
		let data = {
			user_id: orderData?.calculated_order?.user?.id,
			amount: amount,
			reason: "Refund Delivery Fee",
		};
		await creditWallet(data);
		setAddWallet(false);
		setChangeBtn(true);
	};

	return (
		<div className=" space-y-3 input_container  mt-3">
			<p className=" font-semibold">
				{" "}
				Add ₦{numberWithCommas(new_fee?.toFixed(2))}{" "}
				{orderData?.calculated_order?.user?.first_name}{" "}
				{orderData?.calculated_order?.user?.last_name}'s Wallet
			</p>
			<IconInput
				className={"w-auto "}
				value={amount}
				onChange={setAmount}
				placeholder={`add ₦${numberWithCommas(new_fee?.toFixed(2))}...`}
			/>
			<p className=" text-sm green_text font-bold">
				Wallet Balance:{" "}
				<span className=" "> ₦{numberWithCommas(balance.toFixed(2))}</span>
			</p>

			<div className=" flex gap-2 ">
				<SearchButton
					isGreen
					text={"Add To User's Wallet"}
					onClick={handleCreditWallet}
					isLoading={creditLoading}
					isDisabled={creditLoading}
				/>
			</div>
		</div>
	);
};

export default AddToWallet;
