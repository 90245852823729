import React from "react";
import "./styles.scss";
import { BiLoaderAlt } from "react-icons/bi";

const Button = ({ text, onClick, className }) => {
	return (
		<button className={`button ${className}`} onClick={onClick}>
			<label className="bold_18_px">{text}</label>
		</button>
	);
};

const IconButton = ({
	withIcon,
	icon,
	text,
	onClick,
	isBlue,
	isBlack,
	isRed,
	isRedThick,
	className,
	withSubIcon,
	subIcon,
	loading,
	rotate,
	type,
	isLoading,
	isDisabled,
}) => {
	return (
		<button
			className={`button_two pointer ${
				isBlue
					? "blue_button"
					: isRed
					? "red_button"
					: isRedThick
					? "red_button_thick"
					: isBlack
					? "black_button"
					: "green_button"
			} ${className}`}
			//   onClick={onClick}
			disabled={loading || isDisabled || isLoading}
			type={type}
		>
			{isLoading && (
				<div>
					<BiLoaderAlt className="animate-spin" />
				</div>
			)}
			{withIcon && <span className="mr_10">{icon}</span>}
			<h6 className="bold_18_px cursor_pointer" onClick={onClick}>
				{text}
			</h6>
			{withSubIcon && loading && !rotate ? (
				<span className="ml_10">loading...</span>
			) : withSubIcon && !loading ? (
				<span className="ml_10">{subIcon}</span>
			) : null}
		</button>
	);
};
export { IconButton };
export default Button;
