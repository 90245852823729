import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { LOGIN } from "./router";
const SuperAdminRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        (Cookies.get("token") &&
          Cookies.get("role") === "LOGISTICS_SUPER_ADMIN") ||
        (Cookies.get("token") && Cookies.get("role") === "CUSTOMER_SERVICE") ||
        (Cookies.get("token") && Cookies.get("role") === "SUPER_ADMIN") ? (
          <Route render={() => <Component {...props} />} />
        ) : (
          <Redirect to={LOGIN} />
        )
      }
    />
  );
};
export default SuperAdminRoute;
