import React, { useState } from "react";
import { useParams } from "react-router";
import Button from "../../../../components/buttons";
import SearchButton from "../../../../components/buttons/SearchButton";
import Input from "../../../../components/inputs";
import Modal from "../../../../components/modals";
import SearchModal from "../../../../components/modals/SearchModal";

const CancelTrip = ({
	handleChange,
	form,
	cancelTripLoading,
	showModal,
	setShowModal,
	orderData,
	cancelOrders,
	setForm,
	emptyForm,
	alert,
}) => {
	const { id } = useParams();

	// Cancel Trip
	const handleCancelOrders = async () => {
		await cancelOrders(
			alert,
			{
				order_code: orderData?.order_code,
				cancelled_note: form?.reason,
			},
			id
		);
		setForm({
			...emptyForm,
			reason: "",
		});
		setShowModal(!showModal);
	};
	return (
		<Modal
			showModal={showModal}
			closeModal={() => setShowModal(!showModal)}
			title="Please state your reason for cancelling this trip"
			content={
				<div className="flex- flex-col space-y-6">
					<Input
						type="text"
						onChange={(val) => handleChange("reason", val)}
						placeholder="Reason"
						name="Reason"
						value={form.reason}
						inputClassName="pt-1 h-24 "
					/>
					<div className=" flex justify-between gap-2 flex-wrap">
						<SearchButton
							className=""
							isGreen
							text="Go Back"
							onClick={() => setShowModal(!showModal)}
						/>
						<SearchButton
							className=""
							isRed
							text="Cancel Trip"
							onClick={handleCancelOrders}
							isDisabled={!form.reason}
							isLoading={cancelTripLoading}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default CancelTrip;
