import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner/dist/loader/ThreeDots";

const AnalyticsCard = ({
	title,
	caption,
	className,
	list,
	day,
	noOfOrders,
	orderDuration,
	totalAmountSpent,
	settledOrderAmount,
	unsettleOrderAmount,
	basketAbadonmentRate,
	cardWithListItems,
	onClick,
	loading,
	viewButton,
	orders,
	totalOrders,
}) => {
	return (
		<div
			className={`bg-white-100 border-[1px] border-grey-40 rounded-md p-6 w-fit drop-shadow-md ${className}`}
		>
			<div className="space-y-2">
				<div className="">
					{title && <span className="font-semibold capitalize"> {title}</span>}
					{caption && <span className="lowercase"> {caption} </span>}
				</div>

				{noOfOrders && (
					<div className="flex space-x-3 items-end">
						<p className="font-bold text-lg md:text-xl  mdLg:text-3xl text-green-100 ">
							{totalOrders ? totalOrders : 0}
						</p>
						<p className="text-sm sm:text-base">All Time</p>
					</div>
				)}

				{totalAmountSpent && (
					<div className="space-y-2">
						{loading ? (
							<div>
								<ThreeDots color="#ff000060" height={10} width={50} />
							</div>
						) : (
							<div>
								<div className="flex space-x-3 items-end">
									<p className="font-bold text-lg md:text-xl  mdLg:text-3xl text-green-100  ">
										{totalAmountSpent}
									</p>
									<p className="text-sm sm:text-base">All Time</p>
								</div>
								<div className="flex space-x-3 items-end">
									<div className="flex gap-2 items-center">
										<div className=" w-2 h-2 rounded-[50%] bg-red-100"></div>
										<p className="text-sm sm:text-base">
											{unsettleOrderAmount}
										</p>
									</div>
									<div className="flex gap-2 items-center">
										<div className=" w-2 h-2 rounded-[50%] bg-blue-100"></div>
										<p className="text-sm sm:text-base">{settledOrderAmount}</p>
									</div>
								</div>
							</div>
						)}
					</div>
				)}

				{orderDuration && (
					<div className="flex space-x-3 items-end">
						{loading ? (
							<div>
								<ThreeDots color="#ff000060" height={10} width={50} />
							</div>
						) : (
							<div>
								<p className="font-bold text-lg md:text-xl  mdLg:text-3xl text-green-100 ">
									{orderDuration}
								</p>
								<p className="text-sm sm:text-base">All Time</p>
							</div>
						)}
					</div>
				)}

				{basketAbadonmentRate && (
					<div className="flex space-x-3 items-end">
						<p className="font-bold text-lg md:text-xl  mdLg:text-3xl text-green-100  ">
							{basketAbadonmentRate}%
						</p>
						<p className="text-sm sm:text-base">All Time</p>
					</div>
				)}

				{cardWithListItems && (
					<>
						<div className="flex flex-col space-y-2">
							{list.length === 0 ? (
								loading ? (
									<div>
										<ThreeDots color="#ff000060" height={10} width={50} />
									</div>
								) : (
									<span className="text-green-200 font-bold"> - </span>
								)
							) : (
								list?.map((item, idx) => {
									return (
										<div className="text-green-200 font-bold" key={idx}>
											{item.name} - {item.length}
											{orders && (
												<span className="text-2xs">
													{item.length === 1 ? " order" : " orders"}
												</span>
											)}
										</div>
									);
								})
							)}
						</div>

						<div
							className="text-grey-textGrey underline cursor-pointer"
							onClick={onClick}
						>
							{" "}
							{list?.length > 2 ? viewButton : null}
						</div>

						{day && (
							<div className="text-grey-textGrey flex justify-end"> {day} </div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default AnalyticsCard;
