import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import TextCard from "../../components/textCard";
import { ChevronRight, Clock, User } from "../../assets/icons/icons";
import Button from "../../components/buttons";
import { Link } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { withAlert } from "react-alert";
import homeStore from "../../stores/adminStores/homeStore";
import Loader from "react-loader-spinner";
import { Paginate } from "@cokitchen/cokitchen-components";
import Cookies from "js-cookie";

import "./styles.scss";
import moment from "moment";
import dataService from "../../services/data.service";
const HomePage = ({ alert }) => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [showDateRange, setShowDateRange] = useState(true);
    const [tripsLoading, setTripsLoading] = useState(false);

  const [total, setTotal] = useState(0);

  const [showDateRangeFilters, setShowDateRangeFilters] = useState(false);
  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    setFilterCount(filterCount + 1);
  };

  const [logistics, setLogistics] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAllTrips, setShowAllTrips] = useState(true);

  const [bikesOnline, setBikesOnline] = useState([]);
  const [bikesOffline, setBikesOffline] = useState([]);
  const [trips, setTrips] = useState([]);

  const [filterCount, setFilterCount] = useState(0);

  useEffect(async () => {
    await loadData();

    sortBikers();
  }, [selectionRange]);

  const getTrips = async (n) => {
    setTripsLoading(true)
    const pageNumber = n;
    const { startDate, endDate } = selectionRange;
    const payload = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    };
    if (payload.start_date === payload.end_date) {
      payload.end_date = moment(payload.end_date)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
    let res = await dataService.getInternalData(
      `logistics/${Cookies.get(
        "logistics_company_id"
      )}/trips/${pageNumber}?start_date=${moment(startDate).format(
        "YYYY-MM-DD"
      )}&end_date=${moment(payload.end_date).format("YYYY-MM-DD")}`,
      payload,
      true
    );
        setTripsLoading(false);

    setTotal(res?.data?.trips?.total);
    setTrips(res.data?.trips.results);
  };
  const loadData = async () => {
    const getOtherData = async () => {
      setLoading(true)
      const { startDate, endDate } = selectionRange;
      const payload = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      if (payload.start_date === payload.end_date) {
        payload.end_date = moment(payload.end_date)
          .add(1, "days")
          .format("YYYY-MM-DD");
      }

      let res = await dataService.getInternalData(
        `logistics/?start_date=${payload.start_date}&end_date=${payload.end_date}`,
        payload,
        true
      );
      setLogistics(res.data);
      setLoading(false)
    };

    getOtherData();
    getTrips(1);
    sortBikers();
  };
  const sortBikers = () => {
    let online = [];
    let offLine = [];
    let allRiders = logistics?.fleets || [];
    let i;
    for (i = 0; i < allRiders.length; i++) {
      if (allRiders[i].online) {
        online.push(allRiders[i]);
      } else {
        offLine.push(allRiders[i]);
      }
    }
    setBikesOnline(online);
    setBikesOffline(offLine);
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  // const valid = (current) => {
  //   return current.isAfter(moment(startDate));
  // };

  const exportViaCsv = async () => {
    const { startDate, endDate } = selectionRange;
    const payload = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      email: Cookies.get("email"),
    };
    if (payload.start_date === payload.end_date) {
      payload.end_date = moment(payload.end_date)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }

    await dataService.postAuthData(
      `/logistics/${Cookies.get("logistics_company_id")}/trips/export`,

      payload
    );
  };
  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header title="Delivery Partner Admin" />
        <section className="main_section flex_row_top content_section tab_column">
          <section className="left_section pr_25 width_full">
            <section className="top_left grid_third mb_35">
              <TextCard
                cardLabel="Revenue"
                cardValue={`₦${
                  (logistics &&
                    logistics.revenue &&
                    numberWithCommas(logistics.revenue)) ||
                  "0"
                }`}
                withFooter
                cardFooter={`${moment(selectionRange?.startDate).format(
                  "DD-MMM, YY"
                )} - ${moment(selectionRange?.endDate).format("DD-MMM, YY")}`}
                loading={loading}
              />

              <TextCard
                cardLabel="Trips"
                cardValue={trips?.length || "-"}
                withFooter
                cardFooter={`${moment(selectionRange?.startDate).format(
                  "DD-MMM, YY"
                )} - ${moment(selectionRange?.endDate).format("DD-MMM, YY")}`}
                loading={tripsLoading}
              />
              <TextCard
                cardLabel="Fleet"
                cardValue={
                  (logistics && logistics.fleets && logistics.fleets.length) ||
                  "-"
                }
                withLink
                link={
                  <Link to="#/">
                    <label className="gray_label bold cursor_pointer">
                      Click to view
                    </label>
                  </Link>
                }
                loading={loading}
              />
              <TextCard
                cardLabel="Average price per trip"
                cardValue={`₦${
                  (logistics &&
                    logistics.average_price_per_trip &&
                    numberWithCommas(
                      parseFloat(logistics.average_price_per_trip).toFixed(1)
                    )) ||
                  "0"
                }`}
                withFooter
                cardFooter={`${moment(selectionRange?.startDate).format(
                  "DD-MMM, YY"
                )} - ${moment(selectionRange?.endDate).format("DD-MMM, YY")}`}
                loading={loading}
              />
              <TextCard
                cardLabel="Bike online"
                cardValue={bikesOnline && bikesOnline.length}
                withLink
                link={
                  <p className="gray_label flex_row_center">
                    {"Live "}
                    <span className="green_text normal_53px bold ml_5"> •</span>
                  </p>
                }
                loading={loading}
              />
            </section>

            <section className="">
              <div className="border_bottom_thin flex_row_between mb_25">
                <p className="bold_21px mb_15">Trip history</p>
                <Button
                  className="green_button mb_25"
                  text={"Export via csv"}
                  onClick={() => {
                    exportViaCsv();
                  }}
                />
              </div>

              {showAllTrips ? (
                <table className="width_full trip-table slideup">
                  <tbody>
                    <p className="gray_label bold_21px uppercase mt_25 pb_15">
                      {!tripsLoading && trips === 0
                        ? "No trips has been recorded"
                        : null}
                    </p>
                    {/* Loader */}
                    {tripsLoading ? (
                      <tr>
                        <td>
                          <div className="flex_column_left">
                            <Loader
                              type="ThreeDots"
                              color="#ff000060"
                              height={10}
                              width={50}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="flex_column_center">
                            <Loader
                              type="ThreeDots"
                              color="#ff000060"
                              height={10}
                              width={50}
                            />
                          </div>
                        </td>

                        <td>
                          <div className="flex_column_left">
                            <Loader
                              type="ThreeDots"
                              color="#ff000060"
                              height={10}
                              width={50}
                            />
                          </div>
                        </td>

                        <td>
                          <div className="flex_column_right">
                            <Loader
                              type="ThreeDots"
                              color="#ff000060"
                              height={10}
                              width={50}
                            />
                          </div>
                        </td>
                      </tr>
                    ) : null}
                    {/* Loader End */}
                    {!tripsLoading && trips

                      ?.sort(
                        (a, b) =>
                          new Date(b.created_at) - new Date(a.created_at)
                      )
                      ?.map((item, i) => {
                        let status =
                          item && item.completed
                            ? "Completed"
                            : item && item.cancelled
                            ? "Cancelled"
                            : item && !item.completed && !item.cancelled
                            ? "Pending"
                            : "";
                        return (
                          <tr key={i}>
                            <td>
                              <div className="flex_column_left">
                                <label className="gray_label mb_4">
                                  {item &&
                                    item.created_at &&
                                    moment(item.created_at).format("LT")}
                                </label>
                                <label className="gray_label mb_4">
                                  {item &&
                                    item.created_at &&
                                    moment(item.created_at).format(
                                      "DD MMM YYYY"
                                    )}
                                </label>
                                <label className="gray_label">
                                  #{item && item.order_code}
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_align_center mb_10">
                                  <span className="normal_53px green_text mr_10">
                                    •
                                  </span>
                                  <p className="normal_15px">
                                    From:{" "}
                                    <span className="bold">CoKitchen</span>
                                  </p>
                                </div>

                                <div className="flex_row_align_center">
                                  <span className="normal_53px red_text mr_10">
                                    •
                                  </span>
                                  <p className="normal_15px">
                                    To:{" "}
                                    <span className="bold">
                                      {item &&
                                        item.calculated_order &&
                                        item.calculated_order.address_details &&
                                        item.calculated_order.address_details
                                          .address_line}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_center mb_17">
                                  <Clock
                                    fill="#939393"
                                    width="8px"
                                    height="8px"
                                    className="mr_15"
                                  />

                                  <p
                                    className={`normal_15px bold ${
                                      status === "Completed"
                                        ? "green_text"
                                        : status === "Cancelled"
                                        ? "red_text"
                                        : "gray"
                                    }`}
                                  >
                                    {status}
                                  </p>
                                </div>

                                <div className="flex_row_align_center">
                                  <User
                                    fill="#939393"
                                    width="9px"
                                    height="9px"
                                    className="mr_15"
                                  />

                                  <p className="normal_15px">
                                    {item &&
                                      item.rider &&
                                      item.rider.first_name +
                                        " " +
                                        item.rider.last_name +
                                        " (Rider)"}
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_right">
                                <span className="bold_21px green_text mb_17">
                                  ₦
                                  {item?.calculated_order?.delivery_fee &&
                                    numberWithCommas(
                                      parseInt(
                                        item.calculated_order.delivery_fee
                                      )
                                    )}
                                </span>

                                <div className="flex_row_right">
                                  <Link
                                    to={`trip_history/${
                                      item && item.order_code
                                    }`}
                                  >
                                    {" "}
                                    <p className="mr_10 normal_15px black">
                                      See full trip details
                                    </p>
                                  </Link>
                                  <Link
                                    to={`trip_history/${
                                      item && item.order_code
                                    }`}
                                  >
                                    <ChevronRight
                                      fill="#000000"
                                      width="11px"
                                      height="11px"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : null}
              <div className="p_15_40 flex_row_center">
                <Button
                  className="red_button_thick width_full"
                  text={
                    showAllTrips ? `Hide trip history ` : `Show trip history`
                  }
                  onClick={() => {
                    setShowAllTrips(!showAllTrips);
                  }}
                />
              </div>
            </section>
            {total > 10 && (
              <Paginate
                onPageChange={(e) => getTrips(e.selected + 1)}
                pageCount={Math.ceil(total / 10)}
              />
            )}
          </section>

          <section className="right_section px_10 second_width_desktop">
            <div className="flex_row_right mb_25">
              <a href="#/">
                <label className="gray_label bold cursor_pointer">
                  Show all time data
                </label>
              </a>
            </div>

            <div
              className="button red_border borderless_dates flex_row_center mb_30"
              onClick={() => {
                if (showDateRange) {
                  return;
                } else {
                  setShowDateRange(true);
                }
              }}
            >
              {showDateRange && (
                <div
                  className={showDateRangeFilters ? "" : "collapsed_date_range"}
                >
                  <div className="flex_row_between">
                    <button
                      className="cursor_pointer mb_12 date_filter_button"
                      onClick={() =>
                        setShowDateRangeFilters(!showDateRangeFilters)
                      }
                    >
                      {showDateRangeFilters
                        ? "Hide date filters"
                        : " Show date filters"}
                    </button>
                    {filterCount > 1 ? (
                      <button
                        className="cursor_pointer mb_12 date_filter_button"
                        onClick={() => setFilterCount(0)}
                      >
                        Clear date filter
                      </button>
                    ) : null}
                  </div>

                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showMonthAndYearPickers={false}
                    minDate={new Date("2023-7-1")}
                  />
                </div>
              )}
            </div>

            <div className="bold_21px border_bottom_thin width_full p_15">
              All-time fleet performance summary ({logistics?.fleets.length})
            </div>
            {/* Summary item */}

            <p className="gray_label bold_21px uppercase mt_25 pb_15">
              {!loading && logistics?.fleets.length === 0
                ? "Fleets are empty"
                : null}
            </p>
            {logistics?.fleets
              ?.sort((a, b) => {
                return b.balance - a.balance;
              })
              ?.map((item, i, arr) => {
                return (
                  <div
                    className={`summary_item flex_column p_15_15_20 ${
                      arr.length - 1 === i ? "" : "border_bottom_thin"
                    }`}
                    key={i}
                  >
                    <div className="flex_row_between mb_5">
                      <div className="flex_row_align_center">
                        <span className="mr_15">
                          <User fill="#939393" width="11px" height="11px" />
                        </span>
                        <p className="bold_18_px">
                          {item.first_name + " " + item.last_name}
                        </p>
                      </div>
                      <div className="flex_row_right">
                        <p className="mr_10 normal_15px gray cursor_pointer">
                          rider details
                        </p>
                        <ChevronRight
                          fill="#939393"
                          width="11px"
                          height="11px"
                        />
                      </div>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <td className="normal_19px black text_right">
                            {item.trips}
                          </td>

                          <td className="normal_19px black text_right">
                            {item &&
                              item.balance &&
                              "₦" + numberWithCommas(item.balance)}
                          </td>
                          <td className="normal_19px black text_right">
                            {item.hours}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              })}
          </section>
        </section>
      </div>
    </main>
  );
};

export default withAlert()(HomePage);
