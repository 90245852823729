import React, { useState, useEffect } from "react";
import { MdDirectionsBike } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoMdWallet } from "react-icons/io";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { FaHandsHelping } from "react-icons/fa";
import { RiEBikeFill } from "react-icons/ri";
import { RiArrowRightCircleFill } from "react-icons/ri";
import { GiCardPickup } from "react-icons/gi";
import { FaHourglassEnd } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { IoIosRemoveCircle } from "react-icons/io";
import { HiSwitchHorizontal } from "react-icons/hi";
import { BsLightningFill } from "react-icons/bs";

import { TiGroup } from "react-icons/ti";
import { IoPush } from "react-icons/io5";
import { ChevronRight, Clock } from "../../assets/icons/icons";
import { ONGOING_TRIP_DETAILS } from "../../router/router";
import homeStore from "../../stores/superAdminStores/homeStore";
import { withAlert } from "react-alert";
import "./styles.scss";
import Modal from "../modals";
import Select from "../select";
import Prompt from "../modals/prompt";
import moment from "moment";
import commonStore from "../../stores/superAdminStores/commonStore";
import Tippy from "@tippyjs/react";
import NewIcon from "../../assets/images/NEW-icon.png";
import { AiFillQuestionCircle } from "react-icons/ai";
import surgeReasons from "../../helpers/surgeReasons";
import { CheckBox } from "../checkBoxes";
import IconInput from "../inputs/iconInput";

const AltCard = ({
	active,
	onClick,
	loading,
	time,
	date,
	id,
	from,
	address,
	price,
	toDestination,
	logistics,
	rider,
	status,
	pathType,
	order,
	alert,
	ridersLoading,
}) => {
	const [showModal, setShowModal] = useState(false);
	const [showRiderModal, setShowRiderModal] = useState(false);
	const [modalType, setModalType] = useState("");
	const [showPrompt, setShowPrompt] = useState(false);
	const [promptType, setPromptType] = useState("");
	const [selectedRider, setSelectedRider] = useState("");
	const [selectedRiders, setSelectedRiders] = useState([]);

	const [riders, setRiders] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const [submittingAlt, setSubmittingAlt] = useState(false);
	const [body, setBody] = useState({
		title: "first_name, we’re sorry your order is running late",
		body: "We’ll do all we can to get it to you as soon as possible!",
		users: [order?.user_id],
	});
	const [deliveryValues, setDeliveryValues] = useState(order?.surge);
	const [surgeValue, setSurgeValue] = useState(order?.surge);

	useEffect(() => {
		let surgeVal = surgeReasons.find(
			(itm) =>
				String(itm.time) === String(order?.surge?.logistics_surge) &&
				String(itm.alt) === String(order?.surge?.logistics_surge_type)
		);
		surgeVal = surgeVal ? surgeVal : surgeReasons[0];
		setSurgeValue(surgeVal);
		// surgeVal&&  console.log("surgeValsurgeValsurgeValsurgeValsurgeValsurgeValsurgeVal",surgeVal)
	}, []);
	useEffect(() => {
		let surgeVal = surgeReasons.find(
			(itm) =>
				String(itm.time) === String(order?.surge?.logistics_surge) &&
				String(itm.alt) === String(order?.surge?.logistics_surge_type)
		);
		surgeVal = surgeVal ? surgeVal : surgeReasons[0];
		setSurgeValue(surgeVal);

		let isCustom =
			!order?.surge?.logistics_surge && !surgeVal
				? false
				: order?.surge?.logistics_surge &&
				  parseFloat(order?.surge?.logistics_surge) > 1 &&
				  !surgeVal
				? true
				: order?.surge?.logistics_surge && surgeVal
				? false
				: false;

		// console.log(
		// 	"opening modal",
		// 	surgeVal,
		// 	isCustom,
		// 	order?.surge?.logistics_surge
		// );

		sortRiders();
		let orderSurge = order?.surge || surgeReasons[0];
		setDeliveryValues(orderSurge);
		setDeliveryValues({
			...orderSurge,
			is_custom: isCustom,
		});
	}, [showModal, showPrompt]);

	useEffect(() => {
		let surgeVal = surgeReasons.find(
			(itm) =>
				String(itm.time) === String(deliveryValues?.logistics_surge) &&
				String(itm.alt) === String(deliveryValues?.logistics_surge_type)
		);
		setSurgeValue(surgeVal);
	}, [deliveryValues]);

	const customer =
		order?.calculated_order?.user?.first_name +
		" " +
		order?.calculated_order?.user?.last_name;
	const sortRiders = () => {
		let ridersArr = [];
		for (
			let i = 0,
				partners = homeStore?.logistics?.fleets,
				len = homeStore?.logistics?.fleets?.length;
			i < len;
			i++
		) {
			if (partners[i].role === "RIDER") {
				ridersArr.push({
					label: partners[i]?.first_name + " " + partners[i]?.last_name,
					value: partners[i]?.id,
				});
			}
		}
		setRiders(ridersArr);

		return ridersArr;
	};

	const reassignRider = async () => {
		setSubmitting(true);
		await homeStore.reassignRider(alert, {
			order_id: order?.id,
			rider_id: selectedRider,
		});
		setSubmitting(false);
		setShowModal(false);
	};

	const assistRider = async () => {
		setSubmitting(true);
		await homeStore.assistRider(alert, {
			order_id: order?.id,
			rider_assist_ids: selectedRiders,
		});
		setSubmitting(false);
		setShowModal(false);
	};
	const unassignRider = async () => {
		setSubmittingAlt(true);
		await homeStore.unassignRider(alert, {
			order_id: order?.id,
		});
		setSubmittingAlt(false);
		setShowPrompt(false);
	};
	const switchOrder = async () => {
		setSubmittingAlt(true);
		let body = {
			order_id: order?.id,
			cokitchen_polygon_id: order?.calculated_order?.pickup
				? order?.calculated_order?.cokitchen_polygon_id
				: null,
			delivery_type: order?.calculated_order?.pickup ? "delivery" : "pickup",
		};
		!body.cokitchen_polygon_id && delete body.cokitchen_polygon_id;
		await homeStore.switchOrder(alert, body);
		setSubmittingAlt(false);
		setShowPrompt(false);
	};
	const pushNotification = async () => {
		const { postNotification } = commonStore;
		setSubmittingAlt(true);
		await postNotification(alert, body);
		setSubmittingAlt(false);
		setShowPrompt(false);
	};
	// Update delivery factors
	const updateCustomerSurge = async () => {
		if (parseFloat(deliveryValues?.logistics_surge) < 1) {
			alert.show("Surge cannot be less than x1", {
				type: "info",
			});
		} else {
			setSubmitting(true);

			let body = {
				logistics_surge:
					parseFloat(deliveryValues?.logistics_surge) ||
					parseFloat(deliveryValues?.time),
				order_id: order?.id,
				logistics_surge_type:
					deliveryValues?.logistics_surge_type || deliveryValues?.alt,
			};
			!body.logistics_surge_type && delete body.logistics_surge_type;
			let message = customer + "'s order surge updated successfully!";

			await commonStore.postDeliveryValues(alert, body, message);
			setSubmitting(false);
			setShowModal(false);
		}
	};

	const isGroupOrder = order?.calculated_order?.is_group_order ? true : false;

	return loading ? (
		<div className="card flex_column_left bg_white p_20 mb_12 card_loader"></div>
	) : (
		<>
			<table
				className={`width_full alt_card bg_white p_20 mb_12 position-relative ${
					active ? "alt_card_active" : ""
				}`}
				onClick={onClick}
			>
				<tbody>
					<tr>
						{order?.first_order ? (
							<img
								src={NewIcon}
								className="ml-1"
								alt="new user"
								style={{ width: 60, height: 30 }}
							/>
						) : null}
					</tr>
					<tr>
						{/* One */}
						<td>
							<div className="flex_column_left">
								<Tippy content={`Order dispatch time`}>
									<label className="gray_label mb_4">{time}</label>
								</Tippy>
								<Tippy content={`Order dispatch date`}>
									<label className="gray_label mb_4">{date}</label>
								</Tippy>
								<label className="gray_label">#{id}</label>
								<div className="flex_row_right mb_5">
									<Link
										to={`/ongoing-trip-details/${pathType}/${id}`}
										className=""
									>
										<p className="mr_10 normal_15px black">
											See full trip details
										</p>
									</Link>
								</div>

								{order?.calculated_order?.pickup ? null : (
									<div className="flex_row_right cursor_pointer">
										<Link
											to={`#/#`}
											className=""
											onClick={() => {
												setModalType("reassign");
												setShowModal(true);
											}}
										>
											<p className="mr_10 normal_15px pink bold">
												{order?.rider?.first_name ? "Reassign" : "Assign"} rider
											</p>
										</Link>
									</div>
								)}
							</div>
						</td>
						{/* Two */}
						<td>
							<div className="flex_column_left">
								<div className="flex_row_align_center mb_10">
									<span className="normal_53px green_text mr_10">•</span>
									<p className="normal_15px">
										From: <span className="bold">{from}</span>
									</p>
								</div>

								<div className="flex_row_align_center">
									<span className="normal_53px red_text mr_10">•</span>
									<a
										className="normal_15px gray"
										href={`https://www.google.com/maps/search/?api=1&query=${order?.calculated_order?.lat},${order?.calculated_order?.lng}`}
										target="_blank"
									>
										To: <span className="bold">{address}</span>
									</a>
								</div>

								<div className="flex_row_align_center mb-1">
									<div className="flex_row_align_center mr_30">
										<IoMdWallet
											fill=" #939393"
											width="8px"
											height="8px"
											className="mr_15"
										/>

										<p className="normal_15px bold">₦{price}</p>
									</div>

									<p className="normal_15px bold">
										{toDestination} to destination
									</p>
								</div>
								<div className="flex_row_align_center mb-1">
									<div className="cursor_pointer bold mr-1">
										<Tippy
											content={`switch order to ${
												order?.calculated_order?.pickup ? "delivery" : "pickup"
											}`}
										>
											<div
												style={{}}
												className="mr_10 normal_15px pink bold"
												onClick={() => {
													setPromptType("switch");
													setShowPrompt(true);
												}}
											>
												<HiSwitchHorizontal
													color="#ff0000"
													width="9px"
													height="9px"
													className="mr_15"
												/>

												{`switch order to ${
													order?.calculated_order?.pickup
														? "delivery"
														: "pickup"
												}`}
											</div>
										</Tippy>
									</div>

									{isGroupOrder && order?.rider?.first_name && (
										<div className="cursor_pointer bold">
											<Tippy content={`Assign riders to assist ${rider}`}>
												<div style={{}}>
													<FaHandsHelping
														onClick={() => {
															setModalType("assist");
															setShowModal(true);
														}}
														color="#ff0000"
														width="9px"
														height="9px"
														className="mr_15"
													/>
												</div>
											</Tippy>
										</div>
									)}
								</div>

								<div className="flex_row_align_center mb-1">
									<div className="cursor_pointer bold mr-1">
										<Tippy content={`Update ${customer}'s order surge`}>
											<div
												style={{}}
												className="mr_10 normal_15px pink bold"
												onClick={() => {
													setModalType("surge");
													setShowModal(true);
												}}
											>
												<FaHourglassEnd
													color="#ff0000"
													width="9px"
													height="9px"
													className="mr_15"
												/>

												{`Update ${customer}'s order surge`}
											</div>
										</Tippy>
									</div>
								</div>
							</div>
						</td>
						{/* Three */}
						<td>
							<div
								className="flex_column_left cursor_pointer"
								onClick={() => {
									setShowRiderModal(true);
									setModalType("info");
								}}
							>
								<div className="flex_row_align_center mb_5">
									<Clock
										fill=" #939393"
										width="8px"
										height="8px"
										className="mr_15"
									/>

									<p
										className={`normal_15px bold mr_15 ${
											status === "ongoing"
												? "green_text"
												: status === "ready"
												? "text-yellow"
												: status === "processing"
												? "text-purple"
												: "gray"
										}`}
									>
										{status === "ongoing"
											? "on-going"
											: status === "ready"
											? "ready"
											: status === "processing"
											? "processing"
											: "pending"}
									</p>
									<Tippy content={`Push notification to ${customer}`}>
										<div style={{}}>
											<IoPush
												color="#939393"
												size="18px"
												className="mr_15 pointer"
												onClick={() => {
													setPromptType("notify");
													setShowPrompt(true);
												}}
											/>
										</div>
									</Tippy>
								</div>

								<div className="flex_row_align_center mb_5">
									<FaUser color="#939393" size="12px" className="mr_15" />

									<p className="normal_15px">
										{order?.calculated_order?.user?.first_name +
											" " +
											order?.calculated_order?.user?.last_name}
									</p>
								</div>

								<div className="flex_row_align_center mb_5">
									{order?.calculated_order?.pickup ? (
										<GiCardPickup
											color="#939393"
											size="14px"
											className="mr_15"
										/>
									) : (
										<RiEBikeFill
											color="#939393"
											size="15px"
											className="mr_15"
										/>
									)}

									<p className="normal_15px flex_row_start">
										{rider}{" "}
										{order?.rider?.first_name ? (
											<div className="cursor_pointer bold ml_5">
												<Tippy
													content={`Unassign ${
														order?.rider?.first_name +
														" " +
														order?.rider?.last_name
													}`}
												>
													<div style={{}}>
														<IoIosRemoveCircle
															onClick={() => {
																setPromptType("remove");
																setShowPrompt(true);
															}}
															color="#ff0000"
															width="9px"
															height="9px"
															className="mr_15"
														/>
													</div>
												</Tippy>
											</div>
										) : null}
									</p>
								</div>
								<Tippy content={`Order created at`}>
									<div className="flex_row_align_center">
										<RiArrowRightCircleFill
											color="#939393"
											size="12px"
											width="5px"
											height="5px"
											className="mr_15"
										/>

										<p className="normal_15px flex_row_start">
											{moment(order?.created_at).format("DD/MM LT")}
										</p>
									</div>
								</Tippy>
							</div>
						</td>
					</tr>
				</tbody>
				{isGroupOrder && (
					<>
						<div
							className="position-absolute"
							style={{ top: "-3px", right: "-3px" }}
						>
							<Tippy content={"Group order"}>
								<div style={{}}>
									<TiGroup color="#d50000" size="36px" />
								</div>
							</Tippy>
						</div>
						<div
							className="position-absolute"
							style={{ top: "-5px", right: "-8px", zIndex: 999999 }}
						>
							<Tippy
								content={`${
									order?.calculated_order?.sub_calculated_order_ids?.length + 1
								} Participants`}
							>
								<div
									style={{ width: "20px", height: "20px", borderRadius: "50%" }}
									className="d-justify-center d-align-center text-white bg-success"
								>
									{order?.calculated_order?.sub_calculated_order_ids?.length +
										1}
								</div>
							</Tippy>
						</div>
					</>
				)}
				{parseFloat(order?.surge?.logistics_surge) > 1 && (
					<>
						<div
							className="position-absolute"
							style={{ top: "-6px", right: "-6px" }}
						>
							<Tippy content={"Surge"}>
								<div style={{}}>
									<BsLightningFill color="#d50000" size="18px" />
								</div>
							</Tippy>
						</div>
						<div
							className="position-absolute"
							style={{ top: "-5px", right: "-8px", zIndex: 999999 }}
						>
							<Tippy content={`${customer}'s order has surge`}></Tippy>
						</div>
					</>
				)}
			</table>

			<Modal
				showModal={showModal}
				closeModal={() => setShowModal((prev) => !prev)}
				title={
					modalType === "reassign"
						? `${
								order?.rider?.first_name ? "Reassign" : "Assign"
						  } Rider for order ${id}`
						: modalType === "assist"
						? `Assign riders to assist ${rider}`
						: modalType === "surge"
						? `Update ${customer}'s order surge`
						: id
				}
				onSubmit={(e) => {
					e.preventDefault();
					if (modalType === "reassign") {
						reassignRider();
					} else if (modalType === "assist") {
						assistRider();
					} else if (modalType === "surge") {
						updateCustomerSurge();
					}
				}}
				content={
					<div className="width_full">
						{modalType === "assist" ? (
							<Select
								placeholder="Select Rider"
								defaultValue={{ label: rider, value: order?.rider?.id }}
								containerClass="width_full mb_15"
								options={riders}
								handleChange={(e) => {
									let i;
									let copy = [];
									for (i = 0; i < e.length; i++) {
										copy.push(e[i].value);
									}
									setSelectedRiders(copy);
								}}
								isLoading={ridersLoading}
								isMulti
							/>
						) : modalType === "surge" ? (
							<div>
								<div className="d-justify-start d-align-center mb-1">
									<CheckBox
										id="is_custom"
										label={`Enter custom values`}
										checked={deliveryValues?.is_custom}
										onClick={() => {
											setDeliveryValues({
												...deliveryValues,
												is_custom: !deliveryValues?.is_custom,
											});
										}}
									/>

									<Tippy content="Checking the box means that you'd have to manually enter the surge value and reason for this order. Note that surge value cannot be less than 1">
										<div style={{ marginTop: "-5px", marginLeft: "10px" }}>
											<AiFillQuestionCircle color="#de970b" />
										</div>
									</Tippy>
								</div>

								{deliveryValues?.is_custom ? (
									<>
										<IconInput
											containerClass="w-100 mb-1"
											placeholder="Enter surge value"
											value={deliveryValues?.logistics_surge}
											onChange={(e) => {
												setDeliveryValues({
													...deliveryValues,
													logistics_surge: e,
												});
											}}
											required
											type="number"
											min="1"
											step="0.1"
										/>

										<IconInput
											containerClass="w-100 mb-1"
											placeholder="Enter surge reason"
											value={deliveryValues?.logistics_surge_type}
											onChange={(e) => {
												setDeliveryValues({
													...deliveryValues,
													logistics_surge_type: e,
												});
											}}
											required
											type="text"
										/>
									</>
								) : (
									<Select
										placeholder="Select surge type"
										value={surgeValue || surgeReasons[0]}
										containerClass="width_full mb_15"
										options={surgeReasons}
										handleChange={(e) =>
											setDeliveryValues({
												...deliveryValues,
												logistics_surge: e.time,
												logistics_surge_type: e.alt,
											})
										}
									/>
								)}

								<p className="mt-3 text-grey bold">
									Note: Individual surge will only affect estimated delivery
									time. User has already paid.
								</p>
							</div>
						) : (
							<Select
								placeholder="Select Rider"
								defaultValue={{ label: rider, value: order?.rider?.id }}
								containerClass="width_full mb_15"
								options={riders}
								handleChange={(e) => {
									setSelectedRider(e.value);
								}}
								isLoading={ridersLoading}
							/>
						)}
					</div>
				}
				saveText={
					submitting && modalType === "reassign"
						? "Reassigning rider..."
						: submitting && modalType === "assist"
						? "Assigning riders..."
						: submitting && modalType === "surge"
						? "Updating surge..."
						: "Confirm"
				}
				submitting={submitting}
			/>

			<Prompt
				show={showPrompt}
				closeModal={() => setShowPrompt(!showPrompt)}
				isLight
				title={
					promptType === "remove"
						? `Are you sure you want to unassign ${rider} ?`
						: promptType === "switch"
						? `Are you sure you want to switch ${
								order?.calculated_order?.user?.first_name +
								" " +
								order?.calculated_order?.user?.last_name
						  }'s order to ${
								order?.calculated_order?.pickup ? "delivery" : "pickup"
						  }?`
						: promptType === "notify"
						? `Are you sure you want to push notification to ${
								order?.calculated_order?.user?.first_name +
								" " +
								order?.calculated_order?.user?.last_name
						  } ? \n \n \n \n
              Notification title:${body.title} \n
              Notification body:${body.body}\n
           
              `
						: "Are you sure?"
				}
				onSubmit={(e) => {
					e.preventDefault();
					if (promptType === "remove") {
						unassignRider();
					} else if (promptType === "switch") {
						switchOrder();
					} else if (promptType === "notify") {
						pushNotification();
					}
				}}
				saveText={
					submittingAlt && promptType === "remove"
						? "Unassigning rider..."
						: submittingAlt && promptType === "notify"
						? "Sending notification..."
						: submittingAlt && promptType === "switch"
						? "Switching..."
						: "Confirm"
				}
				loading={submittingAlt}
				submitting={submittingAlt}
				withGreen
				withRed
			/>
			<Modal
				smallModal={showRiderModal}
				closeModal={() => {
					setShowRiderModal(false);
				}}
				title={
					modalType === "info"
						? "Rider Details"
						: modalType === "assist"
						? `Assign riders to assist ${rider}`
						: modalType === "surge"
						? `Update ${customer}'s order surge`
						: id
				}
				onSubmit={(e) => {
					e.preventDefault();
					setShowRiderModal(false);
				}}
				content={
					<div className="width_full">
						<p className="normal_px_20 mb_5">
							<span className="bold_21px mr_10 ">First Name</span>{" "}
							{order?.rider?.first_name}
						</p>
						<p className="normal_px_20 mb_5">
							<span className="bold_21px mr_10 ">Last Name</span>{" "}
							{order?.rider?.last_name}
						</p>
						<p className="normal_px_20 mb_5">
							<span className="bold_21px mr_10 ">Email</span>{" "}
							{order?.rider?.email}
						</p>
						<p className="normal_px_20 mb_5">
							<span className="bold_21px mr_10 ">Phone Number</span>{" "}
							{order?.rider?.phone_number}
						</p>
					</div>
				}
				saveText={"Close"}
				submitting={submitting}
			/>
		</>
	);
};
export default withAlert()(AltCard);
