import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import { PAYMENT_HISTORY_URL } from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class HistoryStore {
  @observable histories = [];
  @observable dataCached = false;

  getTransactionHistories = async (alert, shouldAlert) => {
    try {
      let response = await dataService.getAuthData(PAYMENT_HISTORY_URL);

      runInAction(() => {
        let res =response?.data?.data;

    
        this.histories = res;
        shouldAlert &&
          alert.show("Payment history loaded successfully!", {
            type: "success",
          });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}
export default new HistoryStore();
