import React from "react";
import { MdDirectionsBike } from "react-icons/md";
import "./styles.scss";
const Card = ({
  active,
  name,
  riders,
  age,
  trips,
  ridersOnline,
  lastPayout,
  onClick,
  loading,
}) => {
  return loading ? (
    <div className="card flex_column_left bg_white p_20 mb_12 card_loader"></div>
  ) : (
    <div
      className={`card flex_column_left bg_white p_20 mb_12 ${
        active ? "active_card" : ""
      }`}
      onClick={onClick}
    >
      <div className="flex_row_between width_full mb_25">
        <p className="bold_21px">{name} </p>
        <p className="normal_15px ">Partner since: {age}</p>
      </div>
      <div className="flex_row_between width_full mb_12">
        <div className="flex_row_start">
          <MdDirectionsBike
            fill=" #939393"
            width="8px"
            height="8px"
            className="mr_15"
          />
          <p className="bold_21px">{riders} Riders</p>
        </div>

        <p className="normal_15px ">{trips} trips completed</p>
      </div>
      <div className="flex_row_between width_full">
        <div className="flex_row_start">
          <div className="mr_15 green_text bold_21px">•</div>
          <p className="bold_21px green_text">{ridersOnline} Riders online</p>
        </div>

        <p className="normal_15px ">{lastPayout} since last payout</p>
      </div>
    </div>
  );
};
export default Card;
