import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { DateRangePicker } from "react-date-range";

const SearchDateRange = ({ ranges, onChange, clearDateFilter }) => {
	function handleDateSelection(ranges) {
		onChange(ranges.selection);
	}
	

	return (
		<div className=" mx-auto mdLg:mx-0 w-fit space-y-6">
			<div className="flex flex-col gap-6">
				<div className="flex flex-col items-center gap-6 rounded-lg border px-6 py-4 bg-white-100  drop-shadow-xl overflow-hidden w-full">
					<div className=" flex justify-between w-full items-center rounded-sm ">
						<span className="p-[5px] bg-black-100 text-white-100 bg-opacity-80 rounded-md">
							Show date filters
						</span>
						<span
							onClick={clearDateFilter}
							className="p-[5px] bg-black-100 text-white-100 bg-opacity-80 rounded-md"
						>
							Clear date filters
						</span>
					</div>
					<DateRangePicker
						ranges={[ranges]}
						onChange={handleDateSelection}
						showMonthAndYearPickers={false}
						showDateDisplay={false}
					/>
				</div>
			</div>
		</div>
	);
};

SearchDateRange.propTypes = {
	ranges: PropTypes.any,
	onChange: PropTypes.func,
	selectionRange: PropTypes.any,
	setSelectionRange: PropTypes.func,
	clearDateFilter: PropTypes.func,
};

export default SearchDateRange;
