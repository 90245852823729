import React from "react";
import { User } from "../../assets/icons/icons";
import Logo from "../../assets/images/logo.png";
import "./styles.scss";
import { withRouter } from "react-router-dom";
import { withAlert } from "react-alert";
import Cookies from "js-cookie";
const Header = ({ title = "Cokitchen Logistics", noUser, history, alert }) => {
	const user = Cookies.get("first_name") + " " + Cookies.get("last_name");
	const role = Cookies.get("role");
	const logout = () => {
		Cookies.remove("token");
		Cookies.remove("id");
		Cookies.remove("email");
		Cookies.remove("first_name");
		Cookies.remove("last_name");
		Cookies.remove("role");
		history.push("/login");
		alert.show("Logged out successfully!", {
			type: "success",
		});
		window.location.reload();
	};
	return (
		<header className="header flex_row_between">
			<figure className="flex_row">
				<img src={Logo} className="mr_30" alt="logo" />
				<figcaption className="red_normal_20px flex_row_center ">
					{title}
				</figcaption>
			</figure>
			{!noUser && (
				<div className="flex_row_between">
					<div className="flex_column mr_30">
						<label className="normal_18px bold capitalize mb_5">{user}</label>
						<label className="normal_18px">
							{role == "LOGISTICS_SUPER_ADMIN"
								? "Super Admin"
								: role == "LOGISTICS_ADMIN"
								? "Admin"
								: role == "SUPER_ADMIN"
								? "Super Admin"
								: ""}{" "}
						</label>
					</div>
					<span>
						<User width="28px" height="28px" fill="#ffffff" />
					</span>
					<button onClick={logout} className="cursor_pointer red_normal_20px">
						Logout
					</button>
				</div>
			)}
		</header>
	);
};

export default withRouter(withAlert()(Header));
