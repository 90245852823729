import React, { useState } from "react";
import SearchButton from "../../../../components/buttons/SearchButton";
import IconInput from "../../../../components/inputs/iconInput";
import { numberWithCommas } from "../../../../helpers/commaSeparator";

const DeductWallet = ({
	debitWallet,
	debitLoading,
	orderData,
	setChangeBtn,
	setDeductWallet,
	new_fee,
	balance,
}) => {
	const [amount, setAmount] = useState("");

	const handleDebitWallet = async () => {
		let data = {
			user_id: orderData?.calculated_order?.user?.id,
			amount: amount,
			reason: "Deduct Delivery Fee",
		};
		await debitWallet(data);
		setDeductWallet(false);
		setChangeBtn(true);
	};

	return (
		<div className=" space-y-3 input_container mt-3 ">
			<p className="  font-semibold">
				{" "}
				Deduct ₦{numberWithCommas(new_fee?.toFixed(2))}{" "}
				{orderData?.calculated_order?.user?.first_name}{" "}
				{orderData?.calculated_order?.user?.last_name}'s Wallet
			</p>
			<IconInput
				className={"w-auto "}
				value={amount}
				onChange={setAmount}
				placeholder={`deduct ₦${numberWithCommas(new_fee?.toFixed(2))}...`}
			/>
			<p className="text-sm green_text font-bold">
				Wallet Balance:{" "}
				<span className=" "> ₦{numberWithCommas(balance.toFixed(2))}</span>
			</p>

			<div className=" flex gap-2 ">
				<SearchButton
					isRed
					text={"Deduct Wallet"}
					onClick={handleDebitWallet}
					isLoading={debitLoading}
					isDisabled={debitLoading}
				/>
			</div>
		</div>
	);
};

export default DeductWallet;
