import React, { useState, useEffect } from "react";
import moment from "moment";
import { ThreeDots } from "react-loader-spinner/dist/loader/ThreeDots";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router";
import Button from "../../../components/buttons";
import OrderCard from "../../../components/card/OrderCard";
import { numberWithCommas } from "../../../helpers/commaSeparator";
import SearchStore from "../../../stores/superAdminStores/searchStore";
import SearchButton from "../../../components/buttons/SearchButton";

const UserPendingTasks = ({ user, alert, id }) => {
	let rider = "RIDER";
	let role = user?.role;
	let activeRole = role === rider ? "orders" : "transactions";
	const [activeTab, setActiveTab] = useState("orders");
	const {
		getUserOrders,
		userOrders,
		ordersLoading,
		getRiderOrders,
		riderOrders,
	} = SearchStore;
	const [count, setCount] = useState(1);
	const [goBack, setGoback] = useState(false);
	const [orderBtn, setOrderBtn] = useState(true);
	const [issuesBtn, setIssuesBtn] = useState(true);
	const [loading, setLoading] = useState(false);
	useEffect(() => fetchOrders(), []);

	const fetchOrders = async () => {
		setLoading(true);
		if (role !== rider) {
			let data = { user_id: id };
			await getUserOrders(alert, data);
		} else {
			let data = { rider_id: id };
			await getRiderOrders(alert, data);
		}
		setLoading(false);
	};
	useEffect(() => {
		loadUserResultBtn(userOrders.total, userOrders.current_page)
			? setOrderBtn(false)
			: setOrderBtn(true);

		if (userOrders.current_page > 1) {
			setGoback(true);
		} else {
			setGoback(false);
		}
	}, [userOrders]);
	const getMoreOrders = async (page) => {
		setLoading(true);
		if (role === rider) {
			let data = { rider_id: id };
			await getRiderOrders(alert, data, page);
		} else {
			let data = { user_id: id };
			await getUserOrders(alert, data, page);
		}

		setCount(page);
		setLoading(false);
	};
	const loadUserResultBtn = (total, current) => {
		const result = total / current < 10;
		return result;
	};

	const history = useHistory();

	function setUserPage(item) {
		if (role === rider) {
			history.push(`/search/profile/${item?.user_id}`);
		} else {
			history.push(`/search/profile/${item?.rider_id}`);
		}
		window.location.reload();
	}
	function setOrderPage(item) {
		history.push(`/search/order/${item?.id}`);
	}

	let orders = role === rider ? riderOrders : userOrders;
	return (
		<div className="w-full mdLg:w-[80%] lg:w-[70%]">
			<div className="space-y-6 my-9">
				<div className="flex flex-wrap justify-center ">
					<p
						className={`text-black-100 text-base font-bold basis-1/6 py-1 px-4 ${
							activeTab === "orders" ? "active-item" : ""
						} `}
					>
						Orders
					</p>
				</div>
				{!orderBtn && loading && (
					<div>
						<ThreeDots color="#ff000060" height={10} width={50} />
					</div>
				)}

				{activeTab === "orders" ? (
					orders && orders.data?.length === 0 ? (
						<div>
							{loading && (
								<div>
									<ThreeDots color="#ff000060" height={10} width={50} />
								</div>
							)}
							{!loading && (
								<p className="text-lg font-bold">This user has no orders ...</p>
							)}
						</div>
					) : (
						<div className="space-y-6">
							{orders &&
								orders.data?.map((item) => (
									<OrderCard
										role={role}
										rider={rider}
										order={item && item}
										startingTime={moment(item.created_at).format("hh:mma")}
										amount={`₦${numberWithCommas(
											item.calculated_order.total_amount || 0
										)}`}
										// name= {user?.user.first_name +" " + user?.user.last_name}
										startTime={moment(item.created_at).format(
											"Do of MMMM YYYY, LT"
										)}
										orderCode={item.order_code}
										deliveryFee={`₦${numberWithCommas(
											parseInt(item.calculated_order.delivery_fee || 0)
										)}`}
										riderName={
											role === rider
												? item?.rider_assigned &&
												  `${item.user.first_name} ${item.user.last_name}`
												: (item?.rider_assigned &&
														`${item.rider.first_name} ${item.rider.last_name}`) ||
												  "Pickup"
										}
										to={item.calculated_order.address_details.address_line}
										endTime={moment(item.completed_time).format("LT")}
										setOrderPage={() => setOrderPage(item)}
										setUserPage={() => setUserPage(item)}
									/>
								))}
							<div className="flex items-center space-x-2">
								{goBack && (
									<span>
										<BiArrowBack
											size={20}
											color="#FF0000"
											className="cursor-pointer mr-3"
											onClick={() => {
												getMoreOrders(count - 1);
											}}
										/>
									</span>
								)}
								{orderBtn && (
									<SearchButton
										className="my-4"
										isGreen
										text="Load more results"
										onClick={() => {
											getMoreOrders(count + 1);
										}}
										isDisabled={loading}
										isLoading={loading}
									/>
								)}
							</div>
						</div>
					)
				) : null}
			</div>
		</div>
	);
};

export default UserPendingTasks;
