import React from "react";
import Cookies from "js-cookie";

// const logout = ({ history, alert }) => {
//   Cookies.remove("token");
//   Cookies.remove("id");
//   Cookies.remove("email");
//   Cookies.remove("first_name");
//   Cookies.remove("last_name");
//   Cookies.remove("role");
//   history.push("/login");
//   alert.show("Logged out successfully!", {
//     type: "success",
//   });
// };
//
const errorHandler = (error, alert) => {
	const errMsg =
		error && (error.response || error.message || error.toString() || error);
	let errorMsg = (errMsg && errMsg.data && errMsg.data.message) || errMsg;
	console.log("Errror Handler one", errorMsg);
	if (typeof errorMsg !== "string") {
		alert.show("An error has occured!!", {
			type: "error",
		});
		return "An error has occured!!";
	} else {
		alert.show(errorMsg, {
			type: "error",
		});
		console.log("Errror Handler", errorMsg);
		return errorMsg;
	}
};
export default errorHandler;
