import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import Modal from "../../../../components/modals";
import Loader from "react-loader-spinner";
import { numberWithCommas } from "../../../../helpers/commaSeparator";
import SearchButton from "../../../../components/buttons/SearchButton";
import "./styles.scss";
import { withAlert } from "react-alert";
import AddToWallet from "./AddToWallet";
import DeductWallet from "./DeductWallet";

const ChangeAddress = ({
	alert,
	changeAddressModal,
	setChangeAddressModal,
	changeAddress,
	getCokitchenPolygonId,
	coordinatesDetails,
	cordinateLoading,
	orderData,
	addressLoading,
	creditWallet,
	debitWallet,
	creditLoading,
	debitLoading,
	getUserById,
	userData,
}) => {
	const [address, setAddress] = useState(null);
	const [addressDetails, setAddressDetails] = useState(null);
	const [loadingL, setLoading] = useState(false);
	const [coordinates, setCoordinates] = useState({
		lat: "",
		lng: "",
	});
	const [cordinateDetailsArray, setCordinateDetailsArray] = useState(null);
	const [addWallet, setAddWallet] = useState(false);
	const [deductWallet, setDeductWallet] = useState(false);
	const [newAddress, setNewAddress] = useState("");

	const [showDetails, setShowDetails] = useState(false);
	const [changeBtn, setChangeBtn] = useState(false);

	const getUser = async () => {
		await getUserById(alert, orderData?.user_id);
	};

	useEffect(() => {
		setCordinateDetailsArray(coordinatesDetails);
	}, [coordinatesDetails]);

	let previous_fee = parseFloat(orderData?.calculated_order?.delivery_fee);
	const current_address =
		orderData?.calculated_order?.address_details?.address_line;
	let new_fee = cordinateDetailsArray
		? parseFloat(cordinateDetailsArray?.cokitchen_polygon?.delivery_fee || 0) *
		  (orderData?.calculated_order?.surge
				? parseFloat(orderData?.calculated_order?.surge)
				: 1)
		: 0;
	//fee to be added or deducted
	const addedOrDeductedFee = Math.abs(new_fee - previous_fee);
	const balance = parseFloat(userData?.balance);

	const handleChange = async (value) => {
		setShowDetails(false);
		setLoading(true);
		setAddress(value);

		const results = await geocodeByAddress(value);
		const latLng = await getLatLng(results[0]);
		setAddressDetails(results[0]);

		let latLngData = {
			lat: latLng.lat,
			lng: latLng.lng,
		};

		await getCokitchenPolygonId(latLngData);
		setCordinateDetailsArray(coordinatesDetails);
		await getUser();
		setNewAddress(results[0]?.formatted_address);
		setCoordinates(latLng);
		setLoading(false);
		setShowDetails(true);
	};

	useEffect(() => {
		if (new_fee > previous_fee) {
			setDeductWallet(true);
			setAddWallet(false);
			setChangeBtn(false);
		} else if (previous_fee > new_fee) {
			setAddWallet(true);
			setDeductWallet(false);
			setChangeBtn(false);
		} else if (previous_fee === new_fee) {
			setAddWallet(false);
			setDeductWallet(false);
			setChangeBtn(true);
		} else {
			setChangeBtn(true);
		}
	}, [new_fee, previous_fee, coordinatesDetails]);

	const changeDeliveryAddress = async () => {
		let data = {
			cokitchen_polygon_id: cordinateDetailsArray?.cokitchen_polygon?.id,
			lat: String(coordinates.lat),
			lng: String(coordinates.lng),
			order_code: orderData?.order_code,
			address_details: {
				name: "other",
				city: addressDetails?.address_components[2]?.long_name,
				address_line: addressDetails?.formatted_address,
				phone_number: orderData?.calculated_order?.user?.phone_number,
			},
		};
		let id = orderData?.id;
		await changeAddress(data, id, alert);
		setChangeAddressModal(false);
		setShowDetails(false);
		setChangeBtn(false);
		setAddress("");
	};
	const searchOptions = {
		componentRestrictions: { country: ["ng"] },
	};
	return (
		<Modal
			showModal={changeAddressModal}
			closeModal={() => {
				setChangeAddressModal(!changeAddressModal);
				setShowDetails(false);
				setAddWallet(false);
				setDeductWallet(false);
				setChangeBtn(false);
			}}
			title=""
			content={
				<div className=" py-4">
					{" "}
					<PlacesAutocomplete
						value={address}
						onChange={setAddress}
						onSelect={handleChange}
						searchOptions={searchOptions}
					>
						{({
							getInputProps,
							suggestions,
							getSuggestionItemProps,
							loading,
						}) => (
							<div className=" input_container">
								<p className="bold mb_25">Enter address </p>
								<input
									{...getInputProps({
										placeholder: "enter address...",
										className: "mb-2",
									})}
								/>
								<div className="autocomplete-dropdown-container">
									{loading && <div>Loading...</div>}
									{suggestions.map((suggestion, i) => {
										const className = suggestion.active
											? "px-1 py-1 "
											: "px-1 py-1";
										// inline style for demonstration purpose
										const style = suggestion.active
											? {
													backgroundColor: "#FED7AA99",
													transition: "all .3s",
													cursor: "pointer",
											  }
											: { backgroundColor: "#ffffff", cursor: "pointer" };

										return (
											<div
												{...getSuggestionItemProps(suggestion, {
													className,
													style,
												})}
												key={i}
											>
												<span>{suggestion.description}</span>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</PlacesAutocomplete>
					{(loadingL || cordinateLoading) && (
						<div className=" flex items-center gap-1">
							<p className=" text-sm">calculating new delivery price</p>
							<Loader
								type="ThreeDots"
								color="#ff000060"
								height={10}
								width={26}
							/>
						</div>
					)}
					{!cordinateLoading && showDetails && (
						<div className="  mt-2">
							<p>
								Address Will Be Changed From:{" "}
								<span className=" text-grey-textGrey ">{current_address}</span>{" "}
								<br /> To:
								<span className=" text-grey-textGrey "> {newAddress}</span>
							</p>
							<p className="mt-2">
								Previous Delivery Fee:{" "}
								<span className=" ">
									{" "}
									₦{numberWithCommas(previous_fee.toFixed(2))}
								</span>
							</p>
							{/*	<p>
								Polygon: <span className=" font-semibold">{polygonName} </span>{" "}
					</p>*/}
							<p>
								New Delivery Fee:{" "}
								<span className=" font-bold">
									{" "}
									₦{numberWithCommas(new_fee?.toFixed(2))}
								</span>
							</p>

							{changeBtn && (
								<SearchButton
									isGreen
									text={addressLoading ? "Updating..." : "Change Address"}
									className="flex ml-auto  mt-2"
									onClick={changeDeliveryAddress}
									loading={addressLoading}
									isDisabled={addressLoading}
								/>
							)}
							{/*<p
										onClick={() => {
											setAddWallet(true);
											setDeductWallet(false);
										}}
										className=" flex justify-end pt-2 underline cursor-pointer text-xs"
									>
										Add To Wallet
									</p>
									<p
										onClick={() => {
											setAddWallet(false);
											setDeductWallet(true);
										}}
										className=" flex justify-end pt-2 underline cursor-pointer text-xs"
									>
										Remove From Wallet
									</p>{" "}*/}
							{addWallet && (
								<AddToWallet
									creditWallet={creditWallet}
									creditLoading={creditLoading}
									orderData={orderData}
									setChangeBtn={setChangeBtn}
									setAddWallet={setAddWallet}
									new_fee={addedOrDeductedFee}
									balance={balance}
								/>
							)}
							{deductWallet && (
								<DeductWallet
									debitWallet={debitWallet}
									debitLoading={debitLoading}
									orderData={orderData}
									setChangeBtn={setChangeBtn}
									setDeductWallet={setDeductWallet}
									new_fee={addedOrDeductedFee}
									balance={balance}
								/>
							)}
						</div>
					)}
				</div>
			}
		/>
	);
};

export default withAlert()(ChangeAddress);
