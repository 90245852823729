import React, { useState, useEffect } from "react";
import Header from "../header";
import Tabs from "../tabs";
import Select from "../select";
import { IconButton } from "../buttons";
import { BiRefresh } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { PAYMENT_HISTORY } from "../../router/router";
import db from "../../services/firebase.config";
import commonStore from "../../stores/superAdminStores/commonStore";
import Tippy from "@tippyjs/react";
import { AiFillQuestionCircle } from "react-icons/ai";
import IconInput from "../inputs/iconInput";
import { CheckBox } from "../checkBoxes";
import surgeReasons from "../../helpers/surgeReasons";
import { withAlert } from "react-alert";
import "./styles.scss";
const Layout = ({
	children,
	home,
	details,
	notifications,
	search,
	locationChange,
	locationLoading,
	locationValue,
	withHistoryBtn,
	onHistoryClick,
	title,
	refreshLoading,
	onRefresh,
	location_set,
	paymentClass,
	options,
	serachBar,
	users,
	noPaymentBtn,
	alert,
}) => {
	const { pathname } = useLocation();
	const [deliveryValues, setDeliveryValues] = useState({
		delivery: false,
		surge: "",
	});
	const [deliverySubmitting, setDeliverySubmitting] = useState(false);
	useEffect(() => {
		getDeliveryValue();
	}, []);
	const getDeliveryValue = async () => {
		const response = await db
			.collection("delivery_values")
			.doc("delivery_values")
			.onSnapshot(
				(items) => {
					let itemsCopy = { ...items?.data() };
					let matchedReason =
						surgeReasons.filter(
							(itm) =>
								String(itemsCopy?.logistics_surge) === String(itm.time) &&
								String(itemsCopy?.logistics_surge_type) === String(itm.alt)
						) || surgeReasons[0];
					let newMatchedReason =
						(matchedReason && matchedReason[0]) || surgeReasons[0];
					setDeliveryValues({ ...itemsCopy, ...newMatchedReason });
				},
				(err) => {
					console.log(err);
				}
			);
	};
	// Update delivery factors
	const updateDeliveryFactors = async () => {
		if (parseFloat(deliveryValues?.logistics_surge) < 1) {
			alert.show("Surge cannot be less than x1", {
				type: "info",
			});
		} else {
			setDeliverySubmitting(true);
			let body = JSON.parse(JSON.stringify(deliveryValues)),
				message = "Delivery factors updated successfully!";
			body.logistics_surge = parseFloat(deliveryValues.logistics_surge);
			delete body.surge;
			delete body.label;
			delete body.value;
			delete body.time;
			delete body.alt;
			!body?.logistics_surge_type && delete body.logistics_surge_type;
			await commonStore.postDeliveryValues(alert, body, message);
			setDeliverySubmitting(false);
			await getDeliveryValue();
		}
	};
	return (
		<main className="main_container">
			<div className="main_container_content">
				<Header title="Logistics Super Admin" />
				<Tabs
					home={home}
					details={details}
					notifications={notifications}
					location_set={location_set}
					users={users}
					search={search}
				/>
				{!pathname.includes("search") && (
					<div className="">
						<div className="header_flex">
							<div className="header_left">
								<div className="header_left_flex">
									<p>{title}</p>

									<Select
										placeholder="Locations: ALL"
										// defaultValue={{ label: "Locations: ALL", value: "all" }}
										value={locationValue}
										handleChange={(e) => locationChange(e)}
										options={options}
										isLoading={locationLoading}
									/>
								</div>

								<div className="">{serachBar}</div>
							</div>

							<div className="">
								<div className="header_right">
									{noPaymentBtn ? null : (
										<div className="w-100 flex_row_right mb_25">
											{withHistoryBtn && (
												<Link to={PAYMENT_HISTORY} className="no_underline">
													<IconButton
														text="Payment history record"
														className={`mr_25 ${paymentClass}`}
														isBlack
														onClick={onHistoryClick}
														isRed
													/>
												</Link>
											)}

											<IconButton
												withIcon
												icon={
													<BiRefresh
														width="13px"
														height="13px"
														fill="#000000"
													/>
												}
												text={refreshLoading ? "Refreshing..." : "Refresh"}
												className=""
												isBlack
												loading={refreshLoading}
												onClick={onRefresh}
												rotate
											/>
										</div>
									)}
									<form
										className="form_flex"
										onSubmit={(e) => {
											e.preventDefault();
											updateDeliveryFactors();
										}}
									>
										<div className="flex-row d-align-center">
											<h6 className="bold_24px mb_25 ">Delivery factors</h6>

											<Tippy content="Contains factors that affect delivery and delivery prices">
												<div style={{ marginTop: "-10px", marginLeft: "10px" }}>
													<AiFillQuestionCircle color="#de970b" />
												</div>
											</Tippy>
										</div>

										<div className="flex-column d-lg-justify-end d-lg-align-end surge_container">
											<div className="flex-row d-justify-end d-align-center mb_25">
												<Select
													placeholder="Select surge type"
													value={deliveryValues}
													isLoading={!deliveryValues?.value}
													// value={ surgeReasons.find(itm=>
													//   String(itm.value)===String(deliveryValues?.logistics_surge)
													//   && String(itm.alt)===String(deliveryValues?.logistics_surge_type)
													//   )||surgeReasons[0]}
													handleChange={(e) =>
														setDeliveryValues({
															...deliveryValues,
															logistics_surge: e.time,
															logistics_surge_type: e.alt,
															value: e.value,
															time: e.time,
															label: e.label,
															alt: e.alt,
														})
													}
													options={surgeReasons}
												/>
												{/* <IconInput
                    placeholder="Surge"
                    value={deliveryValues?.surge}
                    onChange={(e) => {
                      setDeliveryValues({
                        ...deliveryValues,
                        surge: e,
                      });
                    }}
                    required
                    containerClass="width_52"
                    type="number"
                    min="1"
                    step="0.1"
                  /> */}
												<Tippy content="Surge multiplies the delivery price by its value. 1 is the minimum value">
													<div
														style={{ marginTop: "-5px", marginLeft: "10px" }}
													>
														<AiFillQuestionCircle color="#de970b" />
													</div>
												</Tippy>
											</div>
											<div className="flex_row_start mb_25">
												<CheckBox
													id="set_end_date"
													label={`Switch delivery ${
														deliveryValues?.delivery ? "off" : "on"
													}`}
													checked={deliveryValues?.delivery}
													onClick={() => {
														setDeliveryValues({
															...deliveryValues,
															delivery: !deliveryValues?.delivery,
														});
													}}
												/>

												<Tippy content="Checking the box switches on delivery and switches off pickup. Unchecking the box does vice versa">
													<div
														style={{ marginTop: "-5px", marginLeft: "10px" }}
													>
														<AiFillQuestionCircle color="#de970b" />
													</div>
												</Tippy>
											</div>

											<IconButton
												text={`${
													deliverySubmitting
														? "Updating delivery factors..."
														: "Update delivery factors"
												}`}
												type="submit"
												loading={deliverySubmitting}
											/>
										</div>
									</form>
								</div>
							</div>
						</div>

						<div className="width_52 pr_25 flex_column"></div>
					</div>
				)}

				<section className="width_full flex_row_start">
					{/* <aside className="setting-sidenav">


        </aside> */}
					{children}
				</section>
			</div>
		</main>
	);
};
export default withAlert()(Layout);
