import React, { useState } from "react";
import { observer } from "mobx-react";
import "./styles.scss";
import Layout from "../../components/layout";
import SearchLogo from "../../assets/images/search-logo.png";
import RadioGroup from "../../components/inputs/RadioGroup";
import SearchByKeyword from "./features/SearchByKeyword";
import SearchByDate from "./features/SearchByDate";
import { useHistory } from "react-router";
import SearchStore from "../../stores/superAdminStores/searchStore";
import { SEARCH } from "../../router/router";

const SearchLayout = observer(({ alert }) => {
	const { loading } = SearchStore;
	const history = useHistory();
	const [searchFilter, setSearchFilter] = useState("keyword");

	const handleGetSearchByKeyword = async (val) => {
		history.push(
			`/search/users/query=${val?.filter_value}/${val?.filter_type}`,
			{ state: val }
		);
	};
	const handleGetSearchByDate = async (val) => {
		history.push(
			`/search/users/query=${val?.end_date_range}/${val?.filter_type}/${val?.start_date_range} `,
			{
				state: val,
			}
		);
	};
	function onChange(e) {
		setSearchFilter(e.target.value);
	}

	return (
		<Layout
			// locationChange={setLocation}
			search="active_nav_item"
			withHistoryBtn
			title={<h6 className="bold_24px mr_30">SearchLayout</h6>}
			searchLink={SEARCH}
		>
			<div className="  font-bold w-[90%] md:w-[35%] mx-auto mt-16">
				<div className="flex flex-col items-start gap-1  w-[100%] space-y-2 pb-8">
					<div>
						<img
							src={SearchLogo}
							className="search-logo w-[50%] md:w-[75%] mx-auto md:mx-0"
							alt=""
						/>
					</div>
					<div className="radio-group mx-auto md:mx-0">
						<RadioGroup
							name="filter"
							selectedOption={searchFilter}
							options={[
								{ title: "Search By Keyword", value: "keyword" },
								{ title: "Search By Date", value: "date" },
							]}
							onChange={onChange}
							className=" font-bold"
						/>
					</div>

					{searchFilter === "keyword" ? (
						<SearchByKeyword
							onSearchClick={handleGetSearchByKeyword}
							loading={loading}
							disabled={loading}
						/>
					) : (
						<SearchByDate
							onSearchClick={handleGetSearchByDate}
							loading={loading}
							disabled={loading}
						/>
					)}
				</div>
			</div>
		</Layout>
	);
});

export default SearchLayout;
