import React from "react";
import "./styles.scss";

const Input = ({
	withLabel,
	label,
	name,
	className,
	type,
	placeholder,
	value,
	onChange,
	disabled,
	required,
	max,
	min,
	containerClass,
	...rest
}) => {
	return (
		<div className={`input_container flex_column ${containerClass}`}>
			{withLabel && <label className="mb_15  normal_17px">{label}</label>}{" "}
			<input
				max={max}
				min={min}
				name={name}
				className={`input ${className}`}
				type={type}
				placeholder={placeholder}
				value={value}
				autoComplete="on"
				disabled={disabled}
				onChange={(e) => onChange(e.target.value)}
				required={required}
				{...rest}
			/>
		</div>
	);
};
export default Input;
