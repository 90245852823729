import React from "react";

const Live = ({ className }) => {
  return (
    <p className={`gray_label flex_row_center ${className}`}>
      {"Live "}
      <span className="green_text normal_53px bold ml_5"> •</span>
    </p>
  );
};
export default Live;
